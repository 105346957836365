import axios, { AxiosError } from 'axios'
import { API_ENDPOINTS, parseAxiosErrors, parseAxiosError, SELLER_INVENTORY_API_URL, OMS_APP_URL_CLIENT_ID, OMS_APP_URL_CLIENT_SECRET, OMS_APP_URL_CLIENT_AUTH} from 'common/constant'

import { BaseService } from './base.service'
import { IErrorResponses, ISuccessResponses, IErrorResponse, ISuccessResponse } from './interfaces/common.interface'
import { IPurchaseOrderStatus } from './interfaces/purchaseorder.interface'

export class OnPurchaseOrderService extends BaseService {
  /* Pass email argument from the business user account to fetch the details  */
  // public async getPurchaseOrderListData(): Promise<ISuccessResponses | IErrorResponses> {
  //   try {
  //     // const { datas } = await this.httpClient.get(API_ENDPOINTS.PURCHASEORDER_LIST + `?CompanyCode=${companycode}` + `&BranchCode=${branchcode}` + `&SupplierCode=${suppliercode}` + `&FromDate=${fromdate}` + `&EndDate=${enddate}`  + `&Status=${status}`, { headers: { 'Userid': API_ENDPOINTS.PURCHASE_USER_ID ,'securitycode' : API_ENDPOINTS.PURCHASE_SECURITY_CODE } })
  //     const { data } = await this.httpClient.get(
  //       API_ENDPOINTS.PURCHASEORDER_LIST +
  //         `?CompanyCode=PMPL1` +
  //         `&BranchCode=0` +
  //         `&SupplierCode=1824` +
  //         `&FromDate=20200101` +
  //         `&EndDate=20211210` +
  //         `&Status=SALL`,
  //       { headers: { Userid: 'poorvika', securitycode: '8164-4154-3719-9412' } }
  //     )
  //     //  console.log(data);
  //     localStorage.setItem('purchase_order', data.Data)

  //     return data
  //   } catch (error) {
  //     return parseAxiosErrors(error as AxiosError)
  //   }
  // }
  public async getPurchaseOrderListDatas(
    companycode: string,
    branchcode: string,
    suppliercode: string,
    fromdate: string,
    enddate: string,
    status: string
  ): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const sellercode = localStorage.getItem('seller_code')

      const { data } = await this.httpClient.post(API_ENDPOINTS.PURCHASEORDER_LIST, {
        companycode,
        branchcode,
        suppliercode,
        fromdate,
        enddate,
        status,
        sellercode
      })
    
      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
  public async getPurchaseOrderListImage(itemcode?: string): Promise<ISuccessResponses | IErrorResponses> {
    try {
      const { data } = await this.httpClient.get(`/purchaseorder/getimagepurchaseorder/${itemcode}`)

      return data
    } catch (error) {
      return parseAxiosErrors(error as AxiosError)
    }
  }
  public async getPurchaseOrderGetStock(itemcode?: string): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const seller_code = localStorage.getItem('seller_code')

      const { data } = await this.httpClient.get(
        `${SELLER_INVENTORY_API_URL}/inventory/stock?` +
          `erp_item_code=${itemcode}` +
          '&sellercode=' +
          seller_code
      )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async updatePurchaseOrder(statusdata: IPurchaseOrderStatus): Promise<ISuccessResponses | IErrorResponses> {
    try {
      const { data } = await this.httpClient.put(API_ENDPOINTS.PURCHASEORDER_STATUS_UPDATE, statusdata)

      return data
    } catch (error) {
      return parseAxiosErrors(error as AxiosError)
    }
  }

  public async addPurchaseOrder(statusdata: any): Promise<ISuccessResponses | IErrorResponses> {
    try {
      statusdata.sellercode = localStorage.getItem('seller_code') || '';
      const { data } = await this.httpClient.post(API_ENDPOINTS.PURCHASEORDER_STATUS_ADD, statusdata)

      return data
    } catch (error) {
      return parseAxiosErrors(error as AxiosError)
    }
  }

  
  public async getPurchaseOrderSuppliercode(seller_code: string | null): Promise<ISuccessResponses | IErrorResponses> {
    try {
      const { data } = await this.httpClient.get('/purchaseorder/getSupplierCode/' + seller_code)

      return data
    } catch (error) {
      return parseAxiosErrors(error as AxiosError)
    }
  }

  public async getOmsStatus(seller_code: string | null): Promise<ISuccessResponses | IErrorResponses> {
    try {
      const { data } = await this.httpClient.get('/purchaseorder/oms_status/' + seller_code)

      return data
    } catch (error) {
      return parseAxiosErrors(error as AxiosError)
    }
  }

  public async updatePORejectSatusInOMS(rejectData: any): Promise<ISuccessResponse | IErrorResponse> {
    try {
      console.log("API_ENDPOINTS.UPDATE_PO_REJECT_STATUS_IN_OMS",API_ENDPOINTS.UPDATE_PO_REJECT_STATUS_IN_OMS)
      const { data } = await  axios.post(API_ENDPOINTS.UPDATE_PO_REJECT_STATUS_IN_OMS, {...rejectData},
      {
        headers:{
          "CF-Access-Client-Id": OMS_APP_URL_CLIENT_ID,
          "CF-Access-Client-Secret": OMS_APP_URL_CLIENT_SECRET,
          "authorization" : OMS_APP_URL_CLIENT_AUTH,
          'Content-Type': 'application/json',
        }
      } );
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }


  public async getSellerBranchInfoFromOMS(warehouse_type: string, warehouse_code: string): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await  axios.get(`${API_ENDPOINTS.GET_SELLER_BRANCH_INFO}/${warehouse_type}/${warehouse_code}`,
      {
        headers:{
          "CF-Access-Client-Id": OMS_APP_URL_CLIENT_ID,
          "CF-Access-Client-Secret": OMS_APP_URL_CLIENT_SECRET,
          "authorization" : OMS_APP_URL_CLIENT_AUTH,
          'Content-Type': 'application/json',
        }
      } );
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }



}
