import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import ActionButton from 'components/ActionButton'
import ItemcodeImgs from 'components/PurchaseTableImage'
import Getstock from 'components/Getstockdetails'
import { OnPurchaseOrderService } from 'services/purchaseOrder.service'
import { IErrorResponses,ISuccessResponse, ISuccessResponses,IErrorResponse } from 'services/interfaces/common.interface'
import { isVisible, LIST_OF_ROLES, showNotification, STATUS } from 'common/constant'
import { RootStore } from 'redux/store'

import './styles.scss'


type TPOInformation = {
  seller_code: string
  name: string
  description: string
  latlng: number
  address1: string
  address2: string
  address3: string
  city: string
  state: string
  pincode: string
  warehouse_code: string
}
type POFormprops = {
  title?: string
  formdatas?: any
  open: boolean
  handleClose: () => void
  cancelClose: () => void
  getPurchaseOrders? : () => void
}

const AddPOForm: React.FC<POFormprops> = ({ open, handleClose, cancelClose, formdatas, getPurchaseOrders }) => {
  const { handleSubmit } = useForm<TPOInformation>({ mode: 'onChange' })
  const getPurchaseOrderList: OnPurchaseOrderService = new OnPurchaseOrderService()
  const [datas, setDatas] = useState<any>([])
  const [total, setTotal] = useState<number>(0)
  const [showMsg, setShowMsg] = useState<boolean>(false)
  const [isAccepted, setAccepted] = useState<boolean>(true)
  const history = useHistory()
  const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission)


  const rejectOrder = async () => {
    try {
      const statusorder = {
        CompanyCode: '',
        PurchaseOrderNo: formdatas.OrderNumb,
        PurchaseOrderDate: formdatas.OrderDate,
        AdditionalStatus: 'CANC',
        OrderStatus: 'CANC',
        RefImageUrl: '-',
        RefBillNo: '-',
        RefBillDate: 0
      }

      const PurchaseorderGetStock: ISuccessResponses | IErrorResponses = await getPurchaseOrderList.updatePurchaseOrder(statusorder)
      const getdata = PurchaseorderGetStock as ISuccessResponses

      if(getdata?.StatusCode === 0)
        showNotification(STATUS.SUCCESS, 'Order Rejected Successfully')
      else
        showNotification(STATUS.FAILURE, 'Please Check All fields')

      //Update the cancel status in the OMS
      const oms_post_data = {
        "purchase_number": formdatas.OrderNumb,
        "purchase_date": formdatas.OrderDate,
        "item_code": formdatas.Items[0]["ItemCode"],
        "seller_comments":"Seller Rejected the Order"
      }
      const omsRejectorderstatus: ISuccessResponse | IErrorResponse = await getPurchaseOrderList.updatePORejectSatusInOMS(oms_post_data);
      const getdataOMS = omsRejectorderstatus as ISuccessResponse

      setShowMsg(false)
      history.push('/sellerLayout/purchaseOrder/Rejected')
      if(getPurchaseOrders)
           getPurchaseOrders()
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get stock')
    }
  }

  const getPurchaseAcceptOrder = async (formdatas) => {
    try {


      const statusorder = {
        CompanyCode: '',
        PurchaseOrderNo: formdatas.OrderNumb,
        PurchaseOrderDate: formdatas.OrderDate,
        AdditionalStatus: 'PROC',
        OrderStatus: 'PROC',
        RefImageUrl: '-',
        RefBillNo: '-',
        RefBillDate: 0
      }

      const PurchaseorderGetStock: ISuccessResponses | IErrorResponses = await getPurchaseOrderList.updatePurchaseOrder(statusorder)
      const getdata = PurchaseorderGetStock as ISuccessResponses

      if(getdata?.StatusCode === 0){
        showNotification(STATUS.SUCCESS, 'Order Accepted Successfully')
        history.push('/sellerLayout/purchaseOrder/Accepted')

        if(getPurchaseOrders)
           getPurchaseOrders()

      }
      else
        showNotification(STATUS.FAILURE, 'Please Check All fields')


    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get stock')
    }
  }

  const onSubmit = async () => {
    if(isAccepted){
      handleClose()
      getPurchaseAcceptOrder(formdatas)
    } else{
      showNotification(STATUS.FAILURE, 'Stock is less than order quantity')

    }
    
  }

  useEffect(() => {

    setDatas(formdatas)
    let total = 0
    formdatas?.Items?.map((item) => {
      total = total + item.ItemRate * item.OrderQty
     
      return item;
    })
    setTotal(total)
  }, [formdatas])

  return (
    <Dialog
      className="addware-popup"
      open={open}
      onClose={() => {
        handleClose()
      }}
    >
      <>
        {' '}
        <div className="text-header1">
          <HighlightOffIcon style={{ cursor: 'pointer' }} onClick={cancelClose} />
          <div className="text-header2" style={{ margin: '15px auto' }}>
            Accept Purchase Order
          </div>
        </div>
        <div className="form-wrapper-add">
          <div className="name-wrapper1">
            <form autoComplete="off" className="personal-information-form" onSubmit={handleSubmit(onSubmit)}>
              <div style={{ height: '100vh', overflowY: 'scroll' }}>
                <table className="Purchase-products-table-popup">
                  <thead>
                    <tr>
                      <th>Product Details</th>
                      <th>MOP</th>
                      <th>Quantity</th>
                      <th>Curent Stock</th>
                    </tr>
                  </thead>
                  {datas && datas.Items ? ( 
                    <>
                      <tbody>
                        {datas &&
                          datas.Items.map((item) => {
                            return (
                              <tr key={item.ItemCode}>
                                <td>
                                  <ItemcodeImgs data={item.ItemCode} />
                                </td>

                                <td>Rs.{item?.ItemRate?.toFixed(2)}</td>
                                <td>{item.OrderQty}</td>
                                <td key={item.ItemCode}>
                                  <Getstock OrderQty = {item.OrderQty} data={item.ItemCode} setAccepted = {setAccepted} branchcode={datas.virtual_branch_code || datas.BranchCode} />
                                </td>
                              </tr>
                            )
                          })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th />
                          <th />
                          <th scope="row">
                            <strong>ITEM TOTAL</strong>
                          </th>

                          <th scope="row">
                            <strong>Rs. {total?.toFixed(2)}</strong>
                          </th>
                        </tr>
                        <tr>
                          <th />
                          <th />
                          <th scope="row">
                            <strong>GST(+) </strong>
                          </th>

                          <th scope="row">
                            <strong>Rs. {(datas.OrderAmount - total).toFixed(2)}</strong>
                          </th>
                        </tr>
                        <tr>
                          <th />
                          <th />
                          <th scope="row">
                            <strong>TOTAL</strong>
                          </th>
                          <th scope="row">
                            <strong>Rs. {datas?.OrderAmount?.toFixed(2)}</strong>
                          </th>
                        </tr>
                      </tfoot>
                    </>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="empty-data" colSpan={12}>
                          No data to display
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>

              <div className="purchase-order-button btn-bottom-sticky">
              {isVisible([LIST_OF_ROLES.PURCHASE_ORDER_UPDATE], { user: userPermission }) ? (
                <>
                <ActionButton label="Reject Order" type="button" varient="outlined" onClick={() => setShowMsg(true)} />

                <ActionButton label="Accept Order" type="submit" />
                </>
                ):null}
              </div>
            </form>
          </div>
        </div>{' '}
        <Dialog maxWidth="sm" open={showMsg}>
          <DialogTitle>Reject Order</DialogTitle>
          <Box position="absolute" right={0} top={0}>
            <IconButton onClick={()=>setShowMsg(false)}>
              <Close />
            </IconButton>
          </Box>
          <DialogContent>
            <Typography>Do you want to reject this order?</Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="contained"   onClick={() => setShowMsg(false)}>
              Cancel
            </Button>
            <Button style={{backgroundColor:'#f25822',color:'#fff'}} variant="contained" onClick={() => rejectOrder()}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </Dialog>
  )
}

export default AddPOForm
