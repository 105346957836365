import { Dialog, DialogActions } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HighlightOffIcon from '@material-ui/icons/HighlightOff'


import { showNotification, STATUS } from 'common/constant';
import { decrypt } from 'common/encryptor';
import ActionButton from 'components/ActionButton'
import InputWithLabels from 'components/InputWithLabels';
import { RootStore } from 'redux/store';
import { AccountsService } from 'services/accounts.service';
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface';
import { ILegalInformations, IBankingInformations, IBillingAddress, IPrimaryContact, IDocumentIds, IDocumentAttachments, IProductInformation } from 'services/interfaces/onBoarding.interface';
import { OnboardingService } from 'services/onBoarding.service';
import PrintableConfirmation from '../PrintableConfirmation';

import './styles.scss'
type IInformations = {
   supplierId: string
}

const FormValidations = {
   supplierId: {
     required: { value: true, message: "To activate account Supplier ID is mandatory" }
   }
}
const DetailsConfirmationPage: React.FC = () => {

   const onboardingService = new OnboardingService();
   const accountService = new AccountsService();
   const { register, handleSubmit, errors } = useForm<IInformations>({ mode: 'all' })

   const [LegalInformation, setLegalInformation] = useState<ILegalInformations | null>(null);
   const [BillingInformation, setBillingInformation] = useState<IBankingInformations | null>(null);
   const [productinformation, setProductinformation] = useState<IProductInformation | null>(null);
   const [BillAddress, setBillAddress] = useState<IBillingAddress | null>(null)
   const [primaryContact, setprimaryContact] = useState<IPrimaryContact | null>(null)
   const [documentIds, setDocumentIds] = useState<IDocumentIds | null>(null);
   const [documentAttachments, setDocumentAttachments] = useState<IDocumentAttachments | null>(null)
   const [activationStatus, setActivationStatus] = useState<boolean>(false);
   const [allDetailsAvailability, setAllDetailsAvailability] = useState<boolean>(false);
   const selectedUserEmail = useSelector((state: RootStore) => state.onBoardingData.userEmail?.email);
   const [activateDialog, setActivateDialog] = React.useState<boolean>(false)
   const [termsStatus, setTermsStatus] = useState<boolean>(false)

   const history = useHistory()

   useEffect(() => {
      const getReviewData = async () => {
         const getPersonalInformations: ISuccessResponse | IErrorResponse = await onboardingService.getOnboardingData(5, selectedUserEmail as string);

         if (getPersonalInformations.status === STATUS.SUCCESS) {
            const successResponse = getPersonalInformations as ISuccessResponse;
            setLegalInformation(successResponse.data.LegalInformation)
            successResponse.data.BillingInformation.account_number = decrypt(successResponse.data.BillingInformation.account_number)
            setBillingInformation(successResponse.data.BillingInformation)
            setBillAddress(successResponse.data.BillAddress)
            setProductinformation(successResponse.data.product_info)
            setprimaryContact(successResponse.data.primaryContact)
            setDocumentIds(successResponse.data.TaxationInfo.ids)
            setDocumentAttachments(successResponse.data.TaxationInfo.documents)
            setActivationStatus(successResponse.data.activationStatus);
            setAllDetailsAvailability(successResponse.data.required.reviewsubmit);
            setTermsStatus(successResponse.data?.termsnCondition)

         }
      }

      getReviewData();

   }, [])

   const createSellerinOMS = async(supplierId) => {
      try {
         const seller_data= {
            "seller_code": LegalInformation?.Account_Code,
            "seller_name" :LegalInformation?.Account_Name,
            "supplier_id":supplierId,
            "supplier_name":LegalInformation?.Legal_Name,
            "supplier_state":LegalInformation?.state
         }
         const creationResponse: ISuccessResponse | IErrorResponse = await onboardingService.saveSellerInOMS(seller_data)

         if (creationResponse.status === STATUS.SUCCESS) {
            showNotification(STATUS.SUCCESS, "Seller Account created in OMS")
          } else {
            showNotification(STATUS.FAILURE, "Unable to create seller account in OMS.Please contact OMS TEAM.")
         }
      } catch (error) {
         showNotification(STATUS.FAILURE, "Unable to create seller account in OMS.Please contact OMS TEAM.")
      }
   }


   const activateSellerAccount = async (supplierId) => {
      if (!allDetailsAvailability) {
         showNotification(STATUS.FAILURE, "Seller Don't have enough Information to activate the account!")

         return
      }

      try {
         const activationResponse: ISuccessResponse | IErrorResponse = await onboardingService.activateSeller(selectedUserEmail)

         if (activationResponse.status === STATUS.SUCCESS) {
            //Create the seller in the OMS
            createSellerinOMS(supplierId)
            showNotification(STATUS.SUCCESS, "Seller Account Activated Successfully")
            setActivateDialog(false)
            history.push("/sellerLayout/admin/accounts")
         } else {
            showNotification(STATUS.FAILURE, "Unable to activate the account")
         }
      } catch (error) {
         showNotification(STATUS.FAILURE, "Unable to activate the account")
      }

   }

   const onSubmit = async (formData: IInformations) => {

      try {
         const saveAdditionalInformationResponse: ISuccessResponse | IErrorResponse = await accountService.saveAdditionalInformation({ ...formData, accountCode: localStorage.getItem('seller_code') as string });

         if (saveAdditionalInformationResponse.status === STATUS.SUCCESS) {
            showNotification(STATUS.SUCCESS, saveAdditionalInformationResponse.message)
            activateSellerAccount(formData.supplierId);
         } else {
            showNotification(STATUS.FAILURE, saveAdditionalInformationResponse.message)
         }
      } catch (error) {
         showNotification(STATUS.FAILURE, "Unable to save Informations or already supplier code exist")
      }

   }

   const activationDialogHandler = (): void => {

      if (!allDetailsAvailability) {
         showNotification(STATUS.FAILURE, "Seller Don't have enough Information to activate the account!")

         return
      }
      
      if(!termsStatus) {
         showNotification(STATUS.FAILURE, "The Seller was not accepted terms & conditions during final step of onboarding. Account can't be activated without this action");
        
         return
      }

      setActivateDialog(true)

   }


   const printableRef = useRef<HTMLDivElement>(null);

   return (
      <div className="details-confirmation-page">

         <p className="form-subtitle">Legal Information</p>
         {_.map(LegalInformation, (value, prop) => {
            return <div className="name-and-details" key={prop}>
               <p className="title-text">{prop.replaceAll('_', ' ')} :</p>
               <p className="value-text">{value}</p>
            </div>
         })}
         <p className="form-subtitle">Banking Information</p>
         {_.map(BillingInformation, (value, prop) => {
            return <div className="name-and-details" key={prop}>
               <p className="title-text">{prop.replaceAll('_', ' ')} :</p>
               <p className="value-text">{value}</p>
            </div>
         })}
         <p className="form-subtitle">Billing Information</p>
         {_.map(BillAddress, (value, prop) => {
            return <div className="name-and-details" key={prop}>
               <p className="title-text">{prop.replaceAll('_', ' ')} :</p>
               <p className="value-text">{value}</p>
            </div>
         })}
          {((!!productinformation?.brandList && productinformation?.brandList.length > 0) || 
            (!!productinformation?.categoriesList && productinformation?.categoriesList.length > 0 ))
            ? 
             <>
             <p className="form-subtitle">Product Information</p>
            {_.map(productinformation, (value, prop) => {
                return <div className="name-and-details" key={prop}>
                <p className="title-text">{prop.replaceAll('_', ' ')} :</p>
                <p className="value-text">
                  { !!value && value.length > 0 ? 
                  <>
                 {_.map(value, (childval, index) => {
                    if(index == "0"){
                        return childval["name"]
                    } else{
                        return ", "+childval["name"]
                    } 
                } )}</> : '-' }
                </p>
                </div>
            })} </>: <></> }
         <p className="form-subtitle">Primary Contact</p>
         {_.map(primaryContact, (value, prop) => {
            return <div className="name-and-details" key={prop}>
               <p className="title-text">{prop.replaceAll('_', ' ')} :</p>
               <p className="value-text">{value}</p>
            </div>
         })}
         <p className="form-subtitle">Taxation & Documents</p>
         {_.map(documentIds, (value, prop) => {
            return <div className="name-and-details" key={prop}>
               <p className="title-text">{prop.replaceAll('_', ' ')} :</p>
               <p className="value-text">{value}</p>
            </div>
         })}

         {_.map(documentAttachments, (value, prop) => {
            return <div className="name-and-details" key={prop}>
               {prop === 'Signature' ? <></> : <p className="title-text">{prop} :</p>}
               {value && prop !== 'Signature' ? <input
                  type="button"
                  className="btn-to-link"
                  onClick={() => window.open(value, '_blank')}
                  value="Click to View Document"
               /> : <></>}

            </div>
         })}
         <div className="name-and-details" >
            <p className="title-text">Signature:</p>
            <img src={documentAttachments?.Signature} className="digital-sign-img" />
         </div>

         <div>
            {!activationStatus ? <ActionButton label="Activate Account" onClick={activationDialogHandler} type="submit" /> : <></>}
            <div className="print-form-wrapper">
               <PrintableConfirmation
                  ref={printableRef}
                  form={LegalInformation}
               />

            </div>

         </div>
         <Dialog
            className="activate-suppilerID-dialog"
            open={activateDialog}
            onBackdropClick={() => setActivateDialog(false)}
         >
            <div className="closeIcon dialog-close-ic" onClick={() => setActivateDialog(false)}>
               <HighlightOffIcon />
             </div>
            {allDetailsAvailability ?
                  <form onSubmit={handleSubmit(onSubmit)}>
                     <div className="search-sort-dialog">
                        <InputWithLabels
                           name="supplierId"
                           register={register(FormValidations.supplierId)}
                           error={errors.supplierId?.type !== undefined}
                            errorText={errors.supplierId ? errors.supplierId.message : ''}
                           text="Supplier Id *"
                           notrequired />

                     </div>
                     <div>Same seller ERP Supplier ID has to provide here.Please provide valid supplier id.</div>

                     <DialogActions style={{ margin: '0 auto' }}>
                        <ActionButton label="Cancel" type="button" varient="outlined" onClick={() => setActivateDialog(false)} />
                        <ActionButton label="Activate" type="submit" />
                     </DialogActions>
                  </form>
               
                  :
                  <div className='details-check-message'>
                     Seller Don't have enough Information to activate the account!
                  </div>
            
            }

               </Dialog>
      </div>

   )
}

export default DetailsConfirmationPage
