import React, { useEffect, useState } from 'react'
import './styles.scss'
import { Grid, TextField } from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import moment from 'moment'
import { useSelector } from 'react-redux'

import ActionButton from '../../../components/ActionButton'
import { OnPurchaseOrderService } from '../../../services/purchaseOrder.service'
import { isVisible, LIST_OF_ROLES, showNotification, STATUS } from '../../../common/constant'
import { IErrorResponses, ISuccessResponses } from '../../../services/interfaces/common.interface'
import FileDragandDropOnly from 'components/FileDragDropOnly'
import { RootStore } from '../../../redux/store'


const PurchaseOrderAcceptedForm: React.FC = () => {
  const [order, setOrder] = useState<any>()
  const location = useLocation<any>()
  const [invoiceDocument, setInvoiceDocument] = useState('')
  const [invoice_number, setInvoiceNumber] = useState('')
  const [total, setTotal] = useState<number>(0)
  const [invoicedateval, setInvoicedateval] = React.useState<any>('')
  const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission)
  const [editdata, setEditdata] = useState<boolean>(false)
  const purchase_order: OnPurchaseOrderService = new OnPurchaseOrderService()

  useEffect(() => {
    const { state } = location
    setOrder(state)

    let total = 0
    state?.Items?.map((item) => {
      total = total + item.ItemRate * item.OrderQty

      return item
    })
    setTotal(total)
    setInvoiceNumber(!!state.RefBillNo && state.RefBillNo != "-"? state.RefBillNo : "" )
    if (state.RefBillDate !== '0') {
      setEditdata(true)
      //moment(state.RefBillDate, 'YYYYMMDD').toISOString()
      setInvoicedateval(moment(state.RefBillDate, 'YYYYMMDD').format("YYYY-MM-DD"))
    }else{
       // value="2017-06-01" - Value has to set like this
      setEditdata(false)
      setInvoicedateval(moment().format("YYYY-MM-DD"))
    }
    setInvoiceDocument(state.RefImageUrl)
  }, [location])

  const history = useHistory()

  const handleSubmit = async (event) => {
    event.preventDefault()
    //Invoice date validation - date should not be future date or before the purchase order date
    let po_date= order.OrderDate+"";
    let po_date_frm_month_year = po_date.substring(4);
    let po_date_frm = po_date.substring(0,4)+"-"+ po_date_frm_month_year.substring(0,2)+"-"+ po_date_frm_month_year.substring(2);
    let dt_po = new Date(po_date_frm);
    let today_date =new Date(moment(new Date()).format('YYYY-MM-DD'))
    let inv_selected_date = new Date(invoicedateval);
    if (inv_selected_date >= dt_po && inv_selected_date <= today_date) {
      if ( invoicedateval !== null && invoice_number !== '' && invoice_number != "-") {
        try {
          const order_details = {
            CompanyCode: '',
            PurchaseOrderNo: order.OrderNumb,
            PurchaseOrderDate: order.OrderDate,
            AdditionalStatus: 'INVO',
            OrderStatus: 'INVO',
            RefImageUrl: invoiceDocument,
            RefBillNo: invoice_number,
            RefBillDate: Number(moment(invoicedateval).format('yyyyMMDD'))
          
          }
  
          const add_order_details = {
            seller_code:localStorage.getItem('seller_code') || '',
            total_amt:total.toString(),
            invoice_img: invoiceDocument.toString(),
            invoice_no: invoice_number.toString(),
            invoice_date: moment(invoicedateval).format('DD-MMM-YYYY').toString(),
            payment_status: 'Pending',
            payment_date:moment().format('DD-MMM-YYYY').toString()      
          }
  
         
  
          const PurchaseorderGetStock: ISuccessResponses | IErrorResponses =
            await purchase_order.updatePurchaseOrder(order_details)
          const update_response = PurchaseorderGetStock as ISuccessResponses
  
          if (update_response?.StatusCode === 0){
            
            purchase_order.addPurchaseOrder(add_order_details);
            showNotification(STATUS.SUCCESS, 'Order Invoiced Successfully')
  
          } 
          else showNotification(STATUS.FAILURE, 'Please Check All fields')
  
          history.push('/sellerLayout/purchaseOrder/Invoiced')
        } catch (error) {
          showNotification(STATUS.FAILURE, 'Unable to get stock')
        }
      } else {
        showNotification(STATUS.FAILURE, 'Please fill all fields')
      }
    }
    else{
      showNotification(STATUS.FAILURE, 'Invoice date should not be future date or before the order created date.')
    }
    
  }

  const isDisplay = () => {
    const visible = isVisible([LIST_OF_ROLES.PURCHASE_ORDER_UPDATE], { user: userPermission })

    return visible
  }

  const handleGoBack = (event) => {
    event.preventDefault()
    history.goBack()
  }

  return (
    <>
      <div className="name-wrapper3">
        <div>
          <Grid spacing={2} container>
            <Grid xs={6} item>
              <h3>Attach Invoice</h3>
              <form autoComplete="off" className="personal-information-form attach-invoice-form">
                <Grid spacing={2} style={{ alignItems: 'center', marginBottom: '20px' }} container>
                  <Grid xs={12} item>
                    <TextField
                      value={invoice_number}
                      id="outlined-required"
                      label="Invoice Number"
                      disabled={!isDisplay()}
                      fullWidth
                      required
                      onChange={(e) => setInvoiceNumber(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid spacing={2} style={{ marginBottom: '20px' }} container>
                  <Grid xs={12} item>
                     <input type="date" className='date-range-picker'  value={invoicedateval} onChange={(event) => setInvoicedateval(event.target.value)}/>
                  </Grid>
                </Grid>

                <Grid spacing={2} container>
                  <Grid xs={12} item>
                    <div className="file-uploading-wrapper">
                      <div className="field-and-drag-drop">
                        <div className="drop-area">
                          {invoiceDocument  && invoiceDocument != "-"? (
                            <div>
                              <input
                                className="btn-to-link"
                                type="button"
                                value="Click to View Invoice Copy"
                                onClick={() => window.open(invoiceDocument, '_blank')}
                              />
                              <CloseIcon
                                style={{ visibility: !isDisplay() ? 'hidden' : 'visible' }}
                                onClick={() => setInvoiceDocument('')}
                              />
                            </div>
                          ) : (
                            <div style={{ margin: '-10px 10px -10px -10px' }}>
                              <FileDragandDropOnly
                                fileSelected={(file) => setInvoiceDocument(file)}
                                text="Click to upload or Drag Drop Invoice copy"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                        
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 15 }}>
                  <div className="half-width float-left">
                    <ActionButton label="< Back" varient="outlined" onClick={handleGoBack} />
                  </div>
                  <div className="half-width float-right">
                    {isVisible([LIST_OF_ROLES.PURCHASE_ORDER_UPDATE], { user: userPermission }) ? (
                      <button
                        hidden={order?.AdditionalStatus === 'INVO' ? true : false}
                        className="box-button"
                        style={{ background: '#FF6522', padding: '8px 10px', border: 'none', color: '#fff' }}
                        onClick={(e) => handleSubmit(e)}
                      >
                        PROCEED
                      </button>
                    ) : null}
                  </div>
                </div>
                <div className='text-warning'>*Note: Please provice unique invoice number. To avoid the problem in payment.</div>
              </form>
            </Grid>
            <Grid xs={6} item>
              <div className="po-table">
                <h3 className="table-head">Products in this Order</h3>
                <table className="Purchase-products-table-popup">
                  <thead>
                    <tr>
                      <th>Product Details</th>
                      <th>MOP</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order?.Items?.map((item) => {
                      return (
                        <tr key={item.ItemCode}>
                          <td>
                            <div className="product-col">
                              <div className="pimg-wrap">
                                <img
                                  alt="product-img"
                                  className="product-img"
                                  src={item.ItemImage}
                                  style={{ width: '40px' }}
                                />
                              </div>
                              <p>{item.ItemName}</p>
                            </div>
                          </td>

                          <td>₹ {item?.ItemRate?.toFixed(2)}</td>
                          <td>{item.OrderQty}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th />
                      <th scope="row">
                        <strong>ITEM TOTAL</strong>
                      </th>

                      <th scope="row">
                        <strong>Rs. {total?.toFixed(2)}</strong>
                      </th>
                    </tr>
                    <tr>
                      <th />
                      <th scope="row">
                        <strong>GST(+) </strong>
                      </th>

                      <th scope="row">
                        <strong>Rs. {(order?.OrderAmount - total).toFixed(2)}</strong>
                      </th>
                    </tr>
                    <tr>
                      <th />
                      <th scope="row">
                        <strong>TOTAL</strong>
                      </th>
                      <th scope="row">
                        <strong>Rs. {order?.OrderAmount?.toFixed(2)}</strong>
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}

export default PurchaseOrderAcceptedForm
