import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import InputLabelwithDowninput from 'components/InputLabelwithDownInputField'
import { STATUS, showNotification, LIST_OF_ROLES, validatePermission } from 'common/constant'
import ActionButton from 'components/ActionButton'
import { RootStore, store } from 'redux/store'
import CardHeader from 'components/CardHeader'
import { ADDITIONAL_INFORMATION } from 'redux/actions/profile.action'
import { AccountsService } from 'services/accounts.service'
import { OnboardingService } from 'services/onBoarding.service'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
import useAdminEvaluation from 'hooks/useAdminEvaluation';

import './styles.scss'


type IInformations = {
    supplierId: string
    agreementDate:string   
}
const AdditionalInfoPage: React.FC = () => {
    const { register, setValue, handleSubmit } = useForm<IInformations>({ mode: 'all' })
    const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission);
    const { isAdmin }= useAdminEvaluation();

    /*Services */
    const onboardingService = new OnboardingService()
    const accountService = new AccountsService();
    const additionalInformationEditMode = useSelector((store: RootStore) => store.profile.additionalInformation.edit);

    // const hasAdditionalEditPermission = (): boolean => {
    //     // const permission =  validatePermission([LIST_OF_ROLES.TAX_FINANCE_UPDATE], userPermission);

    //     return isAdmin;
    // };

    /*Save additional informations */
    const onSubmit = async (formData: IInformations) => {
        try {
            
            const saveAdditionalInformationResponse: ISuccessResponse | IErrorResponse = await accountService.saveAdditionalInformation({...formData, accountCode: localStorage.getItem('seller_code') as string});

            if(saveAdditionalInformationResponse.status === STATUS.SUCCESS) {
                showNotification(STATUS.SUCCESS, 'Additional Informations updated successfully')
                store.dispatch({type: ADDITIONAL_INFORMATION, payload: {additionalInformation: {edit: false}}});
            } else {
                showNotification(STATUS.FAILURE, "Unable to update Additional Informations")
            }
        } catch (error) {
            showNotification(STATUS.FAILURE, "Unable to update Additional Informations")
        }

    }

    useEffect(() => {
        const role: string | null = localStorage.getItem('role');
        const isAllowed:boolean = (role === "ROOT_ADMIN" || role === "PMPL_CATALOGUE_MANAGER" ||role === "PMPL_OPERATIONS_MANAGER" ||role === "PMPL_FINANCE_MANAGER" )
              
        const sellerMail: string = localStorage.getItem('sellerMail') as string

        /*Get Additional Informations */
        const getContactInformations = async () => {
            try {
                const Informations: ISuccessResponse | IErrorResponse = await onboardingService.getOnboardingData(7, isAllowed ? sellerMail as string : '');

                if (Informations.status === STATUS.SUCCESS) {
                    const successResponse = Informations as ISuccessResponse;
                    setValue("supplierId", successResponse.data.supplierid);
                    setValue("agreementDate", successResponse.data.AgreementDate);
                } else {
                    showNotification(STATUS.FAILURE, 'Unable to get  informations')
                }
            } catch (error) {
                showNotification(STATUS.FAILURE, 'Unable to get  informations')
            }
        }

        getContactInformations();

    }, [])

    return (
        <div className="Profile-contacts-page">
            <form className="legal-layout-wrapper" onSubmit={handleSubmit(onSubmit)}>
                <CardHeader title="Additional Information" edit={!!isAdmin} action={ADDITIONAL_INFORMATION}/>
                <div>
                    <div className="legal-details-content-top">
                        <InputLabelwithDowninput
                            label="Supplier Id"
                            register={register}
                            disabled={!additionalInformationEditMode}
                            name="supplierId"
                        />
                         <InputLabelwithDowninput
                    
                            label="Agreement Received Date"
                            hint
                            info="(Agreements Hard Copy Received on)"
                            type="date"
                            register={register}
                            disabled={!additionalInformationEditMode}
                            name="agreementDate"
                        /> 
                        
                        <div className="save-btn-wrap">
                            {additionalInformationEditMode ? <ActionButton label="Save" type="submit" />: <></>}
                        </div>

                    </div>
                </div>

            </form>

        </div>
    )
}

export default AdditionalInfoPage