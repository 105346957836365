import React, { useEffect, useState, useRef } from 'react'
import './styles.scss'
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core'
import { CSVLink } from 'react-csv'
import { CloudUpload } from '@material-ui/icons'
import Pagination from '@material-ui/lab/Pagination'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import { faSort, faSortUp, faSortDown, faFileExcel, faFilter } from '@fortawesome/free-solid-svg-icons'
import ProductSearchBlock from 'components/ProductSearchBlock'

import { showNotification, STATUS } from '../../../common/constant'
import { IErrorResponse, ISuccessResponse } from '../../../services/interfaces/common.interface'
import InputField from '../../../components/InputField/InputField'
import InputWithLabels from '../../../components/InputWithLabels'
import ActionButton from '../../../components/ActionButton'
import { ProductService } from '../../../services/productService'
import ResultSummaryDialog from '../../../components/ResultSummaryDialog/Index'
import { IListingProduct, IPimProduct } from '../../../services/interfaces/product.interface'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type Productprops = {
  searchInput?: boolean
  title: string
  to: string
  buttonlabel: string
}

const useStyles = makeStyles(() => ({
  inputRoot: {
    color: 'black',
    background: 'white'
  },
  root: {
    padding: `0px`
  }
}))

type IMatchProduct = {
  item_code: string
  listing_status: string
}

const SearchProduct: React.FC<Productprops> = () => {
  const classes = useStyles()
  const { register, handleSubmit } = useForm({ mode: 'all' })

  const [brandValue, setBrandValue] = useState<string>('')

  const [AddDialog, setAddDialog] = React.useState<boolean>(false)
  const [product, setProducts] = useState<IPimProduct[]>([])
  const [all_products, setAllProducts] = useState<IPimProduct[]>([])
  const [matched_products, setMatchedProducts] = useState<IMatchProduct[]>([])
  const [seller_products, setSellerProducts] = useState<IListingProduct[]>([])

  const [filtered_products, setFilteredProducts] = useState<IPimProduct[]>([])
  const [addproduct, setAddProducts] = useState<IPimProduct | undefined>()
  const [pageCount, setPageCount] = useState<number>()
  const [pageNo, setPageNo] = useState<number | undefined>(1)
  const [csvData, setCsvData] = useState([])
  const inputEl = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null)
  const [bulkUploadDialog, setsBulkUploadDialog] = useState<boolean>(false)
  const [uploadStatus, setUploadStatus] = useState<any>()
  const [sortDialog, setsortDialog] = React.useState<boolean>(false)
  const [sortingType, setSortingType] = useState<string>('1')
  const [sortColumn, setSortColumn] = useState<string>('')
  const [filterSku, setFilterSku] = useState<any>({})
  const [filterErpCode, setFilterErpCode] = useState<any>({})
  const [open, setOpen] = useState(false)
  const [summaryResult, setSummaryResult] = useState([])
  const [productSearchValue, setProductSearchValue] = useState<string>('')
  const [categoryCode, setCategoryCode] = useState<string>('')
  const [brandCode, setBrandCode] = useState<string>('')

  const productService: ProductService = new ProductService()

  const getSellerProducts = async () => {
    try {
      const seller_products: ISuccessResponse | IErrorResponse = await productService.getAllSellerProducts()
      setSellerProducts(seller_products.data)
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Seller Product informations')
    }
  }

  const listProductsHandler = async (PageNumber?: string | number) => {
    const pageNumber = PageNumber !== undefined ? PageNumber : pageNo

    if((productSearchValue.length >= 1 || productSearchValue.length == 0) && (!categoryCode  || !brandCode)) {
      showNotification(STATUS.FAILURE, 'Please select Category and Brand')
      return false;
    }

    if (brandCode || categoryCode || productSearchValue.length >= 3) {
      if (brandCode || categoryCode || productSearchValue) {
        try {
          const getproducts: ISuccessResponse | IErrorResponse = await productService.getPimProducts(
            brandCode,
            categoryCode,
            productSearchValue,
            pageNumber
          )
          const getprdouctsData = getproducts as ISuccessResponse
          if (getprdouctsData.data !== null) {
            setProducts(getprdouctsData.data)
            setPageCount(getprdouctsData.pagecount)
            setPageNo(1);
            // getAllProducts()

          } else {
            setProducts([])
            setFilteredProducts([])
            setPageCount(1)
          }
        } catch (error) {
          showNotification(STATUS.FAILURE, 'Unable to get Product informations')
        }
      } else {
        showNotification(STATUS.FAILURE, 'Please Search the Product')
      }
    } else {
      showNotification(STATUS.FAILURE, 'Please select Category or Brand or Product')
    }
  }

  const addProductSeller = async (data) => {

    const item = {
      seller_code: localStorage.getItem('seller_code'),
      erp_item_code: addproduct?.erp_item_code,
      category: addproduct?.category,
      brand: addproduct?.brand,
      pim_code: addproduct?.code,
      psn: '',
      ext_prod_id: data.ext_product_id,
      sku: addproduct?.sku,
      listing_status: 'Pending',
      gtin: addproduct?.gtin,
      name: addproduct?.name,
      description: '',
      image: addproduct?.image?.url,
      mrp: Number(data.mrp),
      wholesale_price: Number(data.wholesale_price)
    }

    if(Number(data.wholesale_price) === 0){
      showNotification(STATUS.FAILURE, 'Wholesale Price should not be Zero.')
      return
    }

    if(Number(data.wholesale_price) === 0 ||  Number(data.mrp) === 0){
      showNotification(STATUS.FAILURE, 'MRP Price should not be Zero.')
      return
    }

    if (Number(data.wholesale_price) > Number(data.mrp)) {
      showNotification(STATUS.FAILURE, 'Wholesale Price should not be Greater than MRP Price.')
      return
    }

    const SellerCatelogue = [item]

    try {
      const data:any = await productService.AddProductsSellerCatelogue(SellerCatelogue);
      setAddDialog(false)

      if (data?.success) {
        showNotification(STATUS.SUCCESS, data?.data)

        const product_filtered = product?.map((prod) => {
          if (prod.erp_item_code === item.erp_item_code) prod.listing_status = 'Pending'

          return prod
        })

        setFilteredProducts(product_filtered)
      }else{


        showNotification(STATUS.FAILURE, "Seller code already exist")

      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to add Product')
    }
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNo(value)
    const data = {
      erp_item_code: filterErpCode ? filterErpCode.erp_item_code : '',
      sku: filterSku ? filterSku.sku : ''
    }
    const lsortingType = sortingType === '1' ? '-1' : '1'
    multipleproductFilter(data, sortColumn, lsortingType, value)

  }

  const multipleproductFilter = async (data, columnName?: string, sortType?: string, pageNum?: number) => {
    try {
    const page_no_loc = !!pageNum ? pageNum : 1;

     if(product.length > 0){
      const Pimproducts: ISuccessResponse | IErrorResponse = await productService.multiplePimProductsSearch(
        brandCode,
        categoryCode,
        productSearchValue,
        page_no_loc +"",
        data,
        columnName,
        sortType
      )
      const getpimproducts = Pimproducts as ISuccessResponse

      if (getpimproducts.data !== null) {
        setProducts(getpimproducts.data)
        setPageCount(getpimproducts.pagecount)
        setsortDialog(false)
        setPageNo(page_no_loc)
      } else {
        setProducts([])
        setFilteredProducts([])
        setPageCount(1)
        setsortDialog(false)
      }
     }
     
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Product informations')
      setsortDialog(false)
    }
  }

  /* To sort the table data based on column */
  const sortingFunctionalityHandler = (columnName: string, sortType: string) => {
    const data = {
      erp_item_code: filterErpCode ? filterErpCode.erp_item_code : '',
      sku: filterSku ? filterSku.sku : ''
    }
    multipleproductFilter(data, columnName, sortType, 1)
    setSortColumn(columnName)
    setSortingType(sortingType === '1' ? '-1' : '1')
  }

 

  // const getAllProducts = async () => {
  //   try {
  //     const all_products: ISuccessResponse | IErrorResponse = await productService.downloadDocument(
  //       categoryCode,
  //       brandValue,
  //       productSearchValue
  //     )
  //     // debugger;
  //     // const get_all_products = all_products as ISuccessResponse
  //     // setAllProducts(get_all_products.data)

  //   } catch (error) {
  //     showNotification(STATUS.FAILURE, 'Unable to get Seller Product informations')
  //   }
  // }

  const handleProductFilter = () => {
    // debugger;
    const data = {
      erp_item_code: filterErpCode ? filterErpCode.erp_item_code : '',
      sku: filterSku ? filterSku.sku : ''
    }
    //This is to handle the Override the Updated value in the state
    const lsortingType = sortingType === '1' ? '-1' : '1'
    multipleproductFilter(data, sortColumn, lsortingType) 
   }

  useEffect(() => {
    const matched: IMatchProduct[] = [];
    

    if (product?.length > 0 || seller_products?.length > 0) {

      const filtered_products = product?.map((item) => {
        const find = seller_products?.find((product) => product.erp_item_code === item.erp_item_code)

        if (find !== undefined) {
          item.listing_status = find.listing_status
          matched.push({
            item_code: find.erp_item_code,
            listing_status: find.listing_status
          })
        }

        return item
      })

      setFilteredProducts(filtered_products)
      setMatchedProducts(matched)
    } 
  }, [product, seller_products])

  useEffect(()=>{
    getSellerProducts()
  },[])

  /* Reset the search conditions */
  const resetSearchInputs = (): void => {
    setProducts([])
    setAllProducts([])
    setFilteredProducts([])
    setPageCount(1)
  }

  const onFileChange = async (event) => {
    const files = event.target.files[0]

    if (files) {
      try {
        const uploadfile = new FormData()

        uploadfile.append('uploadfile', files, files.name)

        const uploadResult: any = await productService.uploadDocument(uploadfile)

        if (uploadResult.status === STATUS.FAILURE) {
          showNotification(STATUS.FAILURE, 'Uploaded File is Invalid')
        } else {
          listProductsHandler(1)
          setUploadStatus(uploadResult[uploadResult?.length - 1])
          setsBulkUploadDialog(true)
        }
      } catch (error) {
        showNotification(STATUS.FAILURE, 'Unable to upload the document')
      }
    }
  }

  const handleSummary = async () => {
    try {
      setsBulkUploadDialog(false)
      const summaryResult: any = await productService.getUploadHistory()
      setSummaryResult(summaryResult)
      setOpen(true)
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get the History')
    }
  }

  const handleDownload = async () => {
    try {

      if (brandCode || categoryCode || productSearchValue.length >= 3) {
        const all_products = await productService.downloadDocument(
                categoryCode,
                brandCode,
                productSearchValue
              )
      
              // Create a URL object from the response data
        const url = window.URL.createObjectURL(new Blob([all_products.data]));
          
          // Create a temporary link and trigger a download
          const link = document.createElement('a');
          link.href = url;
          
          const fileName = "Product_Template_"+new Date().toISOString()+".xlsx"
          
          link.setAttribute('download', fileName);
          document.body.appendChild(link)
  ;
          link.click();

          // Clean up the temporary link
          document.body.removeChild(link)
      } else {
        showNotification(STATUS.FAILURE, 'Please select Category or Brand or Product')
      }

      // if (product.length > 0) {
      //   const downloadResult: IErrorResponse | ISuccessResponse = await productService.downloadDocument(
      //     categoryCode,
      //     brandValue,
      //     productSearchValue
      //   )
      //   const seller_code = localStorage.getItem('seller_code')

      //   if (downloadResult?.data?.length) {
      //     const data = downloadResult.data
      //       ?.filter((item) => {
      //         const find = matched_products.find((match) => match.item_code === item.erp_item_code)

      //         if (find !== undefined) return false

      //         return true
      //       })
      //       .map((item) => {
      //         const product = {}

      //         product['Seller code'] = seller_code
      //         product['Product'] = item.name
      //         product['Category'] = item.category.name['en-IN']
      //         product['Brand'] = item.brand.name['en-IN']
      //         product['Global SKU'] = item.sku
      //         product['Poorvika Product Code'] = item.erp_item_code
      //         product['Seller Product code'] = ''
      //         product['Wholesale Price'] = ''
      //         product['MRP'] = item.mrp

      //         return product
      //       })
      //     setCsvData(data)
      //     inputEl.current.link.click()
      //   } else showNotification(STATUS.FAILURE, 'No data to download')
      // } else {
      //   showNotification(STATUS.FAILURE, 'Please select Category or Brand')
      // }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to download the document')
    }
  }

  return (
    <div className="search-and-filter-layout">
      <ProductSearchBlock
        listProductsHandler={listProductsHandler}
        setBrandValue={setBrandValue}
        setCategoryCode={setCategoryCode}
        setBrandCode={setBrandCode}
        setProductSearchValue={setProductSearchValue}
        categoryCode={categoryCode}
        brandValue={brandValue}
        brandCode={brandCode}
        productSearchValue={productSearchValue}
        resetSearchInputs={resetSearchInputs}
      />

      <div className="search-product-tbl">
        {/* <div className="bulk-actions-wrapper">
            <p className="search-product-header">Bulk Actions</p>

            <button className="addproduct-btn-wrapper " onClick={() => handleDownload()}>
              <span className="btn-text">Export Product</span> <CloudDownload />
            </button> 

          </div> */}
        <div className="product-table-title">
          <p className="bulk-action-text">Bulk Actions: </p>
          <button className="addproduct-btn-wrapper " onClick={() => handleDownload()}>
            <FontAwesomeIcon className="excel-icon" icon={faFileExcel as IconProp} />{' '}
            <span className="btn-text">Download Template</span>
          </button>

          <CSVLink
            ref={inputEl}
            data={csvData}
            filename="product_template.csv"
            style={{ display: 'none' }}
            asyncOnClick
            onClick={(event, done) => {
              done()
            }}
          />

          <div>
            <label className="custom-file-upload">
              <input
                multiple={false}
                type="file"
                onChange={onFileChange}
                onClick={(event: any) => {
                  event.target.value = null
                }}
              />
              <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CloudUpload className="upload-icon" />
                <span style={{ marginRight: '1rem' }}>Upload Product</span>
              </span>
            </label>
            <span
              style={{
                color: 'red',
                textDecorationLine: 'underline',
                marginRight: '1rem',
                cursor: 'pointer',
                fontSize: '14px'
              }}
              onClick={() => handleSummary()}
            >
              Show History
            </span>
          </div>
        </div>
        <div className="filter-head">
          <div className="filter-item">
            <FontAwesomeIcon className="filter-icon" icon={faFilter as IconProp} />
          </div>
          <div className="filter-item">
            <Autocomplete
              classes={classes}
              getOptionLabel={(option: IPimProduct) => option.sku || ''}
              id="combo-box-demo"
              options={product || []}
              renderInput={(params) => <TextField {...params} label="SKU" size="small" variant="outlined" />}
              style={{ width: 200 }}
              value={filterSku}
              onChange={(e, val) => setFilterSku(val)}
            />
          </div>
          <div className="filter-item">
            <Autocomplete
              classes={classes}
              getOptionLabel={(option: IPimProduct) => option.erp_item_code || ''}
              id="combo-box-demo"
              options={product || []}
              renderInput={(params) => <TextField {...params} label="Poorvika Code" size="small" variant="outlined" />}
              style={{ width: 160 }}
              value={filterErpCode}
              onChange={(e, value) => setFilterErpCode(value)}
            />
          </div>
        
          <div className="filter-item">
            <ActionButton
              label="Apply"
              onClick={() => {
                handleProductFilter()
              }}
            />
            <ActionButton
              label="Clear"
              onClick={() => {
                setFilterSku({})
                setFilterErpCode({})
                listProductsHandler(1)
              }}
            />
          </div>
        </div>
        <table className="inventory-products-table">
          <thead>
            <tr>
              <th>
                Product
                <FontAwesomeIcon
                  className="sort-icon"
                  icon={sortColumn !== 'name' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                  onClick={() => sortingFunctionalityHandler('name', sortingType)}
                />
              </th>
              <th>
                Category
                <FontAwesomeIcon
                  className="sort-icon"
                  icon={sortColumn !== 'category' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                  onClick={() => sortingFunctionalityHandler('category', sortingType)}
                />
              </th>
              <th>
                Brand
                <FontAwesomeIcon
                  className="sort-icon"
                  icon={sortColumn !== 'brand' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                  onClick={() => sortingFunctionalityHandler('brand', sortingType)}
                />
              </th>
              <th>
                Global SKU
                <FontAwesomeIcon
                  className="sort-icon"
                  icon={sortColumn !== 'sku' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                  onClick={() => sortingFunctionalityHandler('sku', sortingType)}
                />
              </th>
              <th>
                Poorvika Product Code
                <FontAwesomeIcon
                  className="sort-icon"
                  icon={sortColumn !== 'erp_item_code' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                  onClick={() => sortingFunctionalityHandler('erp_item_code', sortingType)}
                />
              </th>

              <th> Action</th>
            </tr>
          </thead>
          {filtered_products?.length > 0 ? (
            <tbody>
              {filtered_products?.map((item) => {
                return (
                  <tr key={item.erp_item_code}>
                    <td>
                      <div className="product-col">
                        <div className="pimg-wrap">
                          <img alt={item.name} className="product-img" src={item?.image?.url} />
                        </div>
                        <div className="pcontent-wrap">{item.name}</div>
                      </div>
                    </td>
                    <td>{item.category}</td>
                    <td>{item.brand}</td>
                    <td>{item.sku}</td>
                    <td>{item.erp_item_code}</td>

                    <td className="map-locations-column">
                      {!item.listing_status ? (
                        <ActionButton
                          label="ADD"
                          onClick={() => {
                            setAddDialog(true)
                            setAddProducts(item)
                          }}
                        />
                      ) : (
                        <ActionButton label={item.listing_status} disabled />
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td className="empty-data" colSpan={12}>
                  No data to display
                </td>
              </tr>
            </tbody>
          )}
        </table>
        <div className="all-products-pagination">
          <Pagination
            className="all-products-pagination"
            page={pageNo}
            count={pageCount}
            shape="rounded"
            variant="outlined"
            onChange={handleChange}
          />
        </div>
      </div>

      <Dialog open={bulkUploadDialog} className="bulk-upload-result-dialog">
        <div className="bulk-upload-result">
          <h1 className="bulk-upload-heading">Product - Bulk Upload Result</h1>
          <div className="upload-result">
            <p className="successfull-items"> {uploadStatus?.success} products successfully added</p>
            <p className="failed-items"> {uploadStatus?.failed} products failed to add</p>
          </div>
          <div className="bulk-upload-buttons-wrapper">
            <ActionButton onClick={() => setsBulkUploadDialog(false)} label="Close" varient="outlined" />
            <ActionButton onClick={() => handleSummary()} label="Show Detailed History" />
          </div>
        </div>
      </Dialog>
      <Dialog className="add-products-dialog" open={AddDialog}>
        <form onSubmit={handleSubmit(addProductSeller)}>
          <div className="dialog-header">
            <div className="product-code-wrap">
              <span className="product-code-header">Product code: </span>&nbsp;
              {addproduct?.erp_item_code}
            </div>

            <DialogTitle className="products-title" id="alert-dialog-title">
              Do you want to add this Product ?
            </DialogTitle>
          </div>
          <div className="dialog-content-wrap">
            <div className="left-content-dialog">
              <div className="pimg-wrap">
                <img alt={addproduct?.name} className="product-img" src={addproduct?.image?.url} />
              </div>
              <div className="pcontent-wrap">{addproduct?.name}</div>
            </div>
            <div>
              <InputField
                inputRef={register({
                  required: 'Please enter Reason'
                })}
                label="Seller Product Unique Code"
                name="ext_product_id"
                style={{ margin: '5px' }}
                variant="outlined"
              />

              <InputField
                inputRef={register({
                  required: 'Please enter wholesale_price'
                })}
                label="WholeSale Price"
                name="wholesale_price"
                style={{ margin: '5px' }}
                variant="outlined"
                onChange={(event) =>
                  (event.target.value = event.target.value
                    .replace(/[^0-9.]/g, '')
                    .slice(0, 15)
                    )
                }
              />

              <InputField
                inputRef={register({
                  required: 'Please enter MRP'
                })}
                label="MRP Price"
                name="mrp"
                style={{ margin: '5px' }}
                variant="outlined"
                onChange={(event) =>
                  (event.target.value = event.target.value
                    .replace(/[^0-9.]/g, '')
                    .slice(0, 15)
                    )
                }
              />

              <DialogActions className="dialog-action-btn-wrap" style={{ margin: '0 auto' }}>
                <ActionButton label="Cancel" type="button" varient="outlined" onClick={() => setAddDialog(false)} />

                <ActionButton label="Add" type="submit" />
              </DialogActions>
            </div>
          </div>
        </form>
      </Dialog>

      <Dialog
        className="search-filter-products-dialog searchproduct-sort-dialog"
        open={sortDialog}
        onBackdropClick={() => setsortDialog(false)}
      >
        <form onSubmit={handleSubmit(async (data) => multipleproductFilter(data))}>
          <div className="search-sort-dialog">
            <InputWithLabels name="erp_item_code" register={register} text="Poorvika Product Code" notrequired />
            <InputWithLabels name="sku" register={register} text="Global SKU" notrequired />

            <InputWithLabels name="gtin" register={register} text="GTIN" notrequired />
          </div>

          <DialogActions style={{ margin: '0 auto' }}>
            <ActionButton label="Cancel" type="button" varient="outlined" onClick={() => setsortDialog(false)} />

            <ActionButton label="Search" type="submit" />
          </DialogActions>
        </form>
      </Dialog>
      <ResultSummaryDialog data={summaryResult} open={open} setOpen={setOpen} />
    </div>
  )
}

export default SearchProduct
