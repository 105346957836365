import React from 'react'

import './styles.scss';

type TSelectProps = {
options: string[]
selectedItem: string,
title: string,
register?: any,
name?: string
} & React.SelectHTMLAttributes<HTMLSelectElement>

const SelectDropDownWithLabel: React.FC<TSelectProps> = ({title,register,name,  selectedItem,options, ...props}) => {
    return (
        <div className="select-drop-down-with-label">
            <label className="txt-label">{title} <span className="label-star">*</span>  :</label>
            <select defaultValue={selectedItem}  className="input-selector" {...props} ref={register} name={name}>
                {options?.map((item,idx)=>  
                    <option key={item+"_"+idx} value={item}>{item}</option>
                )}
            </select>
        </div>
    )
}

export default SelectDropDownWithLabel
