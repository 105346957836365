import { AxiosError } from "axios";


import { API_ENDPOINTS, parseAxiosError, CENTRAL_INVENTORY_KEY, OMS_APP_URL_CLIENT_ID,OMS_APP_URL_CLIENT_SECRET } from "common/constant";

import { BaseService } from "./base.service";
import { IErrorResponse, ISuccessResponse } from "./interfaces/common.interface";
import { IWarehouseDetails } from "./interfaces/inventory.interface";


export class InventoryService extends BaseService {



  public async uploadDocument(uploadfile: FormData): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const seller_code = localStorage.getItem('seller_code');
    const url = `${API_ENDPOINTS.SELLER_WAREHOUSE}${API_ENDPOINTS.BULK_UPLOAD_INVENTORY}?SellerCode=${seller_code}`;

      const { data } = await this.httpClient.post(url, uploadfile);

        return data;
    } catch (error) {
        return parseAxiosError(error as AxiosError)
    }
}

public async downloadInventoryDocument(request_obj): Promise<ISuccessResponse | IErrorResponse> {
  try {
    const url = `${API_ENDPOINTS.CADD_INVENTORY_LIST}`
    const { data } = await this.httpClient.post(url, request_obj, {
      headers: {
        'x-api-key': CENTRAL_INVENTORY_KEY,
        'Content-Type': 'application/json'
      }});

      return data;
  } catch (error) {
      return parseAxiosError(error as AxiosError)
  }
}

public async getUploadHistory(): Promise<ISuccessResponse | IErrorResponse> {
  try {
    const seller_code = localStorage.getItem('seller_code');
    const url = `${API_ENDPOINTS.SELLER_WAREHOUSE}${API_ENDPOINTS.UPLOAD_INENTORY_HISTORY}?SellerCode=${seller_code}`;
    const { data } = await this.httpClient.get(url);

      return data;
  } catch (error) {
      return parseAxiosError(error as AxiosError)
  }
}




  public async addwarehouse(WarehouseData: IWarehouseDetails): Promise<ISuccessResponse | IErrorResponse> {

    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.CADD_WAREHOUSE_ADD_UPDATE, WarehouseData,
        {
          headers: {
            'x-api-key': CENTRAL_INVENTORY_KEY
          }
        } );

      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }


  public async updatewarehouse(WarehouseData): Promise<ISuccessResponse | IErrorResponse> {

    try {
      const { data } = await this.httpClient.put(API_ENDPOINTS.CADD_WAREHOUSE_ADD_UPDATE, WarehouseData,
        {
          headers: {
            'x-api-key': CENTRAL_INVENTORY_KEY
          }
        } );
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async active_deactive_warehouse(WarehouseData, status): Promise<ISuccessResponse | IErrorResponse> {
    try {
    const config = {
      headers: {
        'x-api-key': CENTRAL_INVENTORY_KEY,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify( {
        "code": WarehouseData.code,
        "isActive": status,
        "ext_warehouse_code": WarehouseData.ext_warehouse_code,
        "name": WarehouseData.name
      })
    }
      const { data } = await this.httpClient.delete(API_ENDPOINTS.CADD_WAREHOUSE_ADD_UPDATE, config );

      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getwarehouselist(seller_code: string | null, status: number): Promise<ISuccessResponse | IErrorResponse> {

    // try {
    //   const { data } = await this.httpClient.get(API_ENDPOINTS.CADD_WAREHOUSE_LIST+ '/' + status + '/' + seller_code,
    //   {
    //     headers: {
    //       'x-api-key': CENTRAL_INVENTORY_KEY,
    //       'Content-Type': 'application/json'
    //     }
    //   }  );

    //   return data;
    // } catch (error) {
    //   return parseAxiosError(error as AxiosError)
    // }
    try {
      const { data } = await this.httpClient.post(`${API_ENDPOINTS.CADD_WAREHOUSE_LIST}`, {"id": status,  "sellercode": seller_code, "seller_status": "Approval"},
      { headers: {
        'x-api-key': CENTRAL_INVENTORY_KEY,
        'Content-Type': 'application/json'
      }});
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getWarehouseListByStatus(warehouse_statusData): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.post(`${API_ENDPOINTS.CADD_WAREHOUSE_LIST}`, warehouse_statusData,
      { headers: {
        'x-api-key': CENTRAL_INVENTORY_KEY,
        'Content-Type': 'application/json'
      }});
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async postExtCodeApproveStatus(approve_statusDataObj): Promise<ISuccessResponse | IErrorResponse> {
    try {
      console.log(approve_statusDataObj,"approve_statusDataObj");
      const { data } = await this.httpClient.post(`${API_ENDPOINTS.CADD_WAREHOUSE_APPROVE}`, approve_statusDataObj,
      { headers: {
        'x-api-key': CENTRAL_INVENTORY_KEY,
        'Content-Type': 'application/json'
      }});
       return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }






  public async getInventoryListTotal(searchParam){
    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.CADD_INVENTORY_LIST_TOTAL, searchParam,
        { headers: {
        'x-api-key': CENTRAL_INVENTORY_KEY,
        'Content-Type': 'application/json'
      }});

      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getInventorylist(listingStatus: number, pageNo: number,supplier_Code) {

    // const seller_code = localStorage.getItem('seller_code')

    try {
      const url = `${API_ENDPOINTS.CADD_INVENTORY_LIST}`;
      let searchParam = {
        "limit": 10,
        "page": pageNo,
        "sellerCode":supplier_Code,
        "profile": "seller"
      };
      if (listingStatus !== -1){
        searchParam["inventoryStatus"] = listingStatus
      }

      const { data } = await this.httpClient.post( `${API_ENDPOINTS.CADD_INVENTORY_LIST}`, searchParam , {
        headers: {
          'x-api-key': CENTRAL_INVENTORY_KEY,
          'Content-Type': 'application/json'
        }});

      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
  public async addInventory(productData): Promise<ISuccessResponse | IErrorResponse> {
    const seller_code = localStorage.getItem('seller_code')

    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.CADD_INVENTORY, productData,
        { headers: {
        'x-api-key': CENTRAL_INVENTORY_KEY,
        'Content-Type': 'application/json'
      }});

      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }



  public async updateStock(productData): Promise<ISuccessResponse | IErrorResponse> {


    try {
      const { data } = await this.httpClient.post(`${API_ENDPOINTS.CADD_INVENTORY_STOCK_UPDATE}`, productData,
      { headers: {
        'x-api-key': CENTRAL_INVENTORY_KEY,
        'Content-Type': 'application/json'
      }});


      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async multipleSearchProductInventory( searchInput): Promise<ISuccessResponse | IErrorResponse> {

    try {
      const { data } = await this.httpClient.post( `${API_ENDPOINTS.CADD_INVENTORY_LIST}`, searchInput , {
        headers: {
          'x-api-key': CENTRAL_INVENTORY_KEY,
          'Content-Type': 'application/json'
        }});

      return data;


    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }


  public async historyProductInventory( searchInput): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data }  = await this.httpClient.post( `${API_ENDPOINTS.CADD_INVENTORY_HISTORY}`, searchInput , {
        headers: {
          'x-api-key': CENTRAL_INVENTORY_KEY,
          'Content-Type': 'application/json'
        }});
        const returnData = {
          "data": data,
          "status": data.status,
          "message": data?.message
        }

      return returnData;


    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async addupdatewarehouseInOMS(WarehouseData: any): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const SupplierCode = localStorage.getItem("supplier_apx_code");
      const { data } = await this.httpClient.post(API_ENDPOINTS.CREATE_UPDATE_WH_IN_OMS+"/"+SupplierCode, {...WarehouseData},
      {
        headers:{
          "CF-Access-Client-Id": OMS_APP_URL_CLIENT_ID,
          "CF-Access-Client-Secret": OMS_APP_URL_CLIENT_SECRET
        }
      } );
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async updatewarehousestatusInOMS(WarehouseData: any, isActiveStatus: Boolean): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const SupplierCode = localStorage.getItem("supplier_apx_code");
      const request_object = {
          "branchstatus": isActiveStatus,
          "apob_code": WarehouseData.ext_warehouse_code,
          "virtual_branch_code": WarehouseData.code
      }
      const { data } = await this.httpClient.post(API_ENDPOINTS.UPDATE_WH_STATUS_IN_OMS+"/"+SupplierCode, request_object,
      {
        headers:{
          "CF-Access-Client-Id": OMS_APP_URL_CLIENT_ID,
          "CF-Access-Client-Secret": OMS_APP_URL_CLIENT_SECRET
        }
      } );
      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
}