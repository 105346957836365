import React, { useEffect, useState } from 'react'

import './styles.scss'
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import LayoutHeader from '../../../components/LayoutHeader'
import { isVisible, LIST_OF_ROLES, showNotification, STATUS } from '../../../common/constant'
import { InventoryService } from '../../../services/inventory.service'
import ActionButton from '../../../components/ActionButton'
import Verification from '../../../assets/verification.png'
import { RootStore } from '../../../redux/store'
import { IErrorResponse, ISuccessResponse, ISuccessResponses } from '../../../services/interfaces/common.interface';
import { editLocation } from 'redux/actions/inventory.action';
import { activeLocationCount } from 'redux/actions/inventoryCount.action';




type ActiveLocationsprops = {

    onStatus: (data) => void;

}

const ActiveLocations: React.FC<ActiveLocationsprops> = ({ onStatus }) => {

    const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission);
    const dispatch = useDispatch()

    /*State Variables */
    const [warehouselist, setWarehouselist] = useState<any>([])
    const [deleteDialog, setDeleteDialog] = React.useState<boolean>(false)
    const [deletedcode, setDeletedCode] = React.useState<string>()

    /* Services */
    const inventoryService: InventoryService = new InventoryService()

    const displaystringChange = function(warehouselisttmp){
            let resultObjec : any = [];
            for(let cnt =0; cnt < warehouselisttmp.length ; cnt++ ){
                // Check if there exist an object in empty array whose CategoryId matches
                let isElemExist = resultObjec.findIndex(function(item) {
                    let option1 =  item.state.split(" ").join("").toLowerCase()
                    let option2 = warehouselisttmp[cnt].state.split(" ").join("").toLowerCase()
                    return option1 === option2;
                })
                if (isElemExist === -1) {
                    let obj = {};
                    obj["code"] = warehouselisttmp[cnt].code;
                    obj["count"] = 1;
                    obj["state"] = warehouselisttmp[cnt].state;
                    resultObjec.push(obj)
                } else {
                    resultObjec[isElemExist].count += 1
                }            
            }
            let displayCountString = "";
            for(let cnt =0; cnt < resultObjec.length ; cnt++ ){
                if(cnt == resultObjec.length-1){
                    displayCountString += resultObjec[cnt]["state"] +" : " + resultObjec[cnt]["count"]
                } else{
                    displayCountString += resultObjec[cnt]["state"] +" : " + resultObjec[cnt]["count"] +",  "
                }
            }
            // displayCountString =  resultObjec.map(function(item){
            // return 
            // })
            console.log("displayCountString",displayCountString)
            return displayCountString;
    }
   
    /*Get Active Warehouse List Handler */
    const getWareHouse = async () => {
        try {
            const SupplierCode = localStorage.getItem("supplier_apx_code")
            if(!!SupplierCode){
                const updateSellerStatus = {
                    "id":1,
                    "sellercode": SupplierCode,
                    "seller_status": "Approval"
                }
                const getWareHouseResponse: ISuccessResponse | IErrorResponse = await inventoryService.getWarehouseListByStatus(updateSellerStatus);
                //const getWareHouseResponse: ISuccessResponse | IErrorResponse = await inventoryService.getwarehouselist(SupplierCode, 1)
                if(getWareHouseResponse.status === STATUS.SUCCESS){
                    setWarehouselist(getWareHouseResponse.data.warehouselist)
                    dispatch(activeLocationCount(getWareHouseResponse.data.warehouselist.length))
                    let displayCountString : any = "";
                    if(getWareHouseResponse.data.warehouselist.length > 0){
                        // displayCountString =  getWareHouseResponse.data.warehouselist.length + ' ' + "Active"  ;
                        displayCountString = displaystringChange(getWareHouseResponse.data.warehouselist)
                    } else{
                        displayCountString = "0 : Active"
                    }
                   // onStatus(getWareHouseResponse.data.warehouselist ? getWareHouseResponse.data.warehouselist.length + ' ' + "Active" : 0 + ' ' + "Active")
                    onStatus(displayCountString)
                } else{
                    showNotification(STATUS.FAILURE, 'Error - Unable to warehouse list')
                }
            }
        } catch (error) {
            console.log(error)
            showNotification(STATUS.FAILURE, ' Unable to warehouse list')
        }
    }

    const deletedialog = (deletedcode) => {
        setDeletedCode(deletedcode)
        setDeleteDialog(true)
    }

    useEffect(()=>{
        dispatch(editLocation(false));
        getWareHouse();
    },[])

    const deletewarehouse = async () => {
        setDeleteDialog(false)
        try {
            const deActivateHouseResponse: ISuccessResponse | IErrorResponse = await inventoryService.active_deactive_warehouse(deletedcode, 1)
            const deActiveInOMS: ISuccessResponse | IErrorResponse = await inventoryService.updatewarehousestatusInOMS(deletedcode, false)
            showNotification(STATUS.SUCCESS, deActivateHouseResponse.message)
        } catch (error) {
            showNotification(STATUS.FAILURE, ' Unable to deactivate warehouse')
        }

        getWareHouse()
    }


    const handleOnEdit = () => {

        dispatch(editLocation(true))
    }

    return (
        <div className="Activelocations-page">
            {warehouselist ?
                <div>
                    {
                        warehouselist.map((warehouseData, idx) => {
                            return (
                                <div className="location-layout-wrapper" key={warehouseData.warehouse_code+"_"+idx}>
                                    <LayoutHeader title={warehouseData.name + ' ( ' + warehouseData.code + ')'} edit={isVisible(["WAREHOUSE_EDIT"],{user: userPermission})}  remove={isVisible(["WAREHOUSE_DELETE"],{user: userPermission} )} to="/sellerLayout/inventory/AddLocations"  state={warehouseData} onClick={() => deletedialog(warehouseData)} editOnClick={handleOnEdit}/>
                                    <div className="activelocation-details-card">
                                        <div className="activelocation-details-content">
                                            <ImageOutlinedIcon className="warehouse-image-ic" />
                                            <div className="registered-address-content">
                                                <p className="warehouse-details-label">Registered Address</p>
                                                <p className="warehouse-details-content">{warehouseData.address1 + ',' + warehouseData.address2 + ','
                                                    + warehouseData.city + ',' + warehouseData.state + ',' + warehouseData.pincode}</p>
                                            </div>
                                            <div className="primary-contact-content">
                                                <p className="warehouse-details-label">Primary Contact :</p>
                                                <p className="warehouse-details-content">{warehouseData.primary_contact_name}</p>
                                                <p className="warehouse-details-content">{warehouseData.primary_contact_email}</p>
                                                <p className="warehouse-details-content">{warehouseData.primary_contact_mobile}</p>

                                            </div>

                                            <div className="primary-contact-content">
                                                <p className="warehouse-details-label">Escalations Contact :</p>
                                                <p className="warehouse-details-content">{warehouseData.escalations_contact_name}</p>
                                                <p className="warehouse-details-content">{warehouseData.escalations_contact_email}</p>
                                                <p className="warehouse-details-content">{warehouseData.escalations_contact_mobile}</p>

                                            </div>

                                        </div>
                                        {isVisible([LIST_OF_ROLES.WAREHOUSE_ADD_PRODUCT],{user:userPermission} ) ?
                                        <NavLink className="see-listed-product" to={{ pathname:"/sellerLayout/inventory/AddInventoryProducts", state: warehouseData }}>
                                            <div style={{display:'flex', justifyContent:'end'}}>
                                               <ActionButton label="+ Add products to Warehouse" type="button" />

                                            </div>

                                        </NavLink> : <></>}
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
                :
                <div className="location-layout-wrapper no-warehouse">
                    <div className="No-Active-card-wrapper">
                        <img src={Verification} alt="verification" className="verification-image" />

                        No Active Locations Found

                    </div>
                </div>
            }


            <Dialog open={deleteDialog} className="warehouse-delete-dialog">
                <DialogTitle className="delete-title" id="alert-dialog-title">
                Are you sure you want to deactivate this warehouse?
                </DialogTitle>

                <DialogActions style={{ margin: '0 auto' }}>
                    <ActionButton label="Cancel" varient="outlined" onClick={() => setDeleteDialog(false)} />

                    <ActionButton label="De-Activate" onClick={() => deletewarehouse()} />
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default ActiveLocations