import React, { useEffect, useState } from 'react'
import Pagination from '@material-ui/lab/Pagination'
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { faSort, faSortUp, faSortDown, faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'

import './styles.scss'
import { ProductService } from '../../../../services/productService'
import { IErrorResponse, ISuccessResponse } from '../../../../services/interfaces/common.interface'
import { showNotification, STATUS } from '../../../../common/constant'
import ActionButton from '../../../../components/ActionButton'
import InputWithLabels from '../../../../components/InputWithLabels'
import ProductDetailsSlider from '../../../../components/productDetailsSlidder'
import { IListingProduct } from '../../../../services/interfaces/product.interface'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type ALLProductprops = {
  searchInput: string
  products: []
}

const useStyles = makeStyles(() => ({
  inputRoot: {
    color: 'black',
    background: 'white'
  },
  // popupIndicator: {
  //   color: 'red',
  // },
  root: {
    padding: `0px`
  }
}))

const RejectTable: React.FC<ALLProductprops> = ({ searchInput, products }) => {
  const classes = useStyles()

  const { register, handleSubmit } = useForm({ mode: 'all' })

  const [product, setProducts] = useState<IListingProduct[]>([])
  const [pageCount, setPageCount] = useState<number>()
  const [sortDialog, setsortDialog] = React.useState<boolean>(false)
  const [sortingType, setSortingType] = useState<string>('1')
  const [sortColumn, setSortColumn] = useState<string>('')
  const [filterCategory, setFilterCategory] = useState<any>({})
  const [filterSku, setFilterSku] = useState<any>({})
  const [filterbrand, setFilterBrand] = useState<any>({})
  const [filterErpCode, setFilterErpCode] = useState<any>({})
  const [filterName, setFilterName] = useState<any>({})
  const [filterSelCode, setFilterSelCode] = useState<any>({})
  const [filterPsn, setFilterPsn] = useState<any>({})
  const [displayModal, setDisplayModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState<IListingProduct>()

  const productService: ProductService = new ProductService()

  const getPIMProductCategories = async (pageNO) => {
    try {
      const Pimproducts: ISuccessResponse | IErrorResponse = await productService.getSellerProductsWithoutWarehose('Rejected', pageNO)
      const getpimproducts = Pimproducts as ISuccessResponse
      setProducts(getpimproducts.data)
      setPageCount(getpimproducts.pagecount)
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Product informations')
    }
  }

  const multipleProductFilter = async (data, columnName?: string, sortType?: string) => {
    try {
      const Pimproducts: ISuccessResponse | IErrorResponse = await productService.multipleSearchProduct(
        'Rejected',
        1,
        data,
        columnName,
        sortType
      )
      const getpimproducts = Pimproducts as ISuccessResponse

      setProducts(getpimproducts.data)
      setPageCount(getpimproducts.pagecount)
      setsortDialog(false)
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Product informations')
      setsortDialog(false)
    }
  }

  const handleProductFilter = () => {
    const data = {
      erp_item_code: filterErpCode ? filterErpCode.erp_item_code : '',
      category: filterCategory ? filterCategory.category : '',
      sku: filterSku ? filterSku.sku : '',
      brand: filterbrand?.brand ?? '',
      name: filterName ? filterName.name : '',
      ext_prod_id: filterSelCode ? filterSelCode.ext_prod_id : '',
      psn: filterPsn ? filterPsn.psn : ''
    }
    multipleProductFilter(data)
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    getPIMProductCategories(value)
  }

  // const statusProductHandler = async (status: string, pimcode: string) => {
  //     console.log(status, pimcode);
  //     try {
  //         const approveProduct = await productService.approveProduct(status, pimcode)
  //         console.log(approveProduct)
  //         getPIMProductCategories(1);
  //     } catch (error) {
  //         showNotification(STATUS.FAILURE, 'Unable to approve Product ')

  //     }
  // }

  /* To sort the table data based on column */
  const sortingFunctionalityHandler = (columnName: string, sortType: string) => {
    const data = {
      erp_item_code: filterErpCode ? filterErpCode.erp_item_code : '',
      sku: filterSku ? filterSku.sku : '',
      brand: filterbrand?.brand ?? '',
      category: filterCategory ? filterCategory.category : '',
      name: filterName ? filterName.name : '',
      ext_prod_id: filterSelCode ? filterSelCode.ext_prod_id : '',
      psn: filterPsn ? filterPsn.psn : ''
    }
    multipleProductFilter(data, columnName, sortType)
    setSortColumn(columnName)
    setSortingType(sortingType === '1' ? '-1' : '1')
  }

  useEffect(() => {
    getPIMProductCategories(1)
  }, [searchInput])

  return (
    <div className="Inventory-table-body">
      <th className="filter-head">
        <div className="filter-item">
          <FontAwesomeIcon className="filter-icon" icon={faFilter as IconProp} />
        </div>
        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={products || []}
            value={filterName}
            getOptionLabel={(option: IListingProduct) => option.name || ''}
            onChange={(e, val) => setFilterName(val)}
            style={{ width: 300 }}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="Product Name" variant="outlined" />}
          />
        </div>
        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={products || []}
            value={filterSku}
            getOptionLabel={(option: IListingProduct) => option.sku || ''}
            onChange={(e, val) => setFilterSku(val)}
            style={{ width: 200 }}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="SKU" variant="outlined" />}
          />
        </div>

        <div className="filter-item">
          <Autocomplete
            classes={classes}
            getOptionLabel={(option: IListingProduct) => option.brand || ''}
            id="combo-box-demo"
            options={
              products?.filter(
                (v: IListingProduct, i, a) => a.findIndex((t: IListingProduct) => t.brand === v.brand) === i
              ) || []
            }
            renderInput={(params) => <TextField {...params} label="Brand" size="small" variant="outlined" />}
            style={{ width: 200 }}
            value={filterbrand}
            onChange={(e, val) => 
              {
                alert(val)
               
               
            }
          }
          />
        </div>

        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={products || []}
            value={filterErpCode}
            getOptionLabel={(option: IListingProduct) => option.erp_item_code || ''}
            style={{ width: 160 }}
            onChange={(e, value) => setFilterErpCode(value)}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="Poorvika Code" variant="outlined" />}
          />
        </div>
        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={products || []}
            value={filterSelCode}
            getOptionLabel={(option: IListingProduct) => option.ext_prod_id || ''}
            style={{ width: 160 }}
            onChange={(e, value) => setFilterSelCode(value)}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="Seller Code" variant="outlined" />}
          />
        </div>

        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={products || []}
            value={filterPsn}
            getOptionLabel={(option: IListingProduct) => option.psn || ''}
            style={{ width: 160 }}
            onChange={(e, value) => setFilterPsn(value)}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="PSN" variant="outlined" />}
          />
        </div>
        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={
              products?.filter(
                (v: IListingProduct, i, a) => a.findIndex((t: IListingProduct) => t.category === v.category) === i
              ) || []
            }
            value={filterCategory}
            getOptionLabel={(option: IListingProduct) => option.category || ''}
            onChange={(e, val) => setFilterCategory(val)}
            style={{ width: 200 }}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="Category" variant="outlined" />}
          />
        </div>
        <div className="filter-item">
          <ActionButton
            onClick={() => {
              handleProductFilter()
            }}
            label="Apply"
          />
          <ActionButton
            label="Clear"
            onClick={() => {
              setFilterSku(null)
              setFilterBrand(null)
              setFilterCategory(null)
              setFilterErpCode(null)
              setFilterName(null)
              setFilterSelCode(null)
              setFilterPsn(null)
            }}
          />
        </div>
      </th>
      <table className="inventory-products-table">
        <thead>
          <tr>
            <th>
              Product
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'name' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('name', sortingType)}
              />
            </th>
            <th>
              Category
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'category' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('category', sortingType)}
              />
            </th>
            <th>
              Brand
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'brand' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('brand', sortingType)}
              />
            </th>
            <th>
              Global SKU
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'sku' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('sku', sortingType)}
              />
            </th>
            <th>
              Poorvika Product Code
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'erp_item_code' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('erp_item_code', sortingType)}
              />
            </th>
            <th>
              Seller Product Code
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'ext_prod_id' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('ext_prod_id', sortingType)}
              />
            </th>
            <th>
              PSN
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'psn' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('psn', sortingType)}
              />
            </th>
            <th>Reason </th>
          </tr>
        </thead>
        {product ? (
          <tbody>
            {product.map((item) => {
              return (
                <tr key={item.erp_item_code}>
                  <td
                    onClick={() => {
                      setSelectedItem(item)
                      setDisplayModal(!displayModal)
                    }}
                  >
                    <div className="product-col">
                      <div className="pimg-wrap">
                        <img className="product-img" alt="product-img" src={item.image} />
                      </div>
                      <div className="pcontent-wrap">{item.name}</div>
                    </div>
                  </td>
                  <td>{item.category}</td>
                  <td>{item.brand}</td>
                  <td>{item.sku}</td>

                  <td>{item.erp_item_code}</td>

                  <td>{item.ext_prod_id}</td>
                  <td>{item.psn}</td>
                  <td>{item?.reason}</td>
                </tr>
              )
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td className="empty-data" colSpan={12}>
                No data to display
              </td>
            </tr>
          </tbody>
        )}
      </table>
      <div className="all-products-pagination">
        <Pagination
          className="all-products-pagination"
          count={pageCount}
          shape="rounded"
          variant="outlined"
          onChange={handleChange}
        />
      </div>
      <Dialog className="search-filter-products-dialog" open={sortDialog}>
        <form onSubmit={handleSubmit(async (data) => multipleProductFilter(data))}>
          <DialogTitle className="products-title" id="alert-dialog-title">
            Do you want to Search this Product
          </DialogTitle>

          <div className="search-sort-dialog">
            <InputWithLabels name="name" register={register} text="Name" notrequired />
            <InputWithLabels name="wholesale_price" register={register} text="WholeSale Price" notrequired />
            <InputWithLabels name="mrp" register={register} text="MRP" notrequired />
            <InputWithLabels name="erp_item_code" register={register} text="ERP_ITEM_CODE" notrequired />
            <InputWithLabels name="sku" register={register} text="SKU" notrequired />
            <InputWithLabels name="ext_prod_id" register={register} text="Seller Unique Product ID" notrequired />
            <InputWithLabels name="psn" register={register} text="PSN" notrequired />
          </div>

          <DialogActions style={{ margin: '0 auto' }}>
            <ActionButton label="Cancel" type="button" varient="outlined" onClick={() => setsortDialog(false)} />

            <ActionButton label="Search" type="submit" />
          </DialogActions>
        </form>
      </Dialog>

      <ProductDetailsSlider
        productDetails={selectedItem}
        displayModal={displayModal}
        handleClose={() =>{
           setDisplayModal(!displayModal)
           getPIMProductCategories(1)
          }}
      />
    </div>
  )
}
export default RejectTable
