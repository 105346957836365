import React, { useState } from 'react'
import { Badge } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { isVisible, LIST_OF_ROLES } from 'common/constant'
import { IPurchaseOrder } from 'services/interfaces/purchaseorder.interface'
import PurchaseOrderAllForm from 'containers/form/PurchaseOrderAllForm/index'
import { RootStore } from 'redux/store'
import { useSelector } from 'react-redux'
import moment from 'moment'

import './styles.scss'
import { OnPurchaseOrderService } from 'services/purchaseOrder.service'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'


type Props = {
  data: IPurchaseOrder
  getPurchaseOrders?: () => void
}

const ItemcodeImgs: React.FC<Props> = ({ data, getPurchaseOrders }) => {
  const [addform, setaddform] = React.useState(false)
  const history = useHistory()
  const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission)
  const getPurchaseOrderList = new OnPurchaseOrderService();

  const [formdatas, setFormdatas] = useState<IPurchaseOrder | []>([])

  // console.log(purchaseOrderLists[0].image ,"dsafasf");
  const updateStatus = async() => {
    if (!isVisible([LIST_OF_ROLES.PURCHASE_ORDER_VIEW], { user: userPermission })) return null
    else if (data.AdditionalStatus === 'ACTI' || data.AdditionalStatus === '') {
      debugger;
      const sellerBranchInfo: ISuccessResponse | IErrorResponse = await getPurchaseOrderList.getSellerBranchInfoFromOMS("apob",data.BranchCode);
      const sellerBranchData = sellerBranchInfo as ISuccessResponse;
      let virtual_branch_code = "";
      if(sellerBranchData.data){
        const branch = sellerBranchData.data.branch_details.find((branch) => branch.apob_code === data.BranchCode);
        virtual_branch_code = branch ? branch.virtual_branch_code : null;
      }
      let rdata = { ...data };
      rdata["virtual_branch_code"] = virtual_branch_code;
      setFormdatas(rdata)
      setaddform(true)
    } else if (data.AdditionalStatus === 'PROC' || data.AdditionalStatus === 'INVO') {
      history.push({
        pathname: '/SellerLayout/DispatchOrder/ProductsAccepted',
        state: data
      })
    }
  }

  const statusName = (varient) => {
    if (varient === 'ACTI' || data.AdditionalStatus === '') {
      return <p className="seller-account-status-active">Pending</p>
    } else if (varient === 'PROC') {
      return <p className="seller-account-status-active">PO Accepted</p>
    } else if (varient === 'INVO') {
      return <p className="seller-account-status-active">Invoiced</p>
    } else if (varient === 'SHIP') {
      return <p className="seller-account-status-in-active">Dropshipped</p>
    } else if (varient === 'CANC') {
      return <p className="seller-account-status-in-active">Rejected</p>
    }

    return <p className="seller-account-status-in-active">Closed</p>
  }

  const closePurchaseForm = () => {
    setaddform(false)
  }

  const cancelClose = () => {
    setaddform(false)
  }

  const StyledBadge = withStyles(() => ({
    badge: {
      right: -3,
      top: 13,
      border: `2px solid #ffd4c0`,
      background: '#ffebd6',
      color: '#080808',
      padding: '0 4px'
    }
  }))(Badge)

  return (
    <>
      <PurchaseOrderAllForm
        cancelClose={cancelClose}
        formdatas={formdatas}
        handleClose={closePurchaseForm}
        open={addform}
        title="Purchase"
        getPurchaseOrders={getPurchaseOrders}
      />
     
      <tr style={{ cursor: 'pointer' }} onClick={() => updateStatus()}>
        <td>{data?.OrderDate ? moment(data.OrderDate, 'YYYYMMDD').format('YYYY-MM-DD') : <>-</>}</td>
        <td>{data.OrderNumb}</td>
        <td>
          <div className="product-col">
            <div className="pimg-wrap">
              <div className="plus-wrap">
                <img
                  alt="product-img"
                  className="product-img"
                  src={data?.Items[0]?.ItemImage}
                  style={{ width: '40px' }}
                />
                {data.Items.length > 1 ? (
                  <div className="bad-wrp">
                    <StyledBadge badgeContent={`+${data.Items.length - 1}`} color="secondary">
                      {' '}
                    </StyledBadge>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="pcontent-wrap">{data.Items[0].ItemName}</div>
          </div>
        </td>
        <td>{data.RefBillNo}</td>
        <td>
          <span>Rs. </span>
          {data?.OrderAmount.toFixed(2)}
        </td>
        <td>{data.Items[0].OrderQty}</td>
        <td>{statusName(data.AdditionalStatus)}</td>
      </tr>
    </>
  )
}

export default ItemcodeImgs
