import { faSort, faSortUp, faSortDown, faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Autocomplete, TextField, FormControl, InputLabel, Select, MenuItem, Pagination, Dialog, DialogActions } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import { useEffect, useState } from 'react'

import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'

import { ProductService } from 'services/productService'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
import { showNotification, STATUS } from 'common/constant'
import ActionButton from 'components/ActionButton'
import ListingStatus from 'components/ListingStatus'
import ProductDetailsSlider from 'components/productDetailsSlidder'
import { IListingProduct, IAddProductCatalog, IEditProductCatalog, } from 'services/interfaces/product.interface'
import { useForm } from 'react-hook-form'
import EditIcon from '@mui/icons-material/Edit'
import Tooltip from '@mui/material/Tooltip'
import InputField from 'components/InputField/InputField'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type ALLProductprops = {
  products: []
  getAllProducts: () => void
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300
  },
  margin: {
    height: theme.spacing(3)
  },
  thumb: {
    background: '#ff632b',
    '&~&': {
      background: '#ff632b'
    }
  },
  mark: {
    background: 'black'
  },
  rail: {
    background: 'grey'
  },
  track: {
    background: '#ff632b !important'
  },
  valueLabel: {
    '&>*': {
      background: 'black'
    }
  }
}))

const ListedTable: React.FC<ALLProductprops> = ({ products, getAllProducts }) => {
  const classes = useStyles()
  const [product, setProducts] = useState<IListingProduct[]>([])
  const [pageCount, setPageCount] = useState<number>()
  const [sortingType, setSortingType] = useState<string>('1')
  const [sortColumn, setSortColumn] = useState<string>('')
  const [filterCategory, setFilterCategory] = useState<any>({})
  const [filterSku, setFilterSku] = useState<any>({})
  const [filterbrand, setFilterBrand] = useState<any>({})
  const [filterErpCode, setFilterErpCode] = useState<any>({})
  const [filterName, setFilterName] = useState<any>({})
  const [filterSelCode, setFilterSelCode] = useState<any>({})
  const [filterPsn, setFilterPsn] = useState<any>({})
  const [menu_text, setMenuText] = useState<string>('1')
  const [filterMrp, setFilterMrp] = useState<number[]>([0, 35000])
  const [filterPrice, setFilterPrice] = useState<number[]>([0, 35000])
  const [maxMrp, setMaxMrp] = useState<number>(0)
  const [maxPrice, setMaxPrice] = useState<number>(0)
  const [displayModal, setDisplayModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState<IListingProduct>()
  const { register, handleSubmit } = useForm({ mode: 'all' })
  const [showProductEditDialog, setShowProductEditDialog] = useState<boolean>(false)
  const [editableProduct, setEditableProduct] = useState<IListingProduct | undefined>(undefined)
  const [showEditConfirmation, setShowEditConfirmation] = useState<boolean>(false)

  const productService: ProductService = new ProductService()

  const multipleProductFilter = async (data, columnName?: string, sortType?: string) => {
    try {
      const Pimproducts: ISuccessResponse | IErrorResponse = await productService.multipleSearchProduct(
        'Delisted',
        1,
        data,
        columnName,
        sortType
      )
      const getpimproducts = Pimproducts as ISuccessResponse

      setProducts(getpimproducts.data)
      setPageCount(getpimproducts.pagecount)
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Product informations')
    }
  }

  const handleProductFilter = () => {
    const data = {
      erp_item_code: filterErpCode?.erp_item_code ?? '',
      sku: filterSku?.sku ?? '',
      brand: filterbrand?.brand ?? '',
      category: filterCategory?.category ?? '',
      name: filterName?.name ?? '',
      ext_prod_id: filterSelCode?.ext_prod_id ?? '',
      psn: filterPsn?.psn ?? '',
      minPrice: filterPrice ? filterPrice[0] : '',
      maxPrice: filterPrice ? filterPrice[1] : '',
      minMrp: filterMrp ? filterMrp[0] : '',
      maxMrp: filterMrp ? filterMrp[1] : ''
    }
    multipleProductFilter(data)
  }

  const getSellerProducts = async (pageNo) => {
    try {
      const Pimproducts: ISuccessResponse | IErrorResponse = await productService.getSellerProductsWithoutWarehose('Delisted', pageNo)
      const getpimproducts = Pimproducts as ISuccessResponse
      setProducts(getpimproducts.data)
      setPageCount(getpimproducts.pagecount)
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Product informations')
    }
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    getSellerProducts(value)
  }

  const statusProductHandler = async (status: string, pimcode: string, erp_code) => {
    try {
      const approveProduct: ISuccessResponse | IErrorResponse = await productService.approveProduct(status, pimcode, erp_code)

      if (approveProduct.status) {
        showNotification(STATUS.SUCCESS, `Product is ${status} successfully`)
        getAllProducts()
      }

      getSellerProducts(1)
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to approve Product ')
    }
  }

  /* To sort the table data based on column */
  const sortingFunctionalityHandler = (columnName: string, sortType: string) => {
    const data = {
      erp_item_code: filterErpCode?.erp_item_code ?? '',
      sku: filterSku?.sku ?? '',
      brand: filterbrand?.brand ?? '',
      name: filterName?.name ?? '',
      category: filterCategory?.category ?? '',
      ext_prod_id: filterSelCode?.ext_prod_id ?? '',
      psn: filterPsn?.psn ?? '',
      minPrice: filterPrice ? filterPrice[0] : '',
      maxPrice: filterPrice ? filterPrice[1] : '',
      minMrp: filterMrp ? filterMrp[0] : '',
      maxMrp: filterMrp ? filterMrp[1] : ''
    }
    multipleProductFilter(data, columnName, sortType)
    setSortColumn(columnName)
    setSortingType(sortingType === '1' ? '-1' : '1')
  }

  useEffect(() => {
    const maxPrice =
      products?.length > 0 ? Math.max(...products.map((item: IListingProduct) => item.wholesale_price)) : 0
    const maxMrp = products?.length > 0 ? Math.max(...products.map((item: IListingProduct) => item.mrp)) : 0

    setMaxPrice(maxPrice)
    setMaxMrp(maxMrp)
    setFilterMrp([0, maxMrp])
    setFilterPrice([0, maxPrice])
  }, [products])

  useEffect(() => {
    getSellerProducts(1)
  }, [])

  const editProductConfirmationHandler = (): void => {
    setShowEditConfirmation(false)
    setShowProductEditDialog(true)
  }

  const productEditConfirmationHandler = (product: any) => {
    setShowEditConfirmation(true)
    setEditableProduct(product)
  }


  const editProductPriceHandler = async (data: IAddProductCatalog): Promise<void> => {
    try {
      const editProductdata: IEditProductCatalog = {
        seller_code: editableProduct?.seller_code as string,
        erp_item_code: editableProduct?.erp_item_code as string,
        mrp: Number(data?.mrp) as number,
        wholesale_price: Number(data.wholesale_price) as number,
        ext_prod_id: data.ext_product_id as string
      }

      /* Condition to avoid unwanted API calls when price is not modified */
      if (Number(data.wholesale_price) === editableProduct?.wholesale_price && Number(data.mrp) === editableProduct?.mrp) {
        showNotification(STATUS.WARNING, "Kindly update the price values to save");
  
        return
      }

      const editProductCatelogResult = await productService.editProductCatalog(editProductdata)

      if (editProductCatelogResult.status === STATUS.SUCCESS) {
        setShowProductEditDialog(false)
        showNotification(STATUS.SUCCESS, 'Product Price updated Successfully')
        getSellerProducts(1)
      } else {
        showNotification(STATUS.FAILURE, 'Unable to edit product information')
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to edit product information')
    }
  }

  return (
    <div className="Inventory-table-body">
      <th className="filter-head">
        <div className="filter-item">
          <FontAwesomeIcon className="filter-icon" icon={faFilter as IconProp} />
        </div>
        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={products || []}
            value={filterName}
            getOptionLabel={(option: IListingProduct) => option.name || ''}
            onChange={(e, val) => setFilterName(val)}
            style={{ width: 300 }}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="Product Name" variant="outlined" />}
          />
        </div>
        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={products || []}
            value={filterSku}
            getOptionLabel={(option: IListingProduct) => option.sku || ''}
            onChange={(e, val) => setFilterSku(val)}
            style={{ width: 200 }}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="SKU" variant="outlined" />}
          />
        </div>

        <div className="filter-item">
          <FormControl fullWidth>
            <Autocomplete
              classes={classes}
              getOptionLabel={(option: IListingProduct) => option.brand || ''}
              id="combo-box-demo"
              options={
                products?.filter(
                  (v: IListingProduct, i, a) => a.findIndex((t: IListingProduct) => t.brand === v.brand) === i
                ) || []
              }
              renderInput={(params) => <TextField {...params} label="Brand" size="small" variant="outlined" />}
              style={{ width: 200 }}
              value={filterbrand}
              onChange={(e, val) => setFilterBrand(val)}
            />
          </FormControl>
        </div>

        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={
              products?.filter(
                (v: IListingProduct, i, a) => a.findIndex((t: IListingProduct) => t.category === v.category) === i
              ) || []
            }
            value={filterCategory}
            getOptionLabel={(option: IListingProduct) => option.category || ''}
            onChange={(e, val) => setFilterCategory(val)}
            style={{ width: 200 }}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="Category" variant="outlined" />}
          />
        </div>
        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={products || []}
            value={filterErpCode}
            getOptionLabel={(option: IListingProduct) => option.erp_item_code || ''}
            style={{ width: 160 }}
            onChange={(e, value) => setFilterErpCode(value)}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="Poorvika Code" variant="outlined" />}
          />
        </div>
        <div className="filter-item" style={{ width: '250px' }}>
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">Price</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={menu_text}
              style={{ color: 'rgba(0, 0, 0, 0.6)' }}
              label="Price"
              onClose={() => setMenuText('1')}
            >
              <MenuItem value="" style={{ display: 'flex', flexDirection: 'column' }}>
                <Slider
                  getAriaLabel={() => 'Price range'}
                  value={filterPrice}
                  min={0}
                  max={maxPrice}
                  valueLabelDisplay="off"
                  onChange={(event, value: any) => setFilterPrice(value)}
                  track="inverted"
                  classes={{
                    thumb: classes.thumb,
                    rail: classes.rail,
                    track: classes.track,
                    valueLabel: classes.valueLabel
                  }}
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    size="small"
                    disabled
                    value={filterPrice[0]}
                    type="number"
                    style={{ width: 'auto', maxWidth: '130px', paddingRight: '10px' }}
                  >
                    {' '}
                  </TextField>
                  to{' '}
                  <TextField
                    size="small"
                    disabled
                    value={filterPrice[1]}
                    type="number"
                    style={{ width: 'auto', maxWidth: '130px', paddingLeft: '10px' }}
                  >
                    {' '}
                  </TextField>
                </div>
              </MenuItem>
              <MenuItem
                value="1"
                style={{ display: 'none' }}
              >{`Price Range : ${filterPrice[0]} - ${filterPrice[1]}`}</MenuItem>
            </Select>
          </FormControl>
        </div>

        {/* <div className="filter-item" style={{ width: '250px' }}>
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">MRP</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={menu_text}
              label="MRP"
              style={{ color: 'rgba(0, 0, 0, 0.6)' }}
              onClose={() => setMenuText('1')}
            >
              <MenuItem value="" style={{ display: 'flex', flexDirection: 'column' }}>
                <Slider
                  getAriaLabel={() => 'MRP range'}
                  value={filterMrp}
                  min={0}
                  max={maxMrp}
                  valueLabelDisplay="off"
                  onChange={(event, value: any) => setFilterMrp(value)}
                  track="inverted"
                  classes={{
                    thumb: classes.thumb,
                    rail: classes.rail,
                    track: classes.track,
                    valueLabel: classes.valueLabel
                  }}
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    size="small"
                    disabled
                    value={filterMrp[0]}
                    type="number"
                    style={{ width: 'auto', maxWidth: '130px', paddingRight: '10px' }}
                  >
                    {' '}
                  </TextField>
                  to{' '}
                  <TextField
                    size="small"
                    disabled
                    value={filterMrp[1]}
                    type="number"
                    style={{ width: 'auto', maxWidth: '130px', paddingLeft: '10px' }}
                  >
                    {' '}
                  </TextField>
                </div>
              </MenuItem>
              <MenuItem
                value="1"
                style={{ display: 'none' }}
              >{`MRP Range : ${filterMrp[0]} - ${filterMrp[1]}`}</MenuItem>
            </Select>
          </FormControl>
        </div> */}
        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={products || []}
            value={filterSelCode}
            getOptionLabel={(option: IListingProduct) => option.ext_prod_id || ''}
            style={{ width: 160 }}
            onChange={(e, value) => setFilterSelCode(value)}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="Seller Code" variant="outlined" />}
          />
        </div>

        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={products || []}
            value={filterPsn}
            getOptionLabel={(option: IListingProduct) => option.psn || ''}
            style={{ width: 160 }}
            onChange={(e, value) => setFilterPsn(value)}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="PSN" variant="outlined" />}
          />
        </div>
        <div className="filter-item">
          <ActionButton
            label="Apply"
            onClick={() => {
              handleProductFilter()
            }}
          />
          <ActionButton
            label="Clear"
            onClick={() => {
              getSellerProducts('1')
              setFilterSku(null)
              setFilterBrand(null)
              setFilterErpCode(null)
              setFilterName(null)
              setFilterSelCode(null)
              setFilterPsn(null)
              setFilterMrp([0, maxMrp])
              setFilterPrice([0, maxPrice])
            }}
          />
        </div>
      </th>
      <table className="inventory-products-table">
        <thead>
          <tr>
            <th>
              Product
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'name' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('name', sortingType)}
              />
            </th>
            <th>
              Category
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'category' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('category', sortingType)}
              />
            </th>
            <th>
              Brand
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'brand' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('brand', sortingType)}
              />
            </th>
            <th>
              Global SKU
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'sku' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('sku', sortingType)}
              />
            </th>
            <th>
              Poorvika Product Code
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'erp_item_code' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('erp_item_code', sortingType)}
              />
            </th>
            <th>
              Seller Product Code
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'ext_prod_id' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('ext_prod_id', sortingType)}
              />
            </th>
            <th>
              PSN
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'psn' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('psn', sortingType)}
              />
            </th>
            <th>
              WholeSale Price
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'wholesale_price' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('wholesale_price', sortingType)}
              />
            </th>

            <th>
              MRP
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'mrp' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('mrp', sortingType)}
              />
            </th>

            <th style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flex: 9 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <span> Status </span>
                  <FontAwesomeIcon
                    className="sort-icon"
                    icon={sortColumn !== 'listing_status' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                    onClick={() => sortingFunctionalityHandler('listing_status', sortingType)}
                  />
                </div>
              </div>
            </th>
            <th>Action</th>
          </tr>
        </thead>
        {product ? (
          <tbody>
            {product.map((item) => {
              return (
                <tr key={item.erp_item_code}>
                  {/* <td>
                                                    <input type="checkbox" />
            
                                                </td> */}
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSelectedItem(item)
                      setDisplayModal(!displayModal)
                    }}
                  >
                    <div className="product-col">
                      <div className="pimg-wrap">
                        <img className="product-img" alt="product-img" src={item.image} />
                      </div>
                      <div className="pcontent-wrap">{item.name}</div>
                    </div>
                  </td>
                  <td>{item.category}</td>
                  <td>{item.brand}</td>
                  <td>{item.sku}</td>
                  <td>{item.erp_item_code}</td>
                  <td>{item.ext_prod_id}</td>
                  <td>{item.psn}</td>

                  <td>{item.wholesale_price}</td>
                  <td>{item.mrp}</td>
                  <td className="product-status-dropdown">
                    <ListingStatus
                      status={item.listing_status}
                      pim_code={item.pim_code}
                      erp_code ={item.erp_item_code}
                      statusProductHandler={statusProductHandler}
                    />
                  </td>
                  <td>
                    {item.listing_status!=="Blocked" && <Tooltip title="Edit">
                      <EditIcon className="edit-product" onClick={() => productEditConfirmationHandler(item)} />
                    </Tooltip>}
                  </td>
                </tr>
              )
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td className="empty-data" colSpan={12}>
                No data to display
              </td>
            </tr>
          </tbody>
        )}
      </table>
      <div className="all-products-pagination">
        <Pagination
          className="all-products-pagination"
          count={pageCount}
          shape="rounded"
          variant="outlined"
          onChange={handleChange}
        />
      </div>
      <ProductDetailsSlider
        productDetails={selectedItem}
        displayModal={displayModal}
        handleClose={() => setDisplayModal(!displayModal)}
      />
      <Dialog open={showEditConfirmation}>
        <div className="edit-price-confirmation-dialog">
          <h1 className="dialog-heading">Edit Listed Product</h1>
          <div className="warning-message">
            <p>
              <strong>Warning: </strong> Editing listed product information will cause below effects
            </p>
          </div>
          <ul>
            <li>
              Product will be submitted for the <strong>re-approval</strong>
            </li>
            <li>
              Available stock count will be temporarily changed to <strong>Zero</strong>{' '}
            </li>
          </ul>
          <div className="flex flex-center">
            <div className="flex-50">
              <ActionButton onClick={() => setShowEditConfirmation(false)} label="Cancel" varient="outlined" />
            </div>
            <div className="flex-50">
              <ActionButton label="Proceed" onClick={editProductConfirmationHandler} />
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog className="add-products-dialog" open={showProductEditDialog}>
        <form onSubmit={handleSubmit(editProductPriceHandler)}>
          <div className="dialog-header edit-price-confirmation-dialog">
            <div className="product-code-wrap">
              <span className="product-code-header">Product code: </span>
              {editableProduct?.erp_item_code}
            </div>
            <h1 className="dialog-heading">Do you want to Edit this Product details?</h1>
          </div>
          <div className="dialog-content-wrap">
            <div className="left-content-dialog">
              <div className="pimg-wrap">
                <img alt={editableProduct?.name} className="product-img" src={editableProduct?.image} />
              </div>
              <div className="pcontent-wrap">{editableProduct?.name}</div>
            </div>
            <div>
              <InputField
                inputRef={register({
                  required: 'Please enter Reason'
                })}
                label="Seller Product Unique Code"
                name="ext_product_id"
                defaultValue={editableProduct?.ext_prod_id}
                style={{ margin: '5px' }}
                variant="outlined"
              />

              <InputField
                inputRef={register({
                  required: 'Please enter wholesale_price'
                })}
                defaultValue={editableProduct?.wholesale_price}
                label="WholeSale Price"
                name="wholesale_price"
                style={{ margin: '5px' }}
                variant="outlined"
              />

              <InputField
                inputRef={register({
                  required: 'Please enter MRP'
                })}
                label="MRP Price"
                name="mrp"
                defaultValue={editableProduct?.mrp}
                style={{ margin: '5px' }}
                variant="outlined"
              />

              <DialogActions className="dialog-action-btn-wrap" style={{ margin: '0 auto' }}>
                <ActionButton
                  label="Cancel"
                  type="button"
                  varient="outlined"
                  onClick={() => setShowProductEditDialog(false)}
                />

                <ActionButton label="Modify" type="submit" />
              </DialogActions>
            </div>
          </div>
        </form>
      </Dialog>
    </div>
  )
}
export default ListedTable
