import React, { useEffect, useState } from 'react'
import AppHeaderLayout from 'components/AppHeaderLayout'
import NotificationItem from 'components/NotificationItem'
import ReplayIcon from '@mui/icons-material/Replay';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import './styles.scss'
import { NotificationService } from 'services/notification.service';
import { showNotification, STATUS } from 'common/constant';
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface';
import { INotification } from 'services/interfaces/notification.interface';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Grid } from '@material-ui/core';

const NotificationPage: React.FC = () => {

  const [notificationList, setNotificationList] = useState([])
  const notificationService = new NotificationService();
  const [linkHeader, setLinkHeader] = useState<string>("All");

  const breadcrumbs = [
    <div className='bread-crumb-navlink' key="1" >Notifications</div>,
    <div className='bread-crumb-navlink' key="3" > {linkHeader} </div>
];

  const role: string | null = localStorage.getItem('role');
  const isRootAdmin: boolean = (role === "ROOT_ADMIN" || role === "PMPL_CATALOGUE_MANAGER" || role === "PMPL_OPERATIONS_MANAGER" || role === "PMPL_FINANCE_MANAGER")




  const markAllNotifications = async (): Promise<void> => {

    try {

      const notificationReadStatus: ISuccessResponse | IErrorResponse = await notificationService.updateStatus('all', localStorage.getItem("userMail") as string);

      if (notificationReadStatus.status === STATUS.SUCCESS) {
        getNotificationList('all');
        showNotification(STATUS.SUCCESS, "All Notification marked read")
      } else {
        showNotification(STATUS.FAILURE, "Unable to mark all notifications as read")
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, "Unable to mark all notifications as read")
    }
  }


  const getNotificationList = async (status: string, notificationList?: string): Promise<void> => {
    try {
      
  
    const notificationInfo: ISuccessResponse | IErrorResponse = await notificationService.getNotifications(status, notificationList);

    if (notificationInfo.status === STATUS.SUCCESS) {
      const { data } = notificationInfo as ISuccessResponse;
      setNotificationList(data);
    } else {
      showNotification(STATUS.FAILURE, "Unable to get notification list")

    }

  } catch (error) {
    showNotification(STATUS.FAILURE, "Unable to get notification list")
  }

  }
  
  const Notificationfilter = (status: string | null, notificationType: string | null) => {
    getNotificationList(status as string, notificationType as string);
  }
  

  useEffect(() => {
    getNotificationList('all');
  }, [])

  return (
    <div className="notifications-page">
      <AppHeaderLayout title="NOTIFICATIONS" />
      <Grid item md={11} xs={12} className='bread-crumb-layout'>
                <Breadcrumbs className="mt-2" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" >
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
      <div className="notifications-page-wrapper">
        <div className="filter-wrapper">
          <div className="filter-card">

            <div className="title-and-reset">
              <p>FILTER</p>
              <p><span><ReplayIcon /></span> Reset</p>
            </div>

            {/* Need to convert as seperate component */}
            {/* <div className="filter-sub-section">
              <h1>By Priority</h1>
              <CheckboxOption value="Show Critical" />
            </div> */}

            <div className="filter-sub-section">
              <h1>Notification Status</h1>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="All"
                name="radio-buttons-group"
              >
                <FormControlLabel value="All" control={<Radio onChange={() => Notificationfilter('all', null)}  onClick={() => setLinkHeader("All")} />} label="All"  />
                <FormControlLabel value="Read" control={<Radio onChange={() => Notificationfilter("true", null)}  onClick={() => setLinkHeader("Read")} />} label="Read" />
                <FormControlLabel value="Unread" control={<Radio onChange={() => Notificationfilter("false", null)}  onClick={() => setLinkHeader("Unread")}/>} label="Unread" />
                <h1>Notification Type</h1>
                {isRootAdmin ?

                  <FormControlLabel value="Registration" control={<Radio onChange={() => Notificationfilter(null, "REGISTRATION")}   onClick={() => setLinkHeader("Registration")}/>} label="Registration" />
                  : <></>
                }
                 <FormControlLabel value="Listing" control={<Radio onChange={() => Notificationfilter(null,"LISTING")}   onClick={() => setLinkHeader("Listing")}/>} label="Listing" /> 
                 <FormControlLabel value="Purchase Order" control={<Radio onChange={() => Notificationfilter(null,"PURCHASE ORDER") }   onClick={() => setLinkHeader("Purchase Order")}/>} label="Purchase Order" />
              </RadioGroup>
            </div>

            {/* <div className="filter-sub-section">
              <h1>By Date</h1>
              <div className="date-range-filter">
                <input type="text" placeholder="MM / DD / YYYY - MM / DD / YYYY " />
              </div>

            </div> */}

          </div>
        </div>

        <div className="notification-wrapper">
          <div className="notification-menu">
            <div className="header-and-actions">
              <div className="notification-header">
                <h1>Recent Notifications</h1>
              </div>
              <div className="action">
                {notificationList ? <p onClick={() => markAllNotifications()}>Mark all as Read</p> : <></>}
              </div>
            </div>

            <div className="notification-list notification-items">
              {notificationList && notificationList?.length > 0 ? notificationList?.map((NotificationData: INotification) => <NotificationItem NotificationData={NotificationData} />) :
                <p className="empty-status">No Notifications Found</p>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotificationPage
