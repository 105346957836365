import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import './styles.scss'
import InputLabelwithDowninput from 'components/InputLabelwithDownInputField'
import { OnboardingService } from 'services/onBoarding.service'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
import { STATUS, showNotification, validatePermission } from 'common/constant'
import ActionButton from 'components/ActionButton'
import CardHeader from 'components/CardHeader'
import { RootStore, store } from 'redux/store'
import { CONTACT_INFORMATION } from 'redux/actions/profile.action'
import { MODIFY_CONTACT } from 'common/permissionMatrix'
import AdditionalContacts from 'containers/AdditionalContacts'

type IContactInformations = {
  title: string
  designation: string
  name: string
  office: string
  mobile: string
  email: string
  esclation_title: string
  esclation_name: string
  esclation_mobile: string
  esclation_email: string
}

const formValidations = {

  name: {
    required: { value: true, message: " Primary Contact can't be Empty" }
},
esclation_name: {
  required: { value: true, message: " Escalations Contact can't be Empty" }
},
  email: {
    required: { value: true, message: " Email can't be Empty" },
    pattern: { value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: ' Email format is invalid' }
},
esclation_email: {
  required: { value: true, message: " Escalations Email can't be Empty" },
  pattern: { value:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: ' Escalations Email format is invalid' }
},
  mobile: {
    required: { value: true, message: "Primary Contact Number can't be Empty" },
    minLength: { value: 10, message: ' Primary Contact Number must be at least 10 digits' },
    maxLength: { value: 10, message: "Primary Contact Number can't be more than 10 digits" },
    pattern: { value: /^[0-9\b]+$/i, message: ' Primary Contact Number format is invalid' }

  },
  esclation_mobile: {
    required: { value: true, message: " Escalations Contact Number can't be Empty" },
    minLength: { value: 10, message: ' Escalations Contact Number must be at least 10 digits' },
    maxLength: { value: 10, message: "Escalations Contact Number can't be more than 10 digits" },
    pattern: { value: /^[0-9\b]+$/i, message: ' Escalations Contact Number format is invalid' }

  }
}
const ContactsPage: React.FC = () => {
  /* Form hook */
  const { register, setValue, errors, handleSubmit } = useForm<IContactInformations>({ mode: 'onChange' })
  

  /* Services */
  const onboardingService = new OnboardingService();
  

  const contactInformationEditMode = useSelector((store: RootStore) => store.profile.contactDetails.edit)
  const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission)
  

  const onSubmit = async (formData: IContactInformations) => {
    try {
      const addPrimaryContactResponse: ISuccessResponse | IErrorResponse = await onboardingService.saveOnBoardingData({
        ...formData,
        step: 'PRIMARY_CONTACT'
      })
// console.log("addPrimaryContactResponse",addPrimaryContactResponse)

      if ((addPrimaryContactResponse.status === STATUS.SUCCESS)) {
        showNotification(STATUS.SUCCESS,  'Contact Informations updated successfully')
        store.dispatch({ type: CONTACT_INFORMATION, payload: { contactDetails: { edit: false } } })
      } else {
        showNotification(STATUS.FAILURE, 'Unable to update Contact Informations')
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to update Contact Informations')
    }
  }

  useEffect(() => {
    const role: string | null = localStorage.getItem('role')
    const isAllowed: boolean =
      role === 'ROOT_ADMIN' ||
      role === 'PMPL_CATALOGUE_MANAGER' ||
      role === 'PMPL_OPERATIONS_MANAGER' ||
      role === 'PMPL_FINANCE_MANAGER'

    const sellerMail: string = localStorage.getItem('sellerMail') as string
    const getContactInformations = async () => {
      try {
        const contactInformations: ISuccessResponse | IErrorResponse = await onboardingService.getOnboardingData(
          3,
          isAllowed ? (sellerMail as string) : ''
        )

        if (contactInformations.status === STATUS.SUCCESS) {
          const successResponse = contactInformations as ISuccessResponse

          setValue('title', successResponse.data.title)
          setValue('designation', successResponse.data.designation)
          setValue('name', successResponse.data.name)
          setValue('office', successResponse.data.office)
          setValue('mobile', successResponse.data.mobile)
          setValue('email', successResponse.data.email)
          setValue('esclation_name', successResponse.data.esclation_name)
          setValue('esclation_mobile', successResponse.data.esclation_mobile)
          setValue('esclation_email', successResponse.data.esclation_email)
        } else {
          showNotification(STATUS.FAILURE, 'Unable to get Contact informations')
        }
      } catch (error) {
        showNotification(STATUS.FAILURE, 'Unable to get Contact informations')
      }
    }

    getContactInformations();
  }, [])

  const hasLegalEditPermission = (): boolean => {
    const permission = validatePermission(MODIFY_CONTACT, userPermission);

    return permission;
};


  return (
    <div className="Profile-contacts-page">
      <form className="legal-layout-wrapper" onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title="Contacts" edit={hasLegalEditPermission() && !contactInformationEditMode} action={CONTACT_INFORMATION} />
        <div>
          <div className="legal-details-content-top">
            <InputLabelwithDowninput
              label="Primary Contact Person *"
              error={errors.name?.type !== undefined}
              errorText={errors.name ? errors.name.message : ''}
              register={register(formValidations.name)}
              disabled={!contactInformationEditMode}
              name="name"
            />
            <InputLabelwithDowninput
              label="Mail id *"
              error={errors.email?.type !== undefined}
              errorText={errors.email ? errors.email.message : ''}
              register={register(formValidations.email)}
              disabled={!contactInformationEditMode}
              name="email"
            />
            <InputLabelwithDowninput
              label="Contact Number *"
              error={errors.mobile?.type !== undefined}
              onChange={(value)=> 
                {
                value = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').substring(0, 10)
                setValue('mobile', value)}}
              errorText={errors.mobile ? errors.mobile.message : ''}
              disabled={!contactInformationEditMode}
              // register={register}
              register={register(formValidations.mobile)}
              name="mobile"
            />
          </div>
          <div className="legal-details-content">
            <InputLabelwithDowninput
              label="Escalations Contact Person *"
              disabled={!contactInformationEditMode}
              error={errors.esclation_name?.type !== undefined}
              errorText={errors.esclation_name ? errors.esclation_name.message : ''}
              register={register(formValidations.esclation_name)}
              name="esclation_name"
            />
            <InputLabelwithDowninput
              label="Mail id *"
              // register={register}
              error={errors.esclation_email?.type !== undefined}
              errorText={errors.esclation_email ? errors.esclation_email.message : ''}
              register={register(formValidations.esclation_email)}
              disabled={!contactInformationEditMode}
              name="esclation_email"
            />
            <InputLabelwithDowninput
              label="Contact Number *"
              error={errors.esclation_mobile?.type !== undefined}
              onChange={(value)=> 
                {
                value = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').substring(0, 10)
                setValue('esclation_mobile', value)}}
              errorText={errors.esclation_mobile ? errors.esclation_mobile.message : ''}
              // disabled={!contactInformationEditMode}
              // register={register}
              register={register(formValidations.esclation_mobile)}
              // register={register}
              disabled={!contactInformationEditMode}
              name="esclation_mobile"
            />
            <div className="form-data-hide">
              <InputLabelwithDowninput
                label="Designation"
                disabled={!contactInformationEditMode}
                name="designation"
                register={register}
              />
              <select className="input-selector" name="esclation_title" ref={register}>
                <option value="MR">
                  Mr
                </option>
                <option value="MRS">Mrs</option>
                <option value="MS">Ms</option>
              </select>
              <select className="input-selector" name="title" ref={register}>
                <option value="MR">
                  Mr
                </option>
                <option value="MRS">Mrs</option>
                <option value="MS">Ms</option>
              </select>
              <InputLabelwithDowninput label="Office Phone" name="office" register={register} />
            </div>
            <div className="save-btn-wrap">
              {contactInformationEditMode ? <ActionButton label="Save" type="submit" /> : <></>}
            </div>
          </div>
        </div>
      </form>
      <AdditionalContacts/>
    </div>
  )
}

export default ContactsPage
