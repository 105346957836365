import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import './styles.scss'
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import LayoutHeader from '../../../components/LayoutHeader'
import { isVisible, showNotification, STATUS } from '../../../common/constant'
import { InventoryService } from '../../../services/inventory.service'
import ActionButton from '../../../components/ActionButton'
import Verification from '../../../assets/verification.png'
import { RootStore } from '../../../redux/store'
import { IErrorResponse, ISuccessResponse, ISuccessResponses } from '../../../services/interfaces/common.interface';
import { editLocation } from 'redux/actions/inventory.action';
import useAdminEvaluation from 'hooks/useAdminEvaluation';
import InputField from 'components/InputField/InputField';
import { useHistory, useLocation } from 'react-router-dom'

type PendingLocationsprops = {
    onStatus: (data) => void;
}
type IPendingLocationForm = {
    ext_warehouse_code: string
}

const UpdateExtCodeValidation = {
    ext_warehouse_code: { required: true,message: 'External warehouse code is empty' }
}


const PendingLocations: React.FC<PendingLocationsprops> = ({ onStatus }) => {
    const { register,handleSubmit,errors } = useForm<IPendingLocationForm>({ mode: 'onSubmit' })
    const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission);
    const dispatch = useDispatch()
    const history = useHistory<History>()
    /*State Variables */
    const [warehouselist, setWarehouselist] = useState<any>([])
    const [deleteDialog, setDeleteDialog] = React.useState<boolean>(false)
    const [approveWarehouseDialog,setApproveWarehouseDialog] = React.useState<boolean>(false)
    const [deletedcode, setDeletedCode] = React.useState<string>()
    const {isAdmin }= useAdminEvaluation();
    const [approvalDataObj,setApprovalDataObj] = React.useState<any>({})
    const [updateExtCode,setUpdateExtCode] = React.useState<any>()
    /* Services */
    const inventoryService: InventoryService = new InventoryService()

    /*Get Active Warehouse List Handler */
    const getWareHouse = async () => {
        try {
            const SupplierCode = localStorage.getItem("supplier_apx_code")
            if(!!SupplierCode){
                const updateSellerStatus = {
                    "id":0,
                    "sellercode": SupplierCode,
                    "seller_status": "Pending"
                }
                const getWareHouseResponse: ISuccessResponse | IErrorResponse = await inventoryService.getWarehouseListByStatus(updateSellerStatus);
                if(getWareHouseResponse.status === STATUS.SUCCESS){
                    setWarehouselist(getWareHouseResponse.data.warehouselist)
                    onStatus(getWareHouseResponse.data.warehouselist ? getWareHouseResponse.data.warehouselist.length + ' ' + "Pending" : 0 + ' ' + "Pending")
                } else{
                    showNotification(STATUS.FAILURE, 'Error - Unable to warehouse list')
                }
            }
        } catch (error) {
            showNotification(STATUS.FAILURE, ' Unable to warehouse list')
        }
    }

    const deletedialog = (deletedcode) => {
        setDeletedCode(deletedcode)
        setDeleteDialog(true)
    }
    const openDialog = (warehouseData) => {
        setApprovalDataObj(warehouseData);
        !!warehouseData?.ext_warehouse_code ? setUpdateExtCode(warehouseData?.ext_warehouse_code) :  setUpdateExtCode('');
        setApproveWarehouseDialog(true);
    }

    const create_update_wh_in_oms = async (data, ext_warehouse_code) => {
        try {
          const warehouse_data = {
            apob_code: ext_warehouse_code,
            apob_name: data.name,
            virtual_branch_code: data.code,
            virtual_branch_name: data.name,
            address_line1: data.address1,
            address_line2: data.address2,
            city: data.city,
            state: data.state,
            contact_no: data.primary_contact_mobile,
            email: data.primary_contact_email,
            pincode: data.pincode
          }
          const addWarehouse: ISuccessResponse | IErrorResponse = await inventoryService.addupdatewarehouseInOMS(
            warehouse_data
          )
          if (addWarehouse.status === STATUS.SUCCESS) {
            showNotification(STATUS.SUCCESS, addWarehouse.message)
          } else {
            showNotification(STATUS.FAILURE, addWarehouse.message)
          }
        } catch (error) {
          showNotification(STATUS.FAILURE, 'Unable to update warehouse details')
        }
      }

    useEffect(()=>{
        dispatch(editLocation(false));
        getWareHouse();
    },[])

    const deletewarehouse = async () => {
        setDeleteDialog(false)
        try {
            const deActivateHouseResponse: ISuccessResponse | IErrorResponse = await inventoryService.active_deactive_warehouse(deletedcode, 1)
            const deActiveInOMS: ISuccessResponse | IErrorResponse = await inventoryService.updatewarehousestatusInOMS(deletedcode, false)
            showNotification(STATUS.SUCCESS, deActivateHouseResponse.message)
        } catch (error) {
            showNotification(STATUS.FAILURE, ' Unable to deactivate warehouse')
        }

        getWareHouse()
    }

    const updateExtCodeStatus = async () => {
        setApproveWarehouseDialog(false);
        try {
            const SupplierCode = localStorage.getItem("supplier_apx_code")
            if(!!SupplierCode){
                const updateSellerStatus =  {
                    "code": approvalDataObj["code"],
                    "name": approvalDataObj["name"],
                    "seller_code": SupplierCode,
                    "ext_warehouse_code": updateExtCode,
                    "inv_profile": approvalDataObj["inv_profile"],
                    "row_updated_by": approvalDataObj["row_updated_by"],
                    "seller_status": "Approval"
                }

                 const approvalResponse : ISuccessResponse | IErrorResponse = await inventoryService.postExtCodeApproveStatus(updateSellerStatus);
                //  console.log(approvalResponse)
                 if(approvalResponse.status === STATUS.SUCCESS) {
                    create_update_wh_in_oms(approvalDataObj, updateExtCode)
                    showNotification(STATUS.SUCCESS, approvalResponse.message)
                    history.push('/sellerLayout/inventory/ActiveLocations')
                 } else {
                    showNotification(STATUS.FAILURE, 'Error - Unable to approve warehouse.');
                 }
            }
        } catch (error) {
            showNotification(STATUS.FAILURE, 'Error - Unable to approve warehouse.')
        }

    }


    const handleOnEdit = () => {

        dispatch(editLocation(true))
    }
    return (
        <div className="Pendinglocations-page">
            {warehouselist ?
                <div>
                    {
                        warehouselist.map((warehouseData, idx) => {
                            return (
                                <div className="location-layout-wrapper" key={warehouseData.warehouse_code+"_"+idx}>
                                    <LayoutHeader title={warehouseData.name + ' ( ' + warehouseData.code + ')'} edit={isVisible(["WAREHOUSE_EDIT"],{user: userPermission} )}
                                    //remove={isVisible(["WAREHOUSE_DELETE"],{user: userPermission} )}
                                    to="/sellerLayout/inventory/AddLocations"
                                    state={warehouseData}
                                    //onClick={() => deletedialog(warehouseData)}
                                    editOnClick={handleOnEdit}/>
                                    <div className="activelocation-details-card">
                                        <div className="activelocation-details-content">
                                            <ImageOutlinedIcon className="warehouse-image-ic" />
                                            <div className="registered-address-content">
                                                <p className="warehouse-details-label">Registered Address</p>
                                                <p className="warehouse-details-content">{warehouseData.address1 + ',' + warehouseData.address2 + ','
                                                    + warehouseData.city + ',' + warehouseData.state + ',' + warehouseData.pincode}</p>
                                            </div>
                                            <div className="primary-contact-content">
                                                <p className="warehouse-details-label">Primary Contact :</p>
                                                <p className="warehouse-details-content">{warehouseData.primary_contact_name}</p>
                                                <p className="warehouse-details-content">{warehouseData.primary_contact_email}</p>
                                                <p className="warehouse-details-content">{warehouseData.primary_contact_mobile}</p>

                                            </div>

                                            <div className="primary-contact-content">
                                                <p className="warehouse-details-label">Escalations Contact :</p>
                                                <p className="warehouse-details-content">{warehouseData.escalations_contact_name}</p>
                                                <p className="warehouse-details-content">{warehouseData.escalations_contact_email}</p>
                                                <p className="warehouse-details-content">{warehouseData.escalations_contact_mobile}</p>

                                            </div>

                                        </div>

                                        { (isAdmin) ?
                                        <div>
                                        <div style={{display:'flex', justifyContent:'end'}}>
                                           <ActionButton label="Approve Warehouse" type="button" onClick={() => openDialog(warehouseData)} />
                                        </div>
                                        </div>
                                        :  <div>
                                        <div style={{display:'flex', justifyContent:'end'}}>
                                           <ActionButton label="Awaiting for Approval" type="button"/>
                                        </div>
                                        </div>}
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
                :
                <div className="location-layout-wrapper no-warehouse">
                    <div className="No-Active-card-wrapper">
                        <img src={Verification} alt="verification" className="verification-image" />

                        No Active Locations Found

                    </div>
                </div>
            }


            <Dialog open={deleteDialog} className="warehouse-delete-dialog">
                <DialogTitle className="delete-title" id="alert-dialog-title">
                Are you sure you want to deactivate this warehouse?
                </DialogTitle>

                <DialogActions style={{ margin: '0 auto' }}>
                    <ActionButton label="Cancel" varient="outlined" onClick={() => setDeleteDialog(false)} />

                    <ActionButton label="De-Activate" onClick={() => deletewarehouse()} />
                </DialogActions>
            </Dialog>

            <Dialog open={approveWarehouseDialog} className="warehouse-delete-dialog">
                <DialogTitle className="delete-title" id="alert-dialog-title">
                    Are you sure you want to approve this warehouse?
                </DialogTitle>
                <form onSubmit={handleSubmit(updateExtCodeStatus)}>
                <DialogContent>
                <InputField
                defaultValue={approvalDataObj.name}
                label="Warehouse Name"
                name="name"
                disabled />
                <InputField
                defaultValue={approvalDataObj.code}
                label="Warehouse Code"
                name="code"
                disabled
                />
                <InputField
                label="Ext-Warehouse Code"
                name="ext_warehouse_code"
                defaultValue={approvalDataObj.ext_warehouse_code}
                helperText={errors.ext_warehouse_code ? errors.ext_warehouse_code.message : ''}
                inputRef={register({
                    required: 'Please Enter Warehouse code'
                })}
                error
                onChange={(event) => setUpdateExtCode(event.target.value)}
                />
                <div className='infotext'>Note: Ext-Warehouse Code should get from Poorvika ERP system.</div>
                </DialogContent>
                <DialogActions style={{ margin: '0 auto' }}>
                  <ActionButton label="Cancel" varient="outlined" onClick={() => setApproveWarehouseDialog(false)}/>
                  <ActionButton label="Proceed" type="submit"/>
                </DialogActions>
                </form>

            </Dialog>

        </div>
    )
}

export default PendingLocations;