import React ,{useState, useEffect}from 'react'

import './styles.scss'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
import { OnPurchaseOrderService } from 'services/purchaseOrder.service'
import { InventoryService } from 'services/inventory.service'
type Props = {
   
  data: any,
  OrderQty?: number,
  setAccepted: (arg1:boolean) => void,
  branchcode: string
  
}

const Getstock: React.FC<Props> = ({data, setAccepted, OrderQty, branchcode}) => {
  const [purchaseOrderStock, setPurchaseOrderStock] = useState<number>()
  const getPurchaseOrderList = new OnPurchaseOrderService()
  const inventoryService = new InventoryService()
  useEffect(() => {
    
    const getReviewData = async () => {

      const SupplierCode = localStorage.getItem("supplier_apx_code");
      const data_object = {
        sellerCode: SupplierCode,
        poorivkaCode: data ?? '',
        profile: "seller",
        warehouseStatus: 1,
        inventoryStatus:1,
        branch_code: branchcode
      }
     
      // const PurchaseorderGetStock: ISuccessResponse | IErrorResponse = await getPurchaseOrderList.getPurchaseOrderGetStock(data)
        const PurchaseorderGetStock: ISuccessResponse | IErrorResponse = await inventoryService.multipleSearchProductInventory(data_object)
        let stock = 0;
              const getdata = PurchaseorderGetStock as ISuccessResponse;
              if(!!getdata.data && getdata.data.length >0){
                stock = getdata.data[0]["profile_stock_inhand"]
              } else{
                // stock = 10
              }
              // stock = getdata.total_stock || 0;
              const qty =  OrderQty || 0

              if(stock < qty){
                setAccepted(false)
              }


            setPurchaseOrderStock(stock);
            
             
    }

    getReviewData();

}, [])
 
  

    return (
            

     <div className="Getstock">{purchaseOrderStock}</div> 
        
    
      )
}

export default Getstock
