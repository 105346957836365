import React, { useEffect, useState, useRef } from 'react'
import { NavLink, Route } from 'react-router-dom'
import { CSVLink } from 'react-csv'
import { CloudUpload } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { Breadcrumbs, Dialog, Grid } from '@material-ui/core'
import ActionButton from 'components/ActionButton'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import AppHeaderLayout from '../../../components/AppHeaderLayout'
import { ProductService } from '../../../services/productService'
import { InventoryService } from '../../../services/inventory.service'
import { isVisible, LIST_OF_ROLES, showNotification, STATUS } from '../../../common/constant'
import ResultSummaryDialog from '../../../components/ResultSummaryDialog/Index'
import { RootStore } from '../../../redux/store'
import { IErrorResponse, ISuccessResponse} from '../../../services/interfaces/common.interface'
import { IProductInventory } from '../../../services/interfaces/inventory.interface'

import ALLInventoryTable from './AllInventoryProducts'
import ActiveInventoryTable from './ActiveInventoryProducts'
import InActiveInventoryTable from './InactiveInventoryProducts'

import './styles.scss'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const InventoryManagement: React.FC = () => {
  const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission)

  const productService: ProductService = new ProductService()
  const inventoryService: InventoryService = new InventoryService()
  // const getProductCategories = () => productService.getProductCategories()
  const [csvData, setCsvData] = useState([])
  const [isUpdated, setUpdated] = useState(false)
  const [open, setOpen] = useState(false)
  const [summaryResult, setSummaryResult] = useState([])
  const [all_inventory, setAllInventory] = useState<IProductInventory[]>([])
  const [bulkUploadDialog, setsBulkUploadDialog] = useState<boolean>(false)
  const [uploadStatus, setUploadStatus] = useState<any>()
  const [linkHeader, setLinkHeader] = useState<string>('All')

  const inputEl = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null)

  const InventoryStatus = [
    {
      name: 'All',
      path: '/sellerLayout/InventoryManagement/AllInventory'
    },
    {
      name: 'Active',
      path: '/sellerLayout/InventoryManagement/ActiveInventory'
    },
    {
      name: 'InActive',
      path: '/sellerLayout/InventoryManagement/InActiveInventory'
    }
  ]

  const breadcrumbs = [
    <div className="bread-crumb-navlink" key="1">
      Location Details
    </div>,
    <div className="bread-crumb-navlink" key="2">
      Inventory Management
    </div>,
    <div className="bread-crumb-navlink" key="3">
      {' '}
      {linkHeader}{' '}
    </div>
  ]

  // const getInventorylist = async (pageNo) => {
  //   try {
  //     const getInventorylistResponse: ISuccessResponse | IErrorResponse = await inventoryService.getInventorylist(
  //       1,
  //       pageNo
  //     )
  //     console.log(getInventorylistResponse)
  //   } catch (error) {
  //     showNotification(STATUS.FAILURE, 'Unable to get Product informations')
  //   }
  // }

  const getAllInventory = async () => {
    try {
      const SupplierCode = localStorage.getItem("supplier_apx_code");
      if(!!SupplierCode){
        const get_all_inventory: any = await inventoryService.downloadInventoryDocument({sellerCode:SupplierCode,  "warehouseStatus": 1})
        if (get_all_inventory.status === STATUS.SUCCESS)  {
          setAllInventory(get_all_inventory?.data)
        } else {
          showNotification(STATUS.FAILURE, 'Unable to get Product informations')
        }
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Product informations')
    }
  }
  useEffect(() => {
    getAllInventory()
    // getInventorylist(1)
  }, [])

  // useEffect(() => {
  //   setSupplierCode(localStorage.getItem(""))
  // }, [])

  /*This donwload is available for the only  active products */
  const handleDownload = async () => {
    try {
      const SupplierCode = localStorage.getItem("supplier_apx_code");
      const downloadResult: ISuccessResponse | IErrorResponse = await inventoryService.downloadInventoryDocument({sellerCode:SupplierCode,inventoryStatus: 1})
      if (downloadResult?.data?.length !== 0) {
        const data = downloadResult.data
          .map((item) => {
            const product = {}

            product['Seller Code'] = SupplierCode
            product['Product'] = item.product_name.replace(",", " ", "g")
            product['Category'] = item.category
            product['Brand'] = item.brand
            product['Global SKU'] = item.sku
            product['Poorvika Product Code'] = item.erp_prod_id
            product['Seller Product code'] = item.ext_prod_id
            product['PSN'] = item.psn

            product['Stock in Hand'] = item.profile_stock_inhand
            product['Warehouse Code'] = item.branch_code
            product['Warehouse Name'] = item.name
            product['Status'] = !!item.isActive ? 'Active' : 'InActive'

            return product
          })

          // .filter((p) => p['isActive'] === 1)
        setCsvData(data)
      } else showNotification(STATUS.FAILURE, 'No data to download')

      inputEl.current.link.click()
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to download the document')
    }
  }

  const handleSummary = async () => {
    try {
      const summaryResult: any = await inventoryService.getUploadHistory()
      setSummaryResult(summaryResult)
      setOpen(true)
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get the History')
    }
  }

  const onFileChange = async (event) => {
    const files = event.target.files[0]

    if (files) {
      setUpdated(false)

      const uploadfile = new FormData()
      uploadfile.append('uploadfile', files, files.name)

      try {
        const uploadResult: any = await inventoryService.uploadDocument(uploadfile)

        if (uploadResult.status === STATUS.FAILURE) {
          showNotification(STATUS.FAILURE, 'Uploaded File is Invalid')
        } else {
          setUploadStatus(uploadResult[uploadResult?.length - 1])
          setUpdated(true)
          setsBulkUploadDialog(true)
        }
      } catch (error) {
        showNotification(STATUS.FAILURE, 'Unable to upload the document')
      }
    }
  }

  const getStatusCount = (status) => {
    if (status === 'All') {
      return all_inventory.length
    } else if (status === 'Active') {
      return all_inventory.filter((item) => item.isActive === 1).length
    } else if (status === 'InActive') {
      return all_inventory.filter((item) => item.isActive === 0).length
    }

    return 0
  }

  return (
    <div className="location-inventory-page">
      <AppHeaderLayout title="Inventory Management" to="/sellerLayout/inventory/ActiveLocations" />
      <Grid item md={11} xs={12} className="bread-crumb-layout">
        <Breadcrumbs className="mt-2" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Grid>
      <div className="inventory-management-page">
        <div className="inventory-table-title">
          {isVisible([LIST_OF_ROLES.EXPORT_INVENTORY], { user: userPermission }) ? (
            <>
              <p className="bulk-action-text">Bulk Actions: </p>
              <button className="addproduct-btn-wrapper " onClick={() => handleDownload()}>
                <FontAwesomeIcon className="excel-icon" icon={faFileExcel as IconProp} />
                <span className="btn-text">Export Product List</span>
              </button>
            </>
          ) : (
            <></>
          )}
          <CSVLink
            filename="inventory_template.csv"
            data={csvData}
            asyncOnClick
            onClick={(event, done) => {
              done()
            }}
            style={{ display: 'none' }}
            ref={inputEl}
          />
          {isVisible([LIST_OF_ROLES.BULK_UPLOAD_STOCK], { user: userPermission }) ? (
            <div>
              <label className="custom-file-upload">
                <input
                  type="file"
                  multiple={false}
                  onChange={onFileChange}
                  onClick={(event: any) => {
                    event.target.value = null
                  }}
                />
                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <CloudUpload className="upload-icon" />
                  <span style={{ marginRight: '1rem' }}>Upload Products</span>
                </span>
              </label>
              <span
                style={{
                  color: 'red',
                  textDecorationLine: 'underline',
                  marginRight: '1rem',
                  cursor: 'pointer',
                  paddingLeft: '10px'
                }}
                onClick={() => handleSummary()}
              >
                Show History
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="Inventory-table-layout">
          <div className="Inventory-table-header">
            {InventoryStatus.map((status) => (
              <div className="listed-products-selector" key={status.name}>
                <NavLink key={status.name} className="inventorys-selector" to={status.path}>
                  <p onClick={() => setLinkHeader(status?.name)}>{`${status.name} (${getStatusCount(status.name)})`}</p>
                </NavLink>
                <span>|</span>
              </div>
            ))}
          </div>

          <Route
            render={() => <ALLInventoryTable inventory={all_inventory} isUpdated={isUpdated} />}
            path="/sellerLayout/InventoryManagement/AllInventory"
          />
          <Route
            render={() => <ALLInventoryTable inventory={all_inventory} isUpdated={isUpdated}/>}
            path="/admin/seller/sellerLayout/InventoryManagement/AllInventory"
          />

          <Route
            render={() => (
              <ActiveInventoryTable
                inventory={all_inventory?.filter((item: IProductInventory) => item.isActive === 1)}
                isUpdated={isUpdated}
               />
            )}
            path="/sellerLayout/InventoryManagement/ActiveInventory"
          />
          <Route
            render={() => (
              <ActiveInventoryTable
                inventory={all_inventory?.filter((item: IProductInventory) => item.isActive === 1)}
                isUpdated={isUpdated}
               />
            )}
            path="/admin/seller/sellerLayout/InventoryManagement/ActiveInventory"
          />

          <Route
            render={() => (
              <InActiveInventoryTable
                inventory={all_inventory?.filter((item: IProductInventory) => item.isActive ===  0)}
                isUpdated={isUpdated}
               />
            )}
            path="/sellerLayout/InventoryManagement/InActiveInventory"
          />
          <Route
            render={() => (
              <InActiveInventoryTable
                inventory={all_inventory?.filter((item: IProductInventory) => item.isActive === 0)}
                isUpdated={isUpdated}
               />
            )}
            path="/admin/seller/sellerLayout/InventoryManagement/InActiveInventory"
          />
        </div>
      </div>
      <Dialog open={bulkUploadDialog} className="bulk-upload-result-dialog">
        <div className="bulk-upload-result">
          <h1 className="bulk-upload-heading">Product - Bulk Upload Result</h1>
          <div className="upload-result">
            <p className="successfull-items"> {uploadStatus?.success} products successfully added.</p>
            <p className="failed-items"> {uploadStatus?.failed} products failed to add.</p>
          </div>
          <div className="bulk-upload-buttons-wrapper">
            <ActionButton onClick={() => setsBulkUploadDialog(false)} label="Close" varient="outlined" />
            <ActionButton
              onClick={() => {
                setsBulkUploadDialog(false)
                handleSummary()
              }}
              label="Show Detailed History"
            />
          </div>
        </div>
      </Dialog>
      <ResultSummaryDialog data={summaryResult} open={open} setOpen={setOpen} />
    </div>
  )
}

export default InventoryManagement
