import React, { useEffect, useState } from 'react'
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import {  useSelector } from 'react-redux'
import { Dialog, DialogActions, DialogTitle } from '@material-ui/core'

import LayoutHeader from '../../../components/LayoutHeader'
import { isVisible, showNotification, STATUS } from '../../../common/constant'
import { InventoryService } from '../../../services/inventory.service'
import Verification from '../../../assets/verification.png'
import { ISuccessResponse, IErrorResponse } from '../../../services/interfaces/common.interface';
import { RootStore } from '../../../redux/store'
import ActionButton from '../../../components/ActionButton'



type ActiveLocationsprops = {

    onStatus: (data) => void;

}

const INActiveLocations: React.FC<ActiveLocationsprops> = ({ onStatus }) => {

    const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission);


    /*State Variables */
    const [warehouselist, setWarehouselist] = useState<any>([])

    const [activateDialog, setActivateDialog] = React.useState<boolean>(false)
    const [activateCode, setActivateCode] = React.useState<string>()

    /* Services */
    const inventoryService: InventoryService = new InventoryService()

    /*IN-Active GetWarehouse List Handler */
    const getWareHouse = async () => {
        try {
            const SupplierCode= localStorage.getItem("supplier_apx_code")
            if(!!SupplierCode){
                const updateSellerStatus = {
                    "id":0,
                    "sellercode": SupplierCode,
                    "seller_status": "Approval"
                }
                const getWareHouseResponse: ISuccessResponse | IErrorResponse = await inventoryService.getWarehouseListByStatus(updateSellerStatus);
                // const getWareHouseResponse: ISuccessResponse | IErrorResponse = await inventoryService.getwarehouselist(SupplierCode, 0)
                if(getWareHouseResponse.status === STATUS.SUCCESS){
                    setWarehouselist(getWareHouseResponse.data.warehouselist)
                    onStatus(getWareHouseResponse.data.warehouselist? getWareHouseResponse.data.warehouselist.length + ' ' + "In-Active": 0+ ' ' + "In-Active")
                } else{
                    showNotification(STATUS.FAILURE, 'Error - Unable to get warhouse ')
                }
            }

        } catch (error) {
            showNotification(STATUS.FAILURE, ' Unable to get warhouse ')
        }
    }

    useEffect(() => {
        getWareHouse();
    }, [])

    const activatedialog = (deletedcode) => {
        setActivateCode(deletedcode)
        setActivateDialog(true)
    }

    const reactivatewarehouse = async () => {
        setActivateDialog(false)

        try {
            // debugger;
            const activeareHouseResponse: ISuccessResponse | IErrorResponse = await inventoryService.active_deactive_warehouse(activateCode , 0)
            const activeInOMS: ISuccessResponse | IErrorResponse = await inventoryService.updatewarehousestatusInOMS(activateCode , true)

            if(activeareHouseResponse.status === STATUS.SUCCESS){
                showNotification(STATUS.SUCCESS, activeareHouseResponse.message);
                getWareHouse();
            } else{
                showNotification(STATUS.FAILURE, 'Error - Unable to activate warehouse')
            }
        } catch (error) {
            showNotification(STATUS.FAILURE, ' Unable to activate warehouse')
        }

       
    }

    return (
        <div className="Activelocations-page">
            {warehouselist ?
                <div>
                    {
                        warehouselist.map((warehouseData, idx) => {
                            return (
                                <div className="location-layout-wrapper" key={warehouseData.warehouse_code+"_"+idx}>
                                    <LayoutHeader title={warehouseData.name + ' ( ' + warehouseData.code + ')'}  activeflag={isVisible(["WAREHOUSE_EDIT"],{user: userPermission} )}  state={warehouseData} activateBackClick={() => activatedialog(warehouseData)}/>
                                    <div className="activelocation-details-card">
                                        <div className="activelocation-details-content">
                                            <ImageOutlinedIcon className="warehouse-image-ic" />
                                            <div className="registered-address-content">
                                                <p className="warehouse-details-label">Registered Address</p>
                                                <p className="warehouse-details-content">{warehouseData.address1 + ',' + warehouseData.address2 + ','
                                                    + warehouseData.city + ',' + warehouseData.state + ',' + warehouseData.pincode}</p>
                                            </div>
                                            <div className="primary-contact-content">
                                                <p className="warehouse-details-label">Primary Contact :</p>
                                                <p className="warehouse-details-content">{warehouseData.primary_contact_name}</p>
                                                <p className="warehouse-details-content">{warehouseData.primary_contact_email}</p>
                                                <p className="warehouse-details-content">{warehouseData.primary_contact_mobile}</p>

                                            </div>

                                            <div className="primary-contact-content">
                                                <p className="warehouse-details-label">Escalations Contact :</p>
                                                <p className="warehouse-details-content">{warehouseData.escalations_contact_name}</p>
                                                <p className="warehouse-details-content">{warehouseData.escalations_contact_email}</p>
                                                <p className="warehouse-details-content">{warehouseData.escalations_contact_mobile}</p>

                                            </div>

                                        </div>


                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
                : <div className="location-layout-wrapper">
                    <div className="No-Active-card-wrapper">
                    <img src={Verification} alt="verification" className="verification-image" />
                        No Inactive Locations Found

                    </div>
                </div>
            }


        <Dialog open={activateDialog} className="warehouse-delete-dialog">
                <DialogTitle className="delete-title" id="alert-dialog-title">
                    Are you sure you want to reactivate this warehouse?
                </DialogTitle>

                <DialogActions style={{ margin: '0 auto' }}>
                    <ActionButton label="Cancel" varient="outlined" onClick={() => setActivateDialog(false)} />

                    <ActionButton label="Activate" onClick={() => reactivatewarehouse()} />
                </DialogActions>
            </Dialog>



        </div>
    )
}

export default INActiveLocations