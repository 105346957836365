import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import InputLabelwithDowninput from 'components/InputLabelwithDownInputField'
import { OnboardingService } from 'services/onBoarding.service'
import { STATUS, showNotification, validatePermission, LIST_OF_ROLES } from 'common/constant'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
import { decrypt, encrypt } from 'common/encryptor'
import ActionButton from 'components/ActionButton'
import { RootStore, store } from 'redux/store'
import { BANK_AND_FINANCIAL } from 'redux/actions/profile.action'
import CardHeader from 'components/CardHeader'
import { AccountsService } from 'services/accounts.service'
import BankVerificationStatus from 'components/BankVerificationStatus'
import useAdminEvaluation from 'hooks/useAdminEvaluation'


import './styles.scss'



type TBillingInformations = {
    bankName: string
    accountNumber: string
    ifsc: string
    branch: string
    bankAddress: string
    city: string
    state: string
    address1: string
    address2: string
    address3: string
    confirmAccountNumber: string,
    pin: string
    accountHolderName : string 
    verifiationStatus:boolean
}
const formValidations = {

    bankName: {
      required: { value: true, message: " Bank Name can't be Empty" }
  },
  accountNumber: {
    required: { value: true, message: " Account Number can't be Empty" }
  },
  ifsc: {
      required: { value: true, message: " IFSC can't be Empty" },
  },
  branch: {
    required: { value: true, message: " Branch can't be Empty" },
  }
  }
const BankAccountPage: React.FC = () => {
    const { isBusinessUser } = useAdminEvaluation();
    const onboardingService = new OnboardingService();
    const accountsService = new AccountsService();
    const { setValue, register, errors, handleSubmit, watch } = useForm<TBillingInformations>({ mode: 'onChange' })
    const bankAndFinancialEditMode = useSelector((store: RootStore) => store.profile.bankAccountDetails.edit);
    const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission);

    /* Getting initial information */
    const getBillingInformation = async () => {
        try {
            const sellerMail: string = localStorage.getItem('sellerMail') as string;
            const personalData: ISuccessResponse | IErrorResponse = await onboardingService.getOnboardingData(2, isBusinessUser ? sellerMail as string : "")

            if (personalData.status === STATUS.SUCCESS) {
                const successData = personalData as ISuccessResponse;

                setValue("city", successData.data.city);
                setValue("pin", successData.data.pincode);
                setValue("state", successData.data.state);
                setValue("bankName", successData.data.bankName);
                setValue("address1", successData.data.address1);
                setValue("address2", successData.data.address2); 
                setValue("branch", successData.data.bankAddress)
                setValue("accountHolderName", successData.data.accountHolderName);
                setValue("accountNumber", successData.data.accountNumber ? decrypt(successData.data.accountNumber) : "");
                setValue("confirmAccountNumber", successData.data.accountNumber ? decrypt(successData.data.accountNumber) : "");

                setValue("ifsc", successData.data.ifsc);

            }
        } catch (error) {
            showNotification(STATUS.FAILURE, 'Unable to get Billing informations')
        }
    }


    const onSubmit = async (data: TBillingInformations) => {
        data.bankName = watch('bankName')
        data.branch = watch('branch')

        try {
        /* Encrpting the account number */
        if(!data.accountNumber.length ) {
            showNotification(STATUS.FAILURE, 'Invalid Account Number ')

            return
        }

        const encryptedAccountNumber = encrypt(data.accountNumber);
        data.accountNumber = encryptedAccountNumber;
        data.confirmAccountNumber = encryptedAccountNumber;

        if( data.ifsc.length !== 11) {
            showNotification(STATUS.FAILURE, 'Invalid IFSC data')

            return
        }
       
        const saveResponse: ISuccessResponse | IErrorResponse = await accountsService.updateProfile(data, "BANK_ACCOUNT");

        if(saveResponse.status === STATUS.SUCCESS){
            showNotification(STATUS.SUCCESS, 'Bank Account Details updated successfully')
            store.dispatch({type: BANK_AND_FINANCIAL, payload: {bankAccountDetails: {edit: false}}});
            getBillingInformation();
        }      else {
            showNotification(STATUS.FAILURE, 'Unable to update Bank Account Details')
        }
    } catch (error) {
        showNotification(STATUS.FAILURE, 'Unable to update Bank Account Details')
    }

    }

    useEffect(() => {
        getBillingInformation()
    }, [isBusinessUser])

    const hasLegalEditPermission = (): boolean => {
        const permission = validatePermission([LIST_OF_ROLES.LEAGAL_UPDATE], userPermission);

        return permission;
    };

    const ifscValidator = async (ifscCode: string) =>  {
        const formatedIFSC: string = ifscCode?.replace(/[^a-z0-9]/gi,'').substring(0,11);
        setValue("ifsc", formatedIFSC);

        if(formatedIFSC.length === 11) {
           const bankData: ISuccessResponse | IErrorResponse = await onboardingService.getBankSuggestions(formatedIFSC);
           
           if(bankData.status === STATUS.SUCCESS) {
               const {data} = bankData as ISuccessResponse;

               if(data?.bankInfomations.length) {
                   setValue("bankName", data.bankInfomations[0].bank_name);
                   setValue("branch", data.bankInfomations[0].branch);

               } else {
                setValue("bankName", "")
                showNotification(STATUS.FAILURE, `No matching record found for the IFSC - ${formatedIFSC}`);

               }
           }
        } else {
            setValue("bankName", "");
        }
    };

    return (
        <div className="bank-account-page">
            <div className="legal-layout-wrapper">
                <CardHeader title="Bank Account details" edit={hasLegalEditPermission() && !bankAndFinancialEditMode} action={BANK_AND_FINANCIAL}/>

                <form className="legal-details-content" onSubmit={handleSubmit(onSubmit)}>
                <BankVerificationStatus/>
                    <InputLabelwithDowninput
                        label="Bank Name*"
                        // register={register}
                        error={errors.bankName?.type !== undefined}
                        errorText={errors.bankName ? errors.bankName.message : ''}
                        register={register(formValidations.bankName)}
                        disabled
                        name="bankName"
                    />
                    <InputLabelwithDowninput
                        label="Account Number*"
                        disabled={!bankAndFinancialEditMode}
                        error={errors.accountNumber?.type !== undefined}
                        errorText={errors.accountNumber ? errors.accountNumber.message : ''}
                        register={register(formValidations.accountNumber)}
                        name="accountNumber"
                    />
                    <InputLabelwithDowninput
                        label="IFSC Code*"
                        disabled={!bankAndFinancialEditMode}
                        error={errors.ifsc?.type !== undefined}
                        errorText={errors.ifsc ? errors.ifsc.message : ''}
                        register={register(formValidations.ifsc)}
                        onChange={(event) => ifscValidator(event.target.value) }
                        name="ifsc"

                    />
                    <InputLabelwithDowninput
                        label="Branch*"
                        disabled
                        error={errors.branch?.type !== undefined}
                        errorText={errors.branch ? errors.branch.message : ''}
                        register={register(formValidations.branch)}
                        name="branch"
                    />
                    <div className="form-data-hide">
                        <InputLabelwithDowninput
                            label="Confirm Account Number"
                            type="password"

                            name="confirmAccountNumber"
                            register={register}
                        />
                        <InputLabelwithDowninput
                            label="Account Holder Name"

                            name="accountHolderName"
                            register={register}

                        />

                        <InputLabelwithDowninput
                            label="Address 1"

                            name="address1"

                            register={register}
                        />
                        <InputLabelwithDowninput
                            label="Address 2"
                            name="address2"

                            register={register}
                        />

                        <InputLabelwithDowninput
                            label="Pin Code"

                            name="pin"


                            register={register}
                        />
                    </div>
                    <div className="save-btn-wrap">
                        {bankAndFinancialEditMode ? <ActionButton label="Save" type="submit" /> : <></>}
                    </div>
                </form>
            </div>

        </div>
    )
}

export default BankAccountPage