import { AxiosError } from 'axios'
import * as _ from "lodash";

import { store } from 'redux/store'
import { API_ENDPOINTS,  parseAxiosError, rolesPersmission } from 'common/constant'
import { LOGIN, USER, USERUPDATE } from 'redux/actions/UserActionTypes'

import { ILoginCredentials, INewSellerDetails, IOnBoardingDetails, IResetPassword, IBankUpdateDetails, IAccountDetails } from './interfaces/login.interface'
import { BaseService } from './base.service'
import { IErrorResponse, ISuccessResponse } from './interfaces/common.interface'




export class LoginService extends BaseService {
  public async login(credentials: ILoginCredentials): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.LOGIN, credentials)
      let result: string[] = []
      rolesPersmission.map((item) => {
        const permission = _.keys(data.data.Permission[item]).filter((element) => data.data.Permission[item][element]);
        result = [...result, ...permission]?.filter(v => v !== '_id')
      })


      data.data.Permission = result
      store.dispatch({
        type: LOGIN,
        payload: data.data
      })
      localStorage.setItem('userMail', data.data.email)
      data.data.code ? localStorage.setItem('seller_code', data.data.code) :  localStorage.setItem('seller_code', data.data.account_code);

      localStorage.setItem('role', data.data.role)

      const role: string | null = data.data.role;

      const isAdmin: boolean =
        role === 'ROOT_ADMIN' ||
        role === 'PMPL_CATALOGUE_MANAGER' ||
        role === 'PMPL_OPERATIONS_MANAGER' ||
        role === 'PMPL_FINANCE_MANAGER'

       isAdmin ? localStorage.setItem('name', data.data.name):
      localStorage.setItem('name', data.data.legalName)


      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async ssoLogin(token: any): Promise<ISuccessResponse | IErrorResponse> {
    try {


      const { data } = await this.httpClient.post(API_ENDPOINTS.SSO_LOGIN, {token})

      let result: string[] = []
      rolesPersmission.map((item) => {
        const permission = _.keys(data.data.Permission[item]).filter((element) => data.data.Permission[item][element]);
        result = [...result, ...permission]?.filter(v => v !== '_id')
      })

      data.data.Permission = result
      store.dispatch({
        type: LOGIN,
        payload: data.data
      })
      localStorage.setItem('userMail', data.data.email)
      data.data.code ? localStorage.setItem('seller_code', data.data.code) :  localStorage.setItem('seller_code', data.data.account_code);

            localStorage.setItem('role', data.data.role)


      const role: string | null = data.data.role;

      const isAdmin: boolean =
      role === 'ROOT_ADMIN' ||
      role === 'PMPL_CATALOGUE_MANAGER' ||
      role === 'PMPL_OPERATIONS_MANAGER' ||
      role === 'PMPL_FINANCE_MANAGER'

     isAdmin ? localStorage.setItem('name', data.data.name):
    localStorage.setItem('name', data.data.legalName)

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }


  public async getuserDetails(email: string): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_USER_DETAILS}/${email}`)
      store.dispatch({
        type: USER,
        payload: data.data
      })
      localStorage.setItem('userMail', data.data.email)
      localStorage.setItem('role', data.data.role)

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async updateUserDetails(fields: IBankUpdateDetails): Promise<ISuccessResponse | IErrorResponse> {
    const userEmail = localStorage.getItem('userMail') || ''
    fields.email = userEmail

    try {
      const { data } = await this.httpClient.put(
        `${API_ENDPOINTS.UPDATE_USER_DETAILS}`,
        fields
      )
      store.dispatch({
        type: USERUPDATE,
        payload: data.data
      })



      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }


  public async register(sellerDetails: INewSellerDetails): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.SELLER_REGISTRATION, sellerDetails)

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async onBoard(onBoardingDetails: IOnBoardingDetails): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.ON_BOARDING, onBoardingDetails)

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getOnBoardingStatus(): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.get(API_ENDPOINTS.ON_BOARDING_STATUS)

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async forgotPassword(email: string): Promise<ISuccessResponse | IErrorResponse> {

    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.FORGOT_PASSWORD, { email })

      return data

    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }

  }

  public async getActivationLink(email: string): Promise<ISuccessResponse | IErrorResponse> {

    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.RESEND_ACTIVATION_LINK, { email })

      return data

    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }

  }

  public async resetPassword(resetPasswordData: IResetPassword): Promise<ISuccessResponse | IErrorResponse> {

    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.RESET_PASSWORD, { token: resetPasswordData.token, password: resetPasswordData.password })

      return data

    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }

  }

  public async resetPasswordStatus(token): Promise<ISuccessResponse | IErrorResponse> {

    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.RESET_PASSWORD_STATUS, { token: token })

      return data

    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }

  }

  public async addAccount(accountData: IAccountDetails): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.ADD_ACCOUNT, { ...accountData })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }


  public async checkCodeAuthMode(code: string): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.get(API_ENDPOINTS.AUTH_MODE_CHECK_CODE + `?code=${code}`);

      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async checkCode(code: string): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.get(API_ENDPOINTS.CHECK_ACCOUNT_CODE + `?code=${code}`);

      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getRole(): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.get(API_ENDPOINTS.GET_ROLE);

      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }


  public async addSeller(sellerData: IAccountDetails): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.ADD_SELLER_ACCOUNT, sellerData);

      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async activateEmail(token: string): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.get(API_ENDPOINTS.ACTIVATE_EMAIL, { headers: { 'authorization': token } });

      return data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

}
