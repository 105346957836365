import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from 'redux/store';
import { useHistory } from 'react-router-dom';


import './styles.scss'
import LandingCard from 'components/landingCard';
import ProfileManagement from 'assets/images/Product-details.svg'
import PurchaseOrders from 'assets/images/Purchase-Orders.svg'
import OrderManagement from 'assets/images/Order_Management.svg'
import UsersRoles from 'assets/images/Users-Roles.svg'
import Store from 'assets/images/store.svg'
import ProductListing from 'assets/images/Product-Listing.svg'
import PoorvikaLogo from 'assets/images/poorvika-Logo.svg'
import AppheaderRightContent from 'components/AppheaderRightContent';
import { isVisible, LIST_OF_ROLES, ON_BORADING_STEPS, showNotification, STATUS} from 'common/constant';
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
import { OnboardingService } from 'services/onBoarding.service'
import { InventoryService } from 'services/inventory.service';

import { activeLocationCount } from 'redux/actions/inventoryCount.action';





const LandingPage: React.FC = () => {
    const onboardingService = new OnboardingService();
    const inventoryService = new InventoryService();
    const dispatch = useDispatch()

    const [activeWarehouseCount, setActiveWarehouseCount] =  React.useState<number>(0);
    const history = useHistory<History>()
    const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission);
    
    const role = localStorage.getItem('role');
    const isAdmin = (role === "ROOT_ADMIN" || role === "PMPL_CATALOGUE_MANAGER" ||role === "PMPL_OPERATIONS_MANAGER" ||role === "PMPL_FINANCE_MANAGER" )
    const isSeller = (role === 'SELLER_ADMIN')
    const isCallSellerDetail: boolean = (role === 'SELLER_CATALOGUE_MANAGER' || role == 'SELLER_OPERATION_MANAGER' || role === 'SELLER_FINANCE_MANAGER')

    useEffect(() => {
        if (!localStorage.getItem('accessToken')) {
            history.push('/login')
         }
      }, [])

      useEffect( () => {
        const getActiveWarehouseData =  async(supplier_id) =>{
            const getWareHouseResponse: ISuccessResponse | IErrorResponse = await inventoryService.getwarehouselist(supplier_id, 1)
            if (getWareHouseResponse.status === STATUS.SUCCESS) {
                setActiveWarehouseCount(getWareHouseResponse?.data.warehouselist.length)
                dispatch(activeLocationCount(getWareHouseResponse.data.warehouselist.length))
                if(getWareHouseResponse?.data.warehouselist.length == 0){
                    showNotification(STATUS.FAILURE, ' To add or view products need atleast one active location.')
                }
            } else{
                showNotification(STATUS.FAILURE, ' Unable to get active location list')
            }
        }

        const getSellerData = async () => {
        //Note : if seller email is empty means - this may be seller USER so we have to get the seller email form backend
        let usermail: string = localStorage.getItem('userMail') as string;
        if(isCallSellerDetail){
            const getSellerEmail: ISuccessResponse | IErrorResponse = await onboardingService.getLoginUserSellerDetails(usermail)  
            if (getSellerEmail.status === STATUS.SUCCESS) {
              //Note : UPDATE THE basic details in the 
              localStorage.setItem('seller_code', getSellerEmail.data.account_code)
              localStorage.setItem('seller_name', getSellerEmail.data.name)
              localStorage.setItem('sellerMail', getSellerEmail.data.email)
              localStorage.setItem('code', getSellerEmail.data.account_code)
              localStorage.setItem('supplier_apx_code', getSellerEmail.data.supplier_id);
              await getActiveWarehouseData(getSellerEmail.data.supplier_id);
            }
          } else{
            let supplier_id = localStorage.getItem("supplier_apx_code") as string;
            if(!!supplier_id){
                await getActiveWarehouseData(supplier_id);
            } else{
                let usermail: string = localStorage.getItem('userMail') as string;
                await onboardingService.getOnboardingData(1,usermail ) ;
                await getActiveWarehouseData(localStorage.getItem("supplier_apx_code")); 
            } 
          }
        };

        getSellerData();
      }, [isCallSellerDetail]   )
    

    return (
        <div>
            <div className={"landing-header "+(isAdmin? 'seller_header' : '')} >
                <div>
                    <img className="poorvikaheader-logo" draggable="false" src={PoorvikaLogo} />

                </div>

                <AppheaderRightContent />


            </div>
            <div className="landing-page-content">

                {isVisible([ LIST_OF_ROLES.MENU_PROFILE_DETAILS,LIST_OF_ROLES.LEAGAL_CREATE, LIST_OF_ROLES.LEAGAL_UPDATE, LIST_OF_ROLES.LEAGAL_VIEW, LIST_OF_ROLES.BANK_CREATE, LIST_OF_ROLES.BANK_UPDATE, LIST_OF_ROLES.BANK_VIEW,
                LIST_OF_ROLES.CONTACT_CREATE, LIST_OF_ROLES.CONTACT_UPDATE, LIST_OF_ROLES.CONTACT_VIEW, LIST_OF_ROLES.TAX_FINANCE_CREATE, LIST_OF_ROLES.TAX_FINANCE_UPDATE,
                LIST_OF_ROLES.TAX_FINANCE_VIEW, LIST_OF_ROLES.KYC_CREATE, LIST_OF_ROLES.KYC_UPDATE, LIST_OF_ROLES.KYC_VIEW, LIST_OF_ROLES.SELLER_SUMMARY]
                    , { user: userPermission }) ?<>
                    <LandingCard title="Profile Details" label="View and edit your profile details" img={ProfileManagement} onClick={() => history.push('/sellerLayout/profileDetials/Overview')} />
                     </>
                    : <></>}

            {(isVisible( [LIST_OF_ROLES.MENU_PURCHASE_ORDER], { user: userPermission }) && activeWarehouseCount > 0) ?<>
                <LandingCard title="Purchase Orders" label="Purchase Orders" img={PurchaseOrders} onClick={() => history.push('/sellerLayout/purchaseOrder/Pending')}/>
                </>
            : <></>}
                {isVisible([LIST_OF_ROLES.PRODUCT_ADD, LIST_OF_ROLES.PRODUCT_VIEW, LIST_OF_ROLES.PRODUCT_EDIT, LIST_OF_ROLES.PRODUCT_DELIST, LIST_OF_ROLES.PRODUCT_APPROVE_REJECT, LIST_OF_ROLES.PRODUCT_BULK_UPLOAD], { user: userPermission }) && activeWarehouseCount > 0 ?

                    <LandingCard title="Product Listing" label="Product Listing" img={ProductListing} onClick={() => history.push('/sellerLayout/productListing/searchProduct')} />
                    : <></>}

                {isVisible([LIST_OF_ROLES.MENU_PRODUCT_LISTING ,LIST_OF_ROLES.WAREHOUSE_CREATE, LIST_OF_ROLES.WAREHOUSE_EDIT, LIST_OF_ROLES.WAREHOUSE_DELETE, LIST_OF_ROLES.WAREHOUSE_LIST,
                LIST_OF_ROLES.WAREHOUSE_VIEW_STOCK, LIST_OF_ROLES.WAREHOUSE_UPDATE_STOCK, LIST_OF_ROLES.WAREHOUSE_ADD_PRODUCT,
                LIST_OF_ROLES.EXPORT_INVENTORY, LIST_OF_ROLES.BULK_UPLOAD_STOCK], { user: userPermission }) ?
                    <LandingCard title="Location & Inventory" label="Location & Inventory" img={Store} onClick={() => history.push('/sellerLayout/inventory/ActiveLocations')} />
                    : <></>}
                {isVisible( [LIST_OF_ROLES.MENU_OMS], { user: userPermission })  && activeWarehouseCount > 0 ?<>
                <LandingCard title="Order Management" label="Order Management" img={OrderManagement} onClick={() => history.push('/sellerLayout/orderManagement')}  />
                </>
                : <></>}
                {isSeller && isVisible( [LIST_OF_ROLES.MENU_USERS_ROLES], { user: userPermission }) && activeWarehouseCount > 0?
                <LandingCard title="Users & Roles" label="Users & Roles" img={UsersRoles} onClick={() => history.push('/sellerLayout/usersRoles')}/>

                :
                <></>}
                 {isAdmin && isVisible( [LIST_OF_ROLES.MENU_USERS_ROLES], { user: userPermission }) && activeWarehouseCount > 0 ?
                <LandingCard title="Users & Roles" label="Users & Roles" img={UsersRoles} onClick={() => history.push('/sellerLayout/usersRoles')}/>

                :
                <></>}




            </div>

            {/* {logout ?
                <div className="header-card-wrapper">
                    <div className="header-card-content">
                        <p>Manage Profiles</p>
                        <p onClick={logoutHandler}>Logout</p>

                    </div>

                </div>
                : <> </>

            } */}

        </div>


    )




}

export default LandingPage

    