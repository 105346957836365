import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
// import ErrorIcon from '@material-ui/icons/Error';
// import { Link } from 'react-router-dom';

import { showNotification, STATUS } from 'common/constant'
import { decrypt, encrypt } from 'common/encryptor'
import ActionButton from 'components/ActionButton'
import InputWithLabelsNotRequired from 'components/InputLabelsNotrequired'
import InputWithDropDown from 'components/InputwithDropdown'
import InputWithLabels from 'components/InputWithLabels'
import WarningTextLayer from 'components/WarningTextLayer'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
import { IbankInfo } from 'services/interfaces/onBoarding.interface'
import { OnboardingService } from 'services/onBoarding.service'
import './styles.scss'
import Spinner from 'components/Spinner'
import { ProductService } from 'services/productService'
import { Box, Chip, FormControl, InputLabel, OutlinedInput, Select } from '@material-ui/core'
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: 300,
      maxWidth:300
    },
    formControl1:{
      margin: theme.spacing(1),
      marginTop: 30,
      width: 220,
      maxWidth:300
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }),
);

type TBillingInformationProps = {
  nextStep: () => void,
  movePrevious: () => void;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

type TBillingInformations = {
 bankName: string
 accountHolderName: string
 accountNumber: string,
 confirmAccountNumber: string,
 ifsc: string,
 address: string,
 address1: string
  address2: string
  address3:string
  city: string
  state: string
  pin: string
  sameAsLegalAddress: boolean,
  categoriesList: any,
  brandList: any
}

type ILegalAddress = {
  address1: string,
  address2: string,
  address3: string,
  city:string,
  pin: string,
  state: string
}


const BillingAndTaxingForm: React.FC<TBillingInformationProps> = ({movePrevious, nextStep}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [bankList, setBankList] = useState<string[]>([]);
  const [bankName, setBankName] = useState("")
  const [bankAddress, setBankAddress] = useState("")
  const { register, handleSubmit, errors, watch, setValue } = useForm<TBillingInformations>({ mode: 'onChange' })
  const onboardingService = new OnboardingService();
  const [billingAddress, setBillingAddress] = useState(false);
  const [legalAddress, setLegalAddress] = useState<ILegalAddress | null >();
  const [rejectionNotes, setRejectionNotes] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [brandlist, setBrandlist] = React.useState<string[]>([]);
  const [categorylist, setCategorylist] = React.useState<string[]>([]);
  const [brand, setBrand] = React.useState<string[]>([]);
  
  const [category, setCategory] = React.useState<string[]>([]);
  /* Services*/
  const productService: ProductService = new ProductService()

  // const [isText, setIsText] =  useState(false);
  /* Form validations */

  
  const billingInformationForm = {
    bankName: {required:{ value: true, message: 'Bank Name is empty'}},
    accountNumber: {required:{ value: true, message: 'Account Number is empty'}},
    confirmAccountNumber: {
      required:{ value: true, message: 'Confirm Account Number is empty'}, 
      validate: (value: string) => value === watch('accountNumber') || "Account number don't match."
    },
    accountHolderName: {required:{ value: true, message: 'Account Holder name is empty'}},
    ifsc: {
      required:{ value: true, message: 'IFSC is empty'},
      minLengh: {value: 11, message: 'IFSC code should be 11 Characters Length'}
  },
  address1: { required: { value: true, message: "Address Field can't be empty" } },
  address2: { required: { value: true, message: "Address Field can't be empty" } },
  city: { required: { value: true, message: "city Field can't be empty" } },
  state: { required: { value: true, message: "state Field can't be empty" } },
  pin: { 
    required: { value: true, message: "pincode  Field can't be empty" },
    minLength: {value: 6, message: "pincode must be 6 characters long"}
  }
  }

  const getIFSCSuggestions = async (ifscode: string) => {
    try {
      if(ifscode.length) {
        const bankList: ISuccessResponse | IErrorResponse = await onboardingService.getBankSuggestions(ifscode);

        if(bankList.status  === STATUS.SUCCESS) {
          const successList = bankList as ISuccessResponse;
          const bankSugesstions: string [] = [];
          successList.data.bankInfomations.map((element: IbankInfo)=> bankSugesstions.push(element.ifsc))
          setBankList(bankSugesstions)

          if(ifscode.length === 11 && successList.data.bankInfomations.length) {
            setBankName(successList.data.bankInfomations[0].bank_name);
            setBankAddress(successList.data.bankInfomations[0].address);
          } else if(ifscode.length === 11 && !successList.data.bankInfomations.length) {
            showNotification(STATUS.FAILURE, "IFSC Not Exits")
            setBankName("");
            setBankAddress("")
          }
        }
      } else {
        setBankName("");
        setBankAddress("")
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get the bank list')
    }
    
  }

  const handleBillingAddress = async (value: boolean) => {
    // setBillingAddress(value);


    const getPersonalData = async () => {
      try {
        setLoading(true)
        const personalData: ISuccessResponse | IErrorResponse = await onboardingService.getOnboardingData(1)

        if (personalData.status === STATUS.SUCCESS) {     
          const successData = personalData as ISuccessResponse;     
          setValue("address1", successData.data.address1);
          setValue("address2", successData.data.address2);
          setValue("address3", successData.data.address3);
          setValue("city", successData.data.city);
          setValue("pin", successData.data.pincode);
          setValue("state", successData.data.state);
          setLegalAddress({
            address1: successData.data.address1,
            address2: successData.data.address2,
            address3: successData.data.address3,
            city: successData.data.city,
            pin: successData.data.pincode,
            state: successData.data.state
          })

        } else {
          showNotification(STATUS.FAILURE, 'Unable to get Billing informations')
        }
        setLoading(false)
      } catch (error) {
        showNotification(STATUS.FAILURE, 'Unable to get Billing informations')
        setLoading(false)
      }
    }

    if(value) {
      getPersonalData()
    } else {
      setValue("address1", "");
      setValue("address2", "");
      setValue("address3", "");
      setValue("city","");
      setValue("pin", "");
      setValue("state", "");
      setLegalAddress({
        address1: "",
        address2: "",
        address3: "",
        city: "",
        pin: "",
        state: ""
      })
      setBrand([]);
      setCategory([]);
    }

    setBillingAddress(value);

    
  }
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setBrand(event.target.value as string[]);
  };

  const handleChangecategory = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCategory(event.target.value as string[]);
  };
  
  const onSubmit = async (data: TBillingInformations) => { 
    let saveBrandlist : any =  [];
    let saveCategorylist : any =  [];
    if(brand.length>0){
      brand.forEach((bardval, index) => {
        const idx = brandlist.findIndex((element) => element['name'] === bardval);
        if(idx != -1){
          saveBrandlist.push({"name": brandlist[idx]["name"], "code": brandlist[idx]["code"]})
        }
      });
    }
    if(category.length>0){
      category.forEach((catval, index) => {
        const idx = categorylist.findIndex((element) => element['name'] === catval);
        if(idx != -1){
          saveCategorylist.push({"name": categorylist[idx]["name"], "code": categorylist[idx]["code"]})
        }
      });
    }
    if(billingAddress) {
      data.address1 = legalAddress?.address1 ?? '';
      data.address2 = legalAddress?.address2 ?? '';
      data.address3 = legalAddress?.address3 ?? '';
      data.city = legalAddress?.city ?? '';
      data.pin = legalAddress?.pin ?? '';
      data.state = legalAddress?.state ?? '';
    } 
    data.sameAsLegalAddress = billingAddress;
    /* Encrpting the account number */
    data.accountNumber = encrypt(data.accountNumber);
    data.confirmAccountNumber = encrypt(data.confirmAccountNumber);
    data.categoriesList = saveCategorylist;
    data.brandList = saveBrandlist;

    const saveBillingInformations: ISuccessResponse | IErrorResponse = await onboardingService.saveOnBoardingData({...data, step: 'BILLING_AND_TAXING_INFORMATION'})

    if(saveBillingInformations.status === STATUS.SUCCESS) {
      nextStep();
    } else {
      showNotification(STATUS.FAILURE, 'Unable to save billing Informations')
    }
  }

  useEffect(() => {
    const getBillingInformation = async () => {
      try {
        const personalData: ISuccessResponse | IErrorResponse = await onboardingService.getOnboardingData(2)

        if (personalData.status === STATUS.SUCCESS) {     
          const successData = personalData as ISuccessResponse;
          setValue("address1", successData.data.address1);
          setValue("address2", successData.data.address2);
          setValue("address3", successData.data.address3);
          setValue("city", successData.data.city);
          setValue("pin", successData.data.pincode);
          setValue("state", successData.data.state);
          setLegalAddress({
            address1: successData.data.address1,
            address2: successData.data.address2,
            address3: successData.data.address3,
            city: successData.data.city,
            pin: successData.data.pincode,
            state: successData.data.state
          })
          setBankName(successData.data.bankName);
          setBankAddress(successData.data.bankAddress)
          setRejectionNotes(successData.data.rejection_notes);
          setValue("accountHolderName", successData.data.accountHolderName);
          setValue("accountNumber",successData.data.accountNumber ?  decrypt(successData.data.accountNumber) : "");
          setValue("confirmAccountNumber", successData.data.accountNumber ?  decrypt(successData.data.accountNumber) : "");
          setValue("ifsc", successData.data.ifsc);
          setBillingAddress(successData.data.sameAsLegalAddress);
          let tmpBrand : string[] = [], tmpCatgory : string[] = [];
          let savedBrand: any = !!successData.data.brandList ? successData.data.brandList : []
          let saveCat: any = !!successData.data.categoriesList ? successData.data.categoriesList : []
          savedBrand.forEach((bardval, index) => {
            tmpBrand.push(bardval.name)
          });
          saveCat.forEach((catval, index) => {
            tmpCatgory.push(catval.name)
          });
          setBrand(tmpBrand)
          setCategory(tmpCatgory)

        } else {
          showNotification(STATUS.FAILURE, 'Unable to get Billing informations')
        }
      } catch (error) {
        showNotification(STATUS.FAILURE, 'Unable to get Billing informations')
      }
    }
    const getPIMProductCategories = async () => {
      try {
        const Pimproducts: ISuccessResponse | IErrorResponse = await productService.getPIMProductsCategoryAll()
        const getpimproducts = Pimproducts as ISuccessResponse
        setCategorylist(getpimproducts?.data?.beadcrumbs)
      } catch (error) {
        showNotification(STATUS.FAILURE, 'Unable to get Catergory informations')
      }
    }
  
    const getPIMBrandList = async () => {
      try {
        const PimBrands: ISuccessResponse | IErrorResponse = await productService.getPIMBrandListAll()
        const getpimbrands = PimBrands as ISuccessResponse
        setBrandlist(getpimbrands.data.brand)
      } catch (error) {
        showNotification(STATUS.FAILURE, 'Unable to get Product informations')
      }
    }

    getPIMProductCategories();
    getPIMBrandList();
    getBillingInformation();
    
    
  }, [])
   

  return (
    <div className="billing-and-taxing-form-page">
      <WarningTextLayer info={rejectionNotes}/>
      <Spinner loading={loading}/>
        <form autoComplete="off" action="" className="billing-and-taxing-form" onSubmit={handleSubmit(onSubmit)}>
        <p className="form-subtitle">Banking Information</p>
        <InputWithLabels 
          text="Account Holder Name"
          error={errors.accountHolderName?.type !== undefined}
          errorText={errors.accountHolderName ? errors.accountHolderName.message : ''}
          name="accountHolderName"
          register={register(billingInformationForm.accountHolderName)}
          
          />
                    <InputWithLabels 
          text="Account Number"
          error={errors.accountNumber?.type !== undefined}
          errorText={errors.accountNumber ? errors.accountNumber.message : ''}
          name="accountNumber"
          register={register(billingInformationForm.accountNumber)}
          type='password'
          onChange={() => setValue("confirmAccountNumber", "")}
          />
          <InputWithLabels 
          text="Confirm Account Number" 
          type="text"
          onPaste={(event)=> {event.preventDefault();

 return false}}
          error={errors.confirmAccountNumber?.type !== undefined}
          errorText={errors.confirmAccountNumber ? errors.confirmAccountNumber.message : ''}
          name="confirmAccountNumber"
          register={register(billingInformationForm.confirmAccountNumber)}
          />
          <InputWithDropDown 
          text="IFSC" 
          maxLength={11} 
          onChange={(event) => getIFSCSuggestions(event.target.value)} 
          dropdown={bankList}
          error={errors.ifsc?.type !== undefined}
          errorText={errors.ifsc ? errors.ifsc.message : ''}
          name="ifsc"
          register={register(billingInformationForm.ifsc)}
          />
          {/* <div className="bank-not-found">
          <Link to="#">
            <ErrorIcon  className="info-ic" />
            Unable to find your bank ?</Link>
          </div> */}
          <InputWithLabels text="Bank Name" value={bankName} disabled/>
          <InputWithLabels text="Bank Branch" value={bankAddress} disabled/>
          

       
          <p className="form-subtitle">Communication address</p>
          <InputWithLabels text="Same as Legal Address ?" type="checkbox" checked={billingAddress} onChange= {(event) => handleBillingAddress(event.target.checked) }/>
          
          <InputWithLabels 
          text="Address 1" 
          disabled={billingAddress}
          name="address1"
          error={errors.address1?.type !== undefined}
          errorText={errors.address1 ? errors.address1.message : ''}
          register={register(billingInformationForm.address1)}
           />
          <InputWithLabels
           text="Address 2"
           name="address2"
           error={errors.address2?.type !== undefined}
           errorText={errors.address2 ? errors.address2.message : ''}
           register={register(billingInformationForm.address2)}
            disabled={billingAddress}/>
            <InputWithLabelsNotRequired
           text="Address 3"
           name="address3"
           register={register}
            disabled={billingAddress}/>
          <InputWithLabels 
          text="City" 
          disabled={billingAddress}
          name="city"
          error={errors.city?.type !== undefined}
          errorText={errors.city ? errors.city.message : ''}
          register={register(billingInformationForm.city)}/>
          <InputWithLabels 
          text="State" 
          disabled={billingAddress}
          name="state"
          error={errors.state?.type !== undefined}
          errorText={errors.state ? errors.state.message : ''}
          register={register(billingInformationForm.state)}/>
          <InputWithLabels 
          text="Pin Code" 
          disabled={billingAddress}
          name="pin"
          maxLength={6}
          onChange= {(event)=> event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1') }
          error={errors.pin?.type !== undefined}
          errorText={errors.pin ? errors.pin.message : ''}
          register={register(billingInformationForm.pin)}
          />

        <p className="form-subtitle">Selling Product Information</p>
        <div>
        <FormControl  className={classes.formControl1}>  Choose Brand wish to Sell:</FormControl>
        <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-chip-label">Choose Brand</InputLabel>
        <Select
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          multiple
          value={brand}
          defaultValue={brand}
          onChange={handleChange}
          input={<Input  id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {(selected as string[]).map((value) => (
                <Chip key={value} label={value} className={classes.chip}/>
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {brandlist.map((brandval) => (
            <MenuItem key={brandval["code"]} value={brandval["name"]}  style={getStyles(brandval["name"], brand, theme)}>
              {brandval["name"]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
    <div>
        <FormControl  className={classes.formControl1}>  Choose Category wish to Sell:</FormControl>
        <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-chip-label">Choose Category</InputLabel>
        <Select
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          multiple
          value={category}
          onChange={handleChangecategory}
          input={<Input  id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {(selected as string[]).map((value) => (
                <Chip key={value} label={value} className={classes.chip}/>
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {categorylist.map((catval) => (
            <MenuItem key={catval["code"]} value={catval["name"]}  style={getStyles(catval["name"], brand, theme)}>
              {catval["name"]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
        <div>
        <ActionButton label="< Back" varient="outlined" onClick={()=> movePrevious()}/>  
        <ActionButton label="Save & Next" type="submit"/>
        </div>
      </form>
    </div>
  )
}

export default BillingAndTaxingForm
