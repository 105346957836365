import React, { useEffect, useState } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { Button, Menu, MenuItem, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import NestedMenuItem from 'material-ui-nested-menu-item'
import Pagination from '@material-ui/lab/Pagination'

import { RootStore } from '../../../redux/store'
import { ProductService } from '../../../services/productService'
import productImage from '../../../assets/mobiles/mobilePhone.png'
import AppHeaderLayout from '../../../components/AppHeaderLayout'
import MappedLocationsForm from '../../../containers/form/MappedLocationsForm'

import './styles.scss'


type AddProductprops = {
  onStatus: (data) => void
}
const AddProduct: React.FC<AddProductprops> = () => {
  const role: string | null = localStorage.getItem('role')
  const isAllowed: boolean =
    role === 'ROOT_ADMIN' ||
    role === 'PMPL_CATALOGUE_MANAGER' ||
    role === 'PMPL_OPERATIONS_MANAGER' ||
    role === 'PMPL_FINANCE_MANAGER'

  const [menuPosition, setMenuPosition] = useState<any>(null)
  const [categoryValue, setCategoryValue] = useState<any>('')
  const [mappedLocation, setMappedLocation] = useState<boolean>(false)
  const productService: ProductService = new ProductService()
  // const getProductCategories = () => productService.getProductCategories()

  const productStatus = [
    {
      name: 'Samsung',
      listingID: 'MP000000003637257',
      sku: 'IOEO754IIUA',
      Mop: '$14,359',
      productid: 'FEUI878788…',
      CombinationID: 'Mapped Locations',
      SafeLimit: '1 Nos'
    },
    {
      name: 'Oneplus',
      listingID: 'MP000000003637257',
      sku: 'IOEO754IIUA',
      Mop: '$1,02,164',
      productid: 'FEUI878788…',
      CombinationID: 'Mapped Locations',
      SafeLimit: '1 Nos'
    },
    {
      name: 'Iphone',
      listingID: 'MP000000003637257',
      sku: 'IOEO754IIUA',
      Mop: '$14,359',
      productid: 'FEUI878788…',
      CombinationID: 'Mapped Locations',
      SafeLimit: '1 Nos'
    },
    {
      name: 'Redmi',
      listingID: 'MP000000003637257',
      sku: 'IOEO754IIUA',
      Mop: '$1,02,164',
      productid: 'FEUI878788…',
      CombinationID: 'Mapped Locations',
      SafeLimit: '1 Nos'
    }
  ]

  const onClickMenu = (event: React.MouseEvent) => {
    if (menuPosition) {
      return
    }
    event.preventDefault()
    setMenuPosition({
      top: event.pageY,
      left: event.pageX
    })
  }

  //     public filterList(ev) {
  //     if (this.listType === 'Country') {
  //         console.log('here', ev.target.value);
  //         this.itemList = this.tempList.filter(item => item.country_name.toLowerCase().indexOf(ev.target.value.toLowerCase()) !== -1);
  //     } else {
  //         this.loadListing(ev.target.value);
  //     }
  // }

  const handleItemClick = (e) => {
    setMenuPosition(null)
    setCategoryValue(e)
  }

  const categories = useSelector((state: RootStore) => state.product.productCategories)
  console.log(categories)

  // useEffect(() => {
  //   getProductCategories()
  // }, [])

  function Comment({ comment }) {
    const nestedComments = comment.SubCategory.map((comment) => {
      return <Comment key={comment.id} comment={comment} />
    })

    return (
      <div>
        {comment.SubCategory.length ? (
          <NestedMenuItem
            label={`${comment.name}`}
            parentMenuOpen={!!menuPosition}
            onClick={() => handleItemClick(`${comment.name}`)}
          >
            {nestedComments}
            {comment.SubCategory.map((sCate) => {
              if (sCate.SubCategory.length === 0) {
                return <MenuItem onClick={() => handleItemClick(`${sCate.name}`)}>{sCate.name}</MenuItem>
              }
            })}
          </NestedMenuItem>
        ) : (
          comment.SubCategory.map((sCate) => {
            return <MenuItem onClick={() => handleItemClick(`${sCate.name}`)}>{sCate.name}</MenuItem>
          })
        )}
      </div>
    )
  }

  return (
    <div>
      <AppHeaderLayout
        title="Product Listings :"
        to={
          isAllowed
            ? '/admin/seller/sellerLayout/productListing/searchProduct'
            : '/sellerLayout/productListing/searchProduct'
        }
      />
      <div className="add-product-layout">
        <div className="addproducts-table-header">
          <p>Search & Add Product to List</p>
          <div className="search-input-field">
            <SearchIcon className="search-ic" />
            <input type="search" placeholder="Search by product name " />
          </div>
          <span>OR</span>

          <div className="catogeries-wrapper">
            <Button className="catogeries-btn-primary">
              <Typography style={{ cursor: 'pointer', backgroundColor: '#efefef' }} onClick={onClickMenu}>
                {categoryValue !== '' ? categoryValue : 'categories'}
              </Typography>
            </Button>

            <div>
              <Menu
                anchorPosition={menuPosition}
                anchorReference="anchorPosition"
                open={!!menuPosition}
                onClose={() => setMenuPosition(null)}
              >
                {categories?.productcategories[0]?.categories.map((value) => {
                  return <Comment key={value.code} comment={value} />
                })}
              </Menu>
            </div>
          </div>
          <div className="brand-input-field">
            <input type="search" placeholder="Brand" />
          </div>
        </div>
        <div>
          <table className="inventory-products-table">
            <thead>
              <tr>
                <th>Product</th>
                <th>Listing ID</th>
                <th>SKU</th>
                <th>Style Code / EAN/ UPC / MPN</th>
                <th>MOP</th>
                <th>Product Status</th>
                <th>Mapped Locations</th>
              </tr>
            </thead>
            <tbody>
              {productStatus.map((item) => {
                return (
                  <tr key={item.sku}>
                    <td>
                      <div className="product-col">
                        <div className="pimg-wrap">
                          <img src={productImage} alt='product-img' className="product-img" />
                        </div>
                        <div className="pcontent-wrap">{item.name}</div>
                      </div>
                    </td>

                    <td>{item.listingID}</td>
                    <td>{item.sku}</td>
                    <td>{item.productid}</td>
                    <td>{item.Mop}</td>
                    <td className="product-status-dropdown">
                      <select
                        name="status"
                        id={item.name}
                        className="status-dropdown"
                        onChange={(data) => console.log(data.target.value, item.name)}
                      >
                        <option value="listit">Listed</option>
                        <option value="Delist">Delist</option>
                        <option value="Pending">Pending</option>
                      </select>
                    </td>
                    <td className="map-locations-column" onClick={() => setMappedLocation(true)}>
                      {item.CombinationID}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div className="all-products-pagination">
            <Pagination className="all-products-pagination" count={10} variant="outlined" shape="rounded" />
          </div>
        </div>
      </div>
      <MappedLocationsForm cancelClose={() => setMappedLocation(false)} open={mappedLocation} />
    </div>
  )
}

export default AddProduct
