import React, { useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import moment from 'moment'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import { DialogContent, DialogTitle } from '@material-ui/core'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

import { IHistoryResponse, IHistoryResponseData } from '../../services/interfaces/common.interface'

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MuiTableCell: {
        head: {
          backgroundColor: '#f2f2f2 !important'
        }
      }
    }
  })

const columns = [
  {
    name: 'id',
    label: 'No.',
    },
  {
    name: 'createdAt',
    label: 'Date',
    options: {
      filter: true,
      sort: false
    }
  },
  

  {
    name: "response",
    label: "Response",
    options: {
      display: false,
      download: false,
      viewColumns:false
    }
  },
  {
    name: 'result',
    label: 'Status',
    options: {
      filter: true,
      sort: true
    }
  },
 

  {
    name: 'url',
    label: 'File',
    options: {
      filter: true,
      sort: false
    }
  },

  {
    name: "file_name",
    label: "file",
    options: {
      searchable:true,
      display: false,
      download: false,
   }
  },

]

type ISummaryProps = {
  open: boolean,
  data: IHistoryResponse [],
  setOpen : (a:boolean) => void

}

type ISummaryData = {
 
  id: number
  result: JSX.Element,
  url: JSX.Element,
  response: IHistoryResponseData [],
  createdAt: string,
  file_name: string,

}



const ResultSummaryDialog: React.FC<ISummaryProps> = ({ open, setOpen, data }) => {
  const [result, setResult] = useState<ISummaryData[]>([])
  const [scroll] = React.useState<DialogProps["scroll"]>("paper");

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const filteredData = data?.map((item, index) => {
        return {
          id: index + 1,
          result: <div>
                   <span style={{color:'green', fontWeight:'bold'}}>{` Success - ${item.success}`}</span><br/>
                   <span style={{color:'red', fontWeight:'bold'}}>{` Failure - ${item.failed} `}</span>
                 </div>,
          url: <a href={item.url} target='_blank' style={{cursor: 'pointer'}}>{item.file}</a>,
          response: item.response,
          createdAt: moment(item.row_added_dttm).format('DD-MM-YYYY hh:mm a'),
          file_name: item.file,

        }
      })
    setResult(filteredData)
  }, [data])



  const options = {
    filter: false,
    viewColumns	: false,
    download: false,
    print: false,
   
    selectableRows: false,
    filterType: 'dropdown',
    responsive: 'scrollMaxHeight',
    rowsPerPage: 5,
    rowsPerPageOptions:{},
    expandableRows: true,
    customSearch: (searchQuery, currentRow, columns) => {
      let isFound = false;
      columns.forEach((col, i) => {
          if (col.searchable) {
              if (!!currentRow[i] && currentRow[i].toString().indexOf(searchQuery) >= 0) {
                  isFound = true;
              }
          }
      });

      return isFound;
  },

    renderExpandableRow: (rowData) => {
      return (
        <React.Fragment>
          <tr>
            <td colSpan={6}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table" style={{ minWidth: '650' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Poorvika Product Code</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Description</TableCell>
                  
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowData[2]?.map((row) => (
                      <TableRow key={row.pim_code}>
                        <TableCell component="th" scope="row">
                          {row.pim_code}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {
                             row.success ? <span style={{ color: 'green' }}>Success</span>
                             : <span style={{ color: 'red' }}>Failure</span>
                          }
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {row.missingdata === null ? row.data : `[${row.missingdata}] fields are missing`}
                        </TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      )
    },
    page: 0
  }

  return (
    <Dialog aria-describedby="scroll-dialog-description"     
     aria-labelledby="scroll-dialog-title"
     maxWidth="md"
     open={open}

     scroll={scroll}
     disableBackdropClick 
     fullWidth
     onClose={handleClose} 
     

     >
               <DialogTitle id="scroll-dialog-title">
               <div
        
        className="modal-header"
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <h4 className="modal-title">Upload History</h4>
        <button type="button" onClick={() => handleClose()}>
          &times;
        </button>
      </div>

               </DialogTitle>

      <DialogContent dividers={scroll === "paper"}>
 
        <MuiThemeProvider theme={getMuiTheme()}>
             <MUIDataTable columns={columns} data={result} options={options} title="" />
        </MuiThemeProvider>

        <div
          className="modal-footer"
          style={{ display: 'flex', justifyContent: 'end', margin: '20px', alignItems: 'center' }}
        >
          <button className="btn btn-danger" type="button" onClick={() => handleClose()}>
            Close
          </button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ResultSummaryDialog
