import React,{useState} from "react";
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import "./styles.scss";
import ListingStatus from "components/ListingStatus";
import PriceHistory from "components/PriceHistory";
import useAdminEvaluation from 'hooks/useAdminEvaluation';
import { IErrorResponse, ISuccessResponse } from "services/interfaces/common.interface";
import { showNotification, STATUS } from "common/constant";
import { ProductService } from '../../services/productService'
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import InputLabelDownWithDropDown from "components/InputlabeldownwithDropdown";
import ActionButton from "components/ActionButton";

const IReason = ['High MRP Price', 'High Wholesale Price', 'Incorrect seller Product Id'];



type sliderProps = {
    displayModal: boolean
    handleClose: () => void;
    productDetails: any

}

const ProductDetailsSlider: React.FC<sliderProps> = ({ handleClose, displayModal, productDetails }) => {
    const { isBusinessUser, isAdmin }= useAdminEvaluation();
    const [listingStatus, setlistingStatus] = useState<string>('')
    const productService: ProductService = new ProductService()
    const [RejectDialog, setRejectDialog] = useState<boolean>(false)
    const [rejectReason, setRejectReason] = useState<string>("");
    const [BlockDialog, setBlockDialog] = useState<boolean>(false)

    const statusProductHandler = async (status: string, pimcode: string,erp_code: string, reason?: string) => {
        try {
            if(!rejectReason.length && (status === 'Rejected' || status=== 'Blocked') ) {
                showNotification(STATUS.WARNING, 'Kindly select the reason');
        
                return
            }

            const approveProduct: ISuccessResponse | IErrorResponse = await productService.approveProduct(
              status,
              pimcode,
              erp_code,
              reason
            )

            if (approveProduct.status) {
                setlistingStatus(status)
              showNotification(STATUS.SUCCESS, `Product is ${status} successfully`)
            }
          } catch (error) {
            showNotification(STATUS.FAILURE, 'Unable to approve Product ')
          }
      }


      const statusChangeHandler = async (status: string, pimcode: string, erp_code: string): Promise<void> => {
        if (status === 'Rejected') {
          setRejectDialog(true)
        } else if (status === 'Blocked') {
          setBlockDialog(true)
        } else {
            statusProductHandler(status, pimcode, erp_code);
        }
      }

    return (
        <div className="App">
            <div className={`Modal ${displayModal ? "Show" : ""}`}>
                <div className="Close" >
                    <p className="header-title-con">Product Details</p>

                    <HighlightOffIcon onClick={() => {
                    handleClose()
                    setlistingStatus('')
                    }}/>
                </div>
                <div className="pimg-wrap">
                    <img className="product-img" src={productDetails?.image} alt="product"/>
                    <div className="pcontent-wrap" >
                        <p className="product-name">{productDetails?.name}</p>
                    </div>
                </div>
                <div className="product-details-tabular-view">
                    <div className="flex">
                        <p className="flex50 heading">Color</p>
                        <p className="flex50">{productDetails?.sku}</p>
                    </div>
                    <div className="flex">
                        <p className="flex50 heading">Poorvika Product Id</p>
                        <p className="flex50">{productDetails?.erp_item_code}</p>
                    </div>
                    <div className="flex">
                        <p className="flex50 heading"> Product Code</p>
                        <p className="flex50">{productDetails?.ext_prod_id}</p>
                    </div>
                    <div className="flex">
                        <p className="flex50 heading">MRP</p>
                        <p className="flex50">Rs. {productDetails?.mrp}</p>
                    </div>
                    <div className="flex">
                        <p className="flex50 heading">Whole Sale Price</p>
                        <p className="flex50">Rs. {productDetails?.wholesale_price}</p>
                    </div>
                    { (isAdmin || isBusinessUser) &&
                    <>
                        {displayModal && listingStatus === '' && setlistingStatus(productDetails?.listing_status)}
                        <div className="flex">
                        <p className="flex50 heading">Poorvika Price</p>
                        <p className="flex50">{productDetails?.sellPrice ? `Rs.  ${productDetails?.sellPrice}` :  'Not Available'}</p>
                    </div>
                       <div className="flex">
                       <p className="flex50 heading">Status</p>
                       <div className="flex50">
                           
                        
                       <ListingStatus
                     status={listingStatus}
                     pim_code={productDetails?.pim_code}
                     erp_code ={productDetails?.erp_item_code}
                     statusProductHandler={statusChangeHandler}
                   />
                       </div>
                       </div>

                       </>
                    }
                    
                 

                </div>
                <PriceHistory erpItemCode={productDetails?.erp_item_code}/>
            </div>
            <div
                className={`Overlay ${displayModal ? "Show" : ""}`}
            />
            <Dialog className="reject-products-dialog" open={RejectDialog}>
        <DialogTitle className="products-title" id="alert-dialog-title">
          Do you want to Reject this Product
        </DialogTitle>

        <InputLabelDownWithDropDown dropdown={IReason} name="Reason" text="Reason" onChange={(value) => setRejectReason(value as string)} />

        <DialogActions style={{ margin: '0 auto' }}>
          <ActionButton label="Cancel" type="button" varient="outlined" onClick={() => setRejectDialog(false)} />

          <ActionButton
            label="Add"
            type="submit"
            onClick={() => {
              setRejectDialog(false)
              statusProductHandler('Rejected', productDetails.pim_code,productDetails.erp_item_code, rejectReason)
            }}
          />
        </DialogActions>
      </Dialog>
      <Dialog className="reject-products-dialog" open={BlockDialog}>
        <DialogTitle className="products-title" id="alert-dialog-title">
          Do you want to Block this Product
        </DialogTitle>

        <InputLabelDownWithDropDown dropdown={IReason} name="Reason" text="Reason" onChange={(value) => setRejectReason(value as string)} />

        <DialogActions style={{ margin: '0 auto' }}>
          <ActionButton label="Cancel" type="button" varient="outlined" onClick={() => setBlockDialog(false)} />

          <ActionButton
            label="Add"
            type="submit"
            onClick={() => {
              setBlockDialog(false)
              statusProductHandler('Blocked', productDetails.pim_code,productDetails.erp_item_code, rejectReason)
            }}
          />
        </DialogActions>
      </Dialog>

        </div>
    );
}

export default ProductDetailsSlider
