import React from 'react'
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';

import './styles.scss'

type InputLabelInputProps = {
    label: string
    content?: string
    type?: string
    number?: string
    textarea?: boolean
    register?: any
    name?: string
    readonly?: boolean
    value?: string
    error?: boolean
    errorText?: string
    hintText?: string
    info?: string
    hint?: boolean
    maxLength?: number
    autocomplete?: string
    onChange? : (value: string) => void

} & React.InputHTMLAttributes<HTMLTextAreaElement>

const InputLabelwithDowninput: React.FC<InputLabelInputProps> = ({ label, onChange, textarea, type, value, readonly, register, name, errorText, error, hintText,info, hint,maxLength,autocomplete, ...props }) => {


    const onChangeHandler = async (event) => {
        if(onChange) onChange(event);
    }

    return (
        <div className="input-label-input-field">
            <p className="text-label">{label}</p>
           {info?  <p className='info-text'>{info}</p> : <></> }
            {textarea ?
                <textarea ref={register} name={name} className="text-area-content"  {...props} />

                :
                <>
                    <div className={props.disabled ? 'input-with-verified-icon-disabled' : 'input-with-verified-icon'}>
                        <input ref={register} name={name} type={type} onChange={(event) => onChangeHandler(event)} value={value} disabled={props.disabled} readOnly={readonly} maxLength={maxLength} autoComplete={autocomplete} className={props.disabled ? 'text-label-content-disabled text-label-content' :"text-label-content"} />
                        {props.disabled ? <></> : <CheckCircleOutlineRoundedIcon className="verified-icon" />}
                    </div>

                    {hint && !error ? <p className="hint-text"> {hintText}</p> : ''}
                    {error ? <p className="error-text">* {errorText}</p> : ''}
                </>


            }

        </div>
    )
}

export default InputLabelwithDowninput
