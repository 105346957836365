import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'

import ActionButton from 'components/ActionButton'

import './styles.scss'
import { OnboardingService } from 'services/onBoarding.service'
import { showNotification, STATUS } from 'common/constant'
import { IBankingInformations, IBillingAddress, IDocumentAttachments, IDocumentIds, ILegalInformations, IPrimaryContact, IProductInformation } from 'services/interfaces/onBoarding.interface'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
import { decrypt } from 'common/encryptor'
import PrintableConfirmation from 'pages/RootAdmin/onBoardingDetails/PrintableConfirmation'
// import { useReactToPrint } from 'react-to-print'

type TDetailsConfirmationPage = {
    nextStep: () => void,
    movePrevious: () => void
}


const DetailsConfirmationPage: React.FC<TDetailsConfirmationPage> = ({ movePrevious, nextStep }) => {
    const onboardingService = new OnboardingService();
    const [LegalInformation, setLegalInformation] = useState<ILegalInformations | null>(null);
    const [BillingInformation, setBillingInformation] = useState<IBankingInformations | null>(null);
    const [BillAddress, setBillAddress] = useState<IBillingAddress | null>(null)
    const [productinformation, setProductinformation] = useState<IProductInformation | null>(null);
    const [primaryContact, setprimaryContact] = useState<IPrimaryContact | null>(null)
    const [documentIds, setDocumentIds] = useState<IDocumentIds | null>(null);
    const [documentAttachments, setDocumentAttachments] = useState<IDocumentAttachments | null>(null)
    const [acceptingTerms, setAcceptingTerms] = useState<boolean>(false);
    const [PrintData, setPrintData] = useState<any>();
    const [alldetailsCheck, setAlldetailsCheck] = useState<boolean>(false);

    const printableRef = useRef<HTMLDivElement>(null);
    // const handlePrint = useReactToPrint({
    //   content: () => printableRef.current,
    // });

    const saveDetailsHandler = async () => {
        if (alldetailsCheck) {
            if (!acceptingTerms) {
                showNotification(STATUS.FAILURE, "Read & Accept terms and conditions")

                return
            }

            try {

                const saveStatus: ISuccessResponse | IErrorResponse = await onboardingService.saveOnBoardingData({ step: 'REVIEW_AND_COMPLETE' })

                if (saveStatus.status === STATUS.SUCCESS) {
                    nextStep();
                }

            } catch (error) {
                showNotification(STATUS.FAILURE, "Unable to save seller Details")
            }
        } else {
            showNotification(STATUS.FAILURE, 'Please fill all the required Fields')
        }
    }





    useEffect(() => {
        const getReviewData = async () => {
            const getPersonalInformations: ISuccessResponse | IErrorResponse = await onboardingService.getOnboardingData(5);

            if (getPersonalInformations.status === STATUS.SUCCESS) {
                const successResponse = getPersonalInformations as ISuccessResponse;
                setPrintData(successResponse)
                setLegalInformation(successResponse.data.LegalInformation)
                successResponse.data.BillingInformation.account_number = decrypt(successResponse.data.BillingInformation.account_number)
                setBillingInformation(successResponse.data.BillingInformation)
                setProductinformation(successResponse.data.product_info)
                setBillAddress(successResponse.data.BillAddress)
                setprimaryContact(successResponse.data.primaryContact)
                setDocumentIds(successResponse.data.TaxationInfo.ids)
                setDocumentAttachments(successResponse.data.TaxationInfo.documents)
                setAlldetailsCheck(successResponse.data.required.reviewsubmit)
            }
        }

        getReviewData();

    }, [])

    return (
        <div className="details-confirmation-page">

            <p className="form-subtitle">Legal Information</p>
            {_.map(LegalInformation, (value, prop) => {
                return <div className="name-and-details" key={prop}>
                    <p className="title-text">{prop.replaceAll('_', ' ')} :</p>
                    <p className="value-text">{value}</p>
                </div>
            })}
            <p className="form-subtitle">Banking Information</p>
            {_.map(BillingInformation, (value, prop) => {
                return <div className="name-and-details" key={prop}>
                    <p className="title-text">{prop.replaceAll('_', ' ')} :</p>
                    <p className="value-text">{value}</p>
                </div>
            })}
            <p className="form-subtitle">Billing Information</p>
            {_.map(BillAddress, (value, prop) => {
                return <div className="name-and-details" key={prop}>
                    <p className="title-text">{prop.replaceAll('_', ' ')} :</p>
                    <p className="value-text">{value}</p>
                </div>
            })}
            {(!!productinformation?.brandList && productinformation?.brandList.length > 0) || 
            (!!productinformation?.categoriesList && productinformation?.categoriesList.length > 0 )
            ? 
             <>
             <p className="form-subtitle">Product Information</p>
            {_.map(productinformation, (value, prop) => {
                return <div className="name-and-details" key={prop}>
                <p className="title-text">{prop.replaceAll('_', ' ')} :</p>
                <p className="value-text">
                  {  !!value && value.length > 0 ? 
                  <>
                {_.map(value, (childval, index) => {
                    if(index == "0"){
                        return childval["name"]
                    } else{
                        return ", "+childval["name"]
                    } 
                } )}</> : '-' }
                </p>
                </div>
            })} </>: <></> }
            <p className="form-subtitle">Primary Contact</p>
            {_.map(primaryContact, (value, prop) => {
                return <div className="name-and-details" key={prop}>
                    <p className="title-text">{prop.replaceAll('_', ' ')} :</p>
                    <p className="value-text">{value}</p>
                </div>
            })}
            <p className="form-subtitle">Taxation & Documents</p>
            {_.map(documentIds, (value, prop) => {
                return <div className="name-and-details" key={prop}>
                    <p className="title-text">{prop.replaceAll('_', ' ')} :</p>
                    <p className="value-text">{value}</p>
                </div>
            })}

            {_.map(documentAttachments, (value, prop) => {
                return <div className="name-and-details" key={prop}>
                    {prop === 'Signature' ? <></> : <p className="title-text">{prop} :</p>}
                    {value && prop !== 'Signature' ? <input
                        type="button"
                        className="btn-to-link"
                        onClick={() => window.open(value, '_blank')}
                        value="Click to View Document"
                    /> : <></>}

                </div>
            })}
            <div className="name-and-details" >
            <p className="title-text">Signature:</p>
            <img src={documentAttachments?.Signature} className="digital-sign-img" />
            </div>
            <div>
                <p className="agree-tnc"><input type="checkbox" onChange={(event) => setAcceptingTerms(event.target.checked)} /> I have Read and Accept Terms & Conditions</p>
            </div>

            <div>
                <ActionButton label="< Back" varient="outlined" onClick={() => movePrevious()} />
                <ActionButton label="Review & Submit" type="submit" onClick={() => saveDetailsHandler()} />
                {/* <ActionButton label="PRINT" onClick={handlePrint} /> */}
                <div className="print-form-wrapper">
                    <PrintableConfirmation
                        ref={printableRef}
                        form={PrintData}


                    />

                </div>


            </div>
        </div>
    )
}

export default DetailsConfirmationPage
