import { AxiosError } from "axios";
import { API_ENDPOINTS, ON_BORADING_STEPS, parseAxiosError, OMS_APP_URL_CLIENT_ID, OMS_APP_URL_CLIENT_SECRET, OMS_APP_URL_CLIENT_AUTH } from "common/constant";
import { BaseService } from "./base.service";
import { IErrorResponse, ISuccessResponse } from "./interfaces/common.interface";

export class OnboardingService extends BaseService {

    /* Pass email argument from the business user account to fetch the details  */
    public async getOnboardingData(step: number, email?: string): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.GET_ONBOARDING_DATA, { step: ON_BORADING_STEPS[step - 1], email: email ? email : localStorage.getItem('userMail') })
            if(ON_BORADING_STEPS[step - 1] === ON_BORADING_STEPS[0]){
                localStorage.setItem('seller_code', data.data.account_code)
                localStorage.setItem('seller_name', data.data.name)
                localStorage.setItem('sellerMail', data.data.email)
                localStorage.setItem('supplier_apx_code', data.data.supplier_id);
            }
            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async getOnUserData(step: number, email?: string): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.GET_USER_DATA, { step: ON_BORADING_STEPS[step - 1], email: email ? email : localStorage.getItem('userMail') })
            // localStorage.setItem('seller_code', data.data.code)

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }

    }

    public async getOTP(mobile: string): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const sellerData= localStorage.getItem("seller_code");
            const { data } = await this.httpClient.get(API_ENDPOINTS.SEND_OTP +`?mobile=${mobile}&sellerdata=${sellerData}`)

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async verifyOTP(mobile: string, otp: string, email?:string): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.get(API_ENDPOINTS.VERIFY_OTP + `?mobile=${mobile}&otp=${otp}&email=${email}`)

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async saveSellerInOMS(seller_data: any): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.CREATE_SELLER_IN_OMS, { ...seller_data },
                {
                    headers:{
                      "CF-Access-Client-Id": OMS_APP_URL_CLIENT_ID,
                      "CF-Access-Client-Secret": OMS_APP_URL_CLIENT_SECRET
                    }
                  })

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }


    public async saveOnBoardingData(personalInformations: any): Promise<ISuccessResponse | IErrorResponse> {
       
        console.log(personalInformations, 'code');
        
        const role: string | null = localStorage.getItem('role')
        const isAdmin: boolean =
          role === 'ROOT_ADMIN' ||
          role === 'PMPL_CATALOGUE_MANAGER' ||
          role === 'PMPL_OPERATIONS_MANAGER' ||
          role === 'PMPL_FINANCE_MANAGER'

        try {

            const headers = {
                'email': isAdmin ? localStorage.getItem('sellerMail') : localStorage.getItem('userMail')
            }

            const { data } = await this.httpClient.post(API_ENDPOINTS.SAVE_ONBOARDING_DATA, { ...personalInformations }, { headers })
            
            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async saveOnBoardingUserData(personalInformations: any): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.SAVE_USER_DATA, { ...personalInformations })

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }
    public async savePmplUserData(personalInformations: any): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.SAVE_PMPL_USER_DATA, { ...personalInformations })

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async getBankSuggestions(ifsc: string): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.get(API_ENDPOINTS.GET_IFSC_SUGGESTION + `?ifsc=${ifsc}`)

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async uploadDocument(fileData: FormData): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.UPLOAD_DOCUMENT_TO_CLOUD, fileData)

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async uploadCheckLeaf(filepath: string): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.CHEQUELEAF_PATH, {filepath, code : localStorage.getItem('seller_code')})

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async updateOnBoardingData(personalInformations: any): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.UPDATE_ONBOARDING_DATA, { ...personalInformations })

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async activateSeller(email): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.ACTIVATE_SELLER,  {email})

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    
    public async getHistorylog(email) {

        try {
            const { data } = await this.httpClient.post(API_ENDPOINTS.SELLER_HISTORY, { email })

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }



    public async getGstIndetails(gstNumber: string) {

        try {
            const { data } = await this.httpClient.get(API_ENDPOINTS.GST_INFORMATION + `?gstNumber=${gstNumber}`);

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async getLoginUserSellerDetails(login_user_email: string) {

        try {
            const { data } = await this.httpClient.get(API_ENDPOINTS.SELLER_BASIC_DETAILS + `?email=${login_user_email}`);

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError)
        }
    }

    public async addorcreateUserInOMS(UserData: any): Promise<ISuccessResponse | IErrorResponse> {
        try {
          const { data } = await this.httpClient.post(API_ENDPOINTS.CREATE_OMS_USER, {...UserData},
          {
            headers:{
              "CF-Access-Client-Id": OMS_APP_URL_CLIENT_ID,
              "CF-Access-Client-Secret": OMS_APP_URL_CLIENT_SECRET,
              "authorization":OMS_APP_URL_CLIENT_AUTH
            }
          } );
          return data;
        } catch (error) {
          return parseAxiosError(error as AxiosError)
        }
      }



}