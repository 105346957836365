import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
// import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import { useHistory, useLocation } from 'react-router-dom'
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import LayoutHeader from 'components/LayoutHeader'
import InputLabelinputWarehouse from 'components/InputLabelAndInputFieldforWarehouse'
import InputLabelwithContent from 'components/InputLabelwithContent'
import MapsContainer from 'components/MapContainer'
import { IWarehouseDetails } from 'services/interfaces/inventory.interface'
import InputLabelDownWithDropDown from 'components/InputlabeldownwithDropdown'
import WorkingDaysForm from 'containers/form/WorkingDaysForm'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import ActionButton from 'components/ActionButton'
import ActionButtonSm from 'components/ActionButtonSm'
import { InventoryService } from 'services/inventory.service'
import { showNotification, STATUS, ALL_DAYS } from 'common/constant'
import {
  IErrorResponse,
  ISuccessResponse,
  ISuccessResponses,
  IErrorResponses
} from 'services/interfaces/common.interface'
import { OnboardingService } from 'services/onBoarding.service'
import { OnPurchaseOrderService } from 'services/purchaseOrder.service'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import './styles.scss'
import { Istate } from 'services/interfaces/state.interface'
import { useSelector } from 'react-redux'
import { RootStore } from 'redux/store'
import Spinner from 'components/Spinner'
import { Autocomplete, FormLabel,TextField } from '@mui/material'
import _ from "lodash";
import useAdminEvaluation from 'hooks/useAdminEvaluation';

type AddLocationsprops = {
  onStatus: (data) => void;
}
type ILocationForm = {
  gst_number: string
  name: string
  code: string
  ext_warehouse_code: string
  location_type: string
  fulfilment_type: string
  tan_number: string
  pan_number: string
  cst_number: string
  vat_number: string
  address1: string
  address2: string
  address3: string
  city: string
  state: string
  pincode: string
  active_hours_and_days: string
  primary_contact_name: string
  primary_contact_email: string
  primary_contact_mobile: string
  escalations_contact_name: string
  escalations_contact_email: string
  escalations_contact_mobile: string

  location_code: string
  registered_address: string
  location_name: string
  plus_code: string
  location_link: string
}
const AddWarehouseValidation = {
  name: {
    required: { value: true, message: "Name can't be Empty" }
  },
  code: {
    required: { value: true, message: "Location Code can't be Empty" }
  },

  fulfilment_type: {
    required: { value: true, message: "Type can't be Empty" }
  },
  primary_contact_name: {
    required: { value: true, message: "Name field can't be Empty" }
  },
  primary_contact_email: {
    required: { value: true, message: "Email field can't be Empty" },
    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: '* Email format is invalid' }
  },
  primary_contact_mobile: {
    required: { value: true, message: "Mobile field can't be Empty" },
    minLength: { value: 10, message: ' Phone Number must be at least 10 digits' },
    maxLength: { value: 10, message: "Phone Number can't be more than 10 digits" }
  },
  escalations_contact_name: {
    required: { value: true, message: "Name field can't be Empty" }
  },
  escalations_contact_email: {
    required: { value: true, message: "Email field can't be Empty" },
    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: '* Email format is invalid' }
  },
  escalations_contact_mobile: {
    required: { value: true, message: "Mobile field can't be Empty" },
    minLength: { value: 10, message: ' Phone Number must be at least 10 digits' },
    maxLength: { value: 10, message: "Phone Number can't be more than 10 digits" }
  },
  gst_number: {
    required: { value: true, message: "GST field can't be Empty" },
    minLength: { value: 15, message: 'GST must be 15 characters long' },
    maxLength: { value: 15, message: 'GST must be 15 characters long' }
  },

  pincode: {
    required: { value: true, message: "Pincode can't be Empty" },
    minLength: { value: 6, message: 'pincode must be 6 characters long' },
    maxLength: { value: 6, message: 'pincode must be 6 characters long' }
  },
  address1: {
    required: { value: true, message: "Address1 can't be Empty" }
  },
  address2: {
    required: { value: true, message: "Address2  can't be Empty" }
  },
  address3: {
    required: { value: true, message: "Address3  can't be Empty" }
  },
  city: {
    required: { value: true, message: "City field can't be Empty" }
  },
  state: {
    required: { value: true, message: "State field can't be Empty" }
  }
}

const AddLocations: React.FC<AddLocationsprops> = ({ onStatus }) => {
  const { handleSubmit, register, errors, setValue, getValues, setError, reset } = useForm<ILocationForm>()
  const location = useLocation()
  const {isAdmin }= useAdminEvaluation();
  const history = useHistory<History>()
  const onBoardingService = new OnboardingService()
  const onboardingService = new OnboardingService()
  const isEdit = useSelector((state: RootStore) => state.inventory.editLocation)
  const [isSameGST, setIsSameGST] = useState<string>('No')
  /* State variables */
  const [lat, setLat] = useState<number>()
  const [lng, setLng] = useState<number>()
  const [sellerGST, setSellerGST] = useState<string>('')
  const [warehouseGST, setWarehouseGST] = useState<string>('')

  const [plusCode, setPlusCode] = useState<string>('')
  const [latlong, setlatlong] = useState<string>('')
  const [warehouseCode] = React.useState<string>('')
  const [workingdaysDialog, setWorkingdaysDialog] = React.useState(false)
  const [registerdAddress, setRegesiteredAddress] = React.useState<any>()
  const [workingdays, setWorkingdays] = React.useState<any>('')
  const [editWarehouseData, seteditWarehouseData] = React.useState<any>()
  const locationLink = 'https://www.google.com/maps/search/?api=1&query='
  const [locality, setLocality] = useState(null)
  const [datacheck, setDatacheck] = useState<boolean>(false)
  const [datahidden,setDatahidden] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [SupplierCode, setSupplierCode] = useState<string>('')
  const [isValidGST, setIsValidGST] = useState<boolean>(false)
  const [isGstCityState, setIsGstCityState] = useState<string[]>([])
  const [isGstFullAddress, setIsGstFullAddress] = useState<string[]>([])
  const [isGSTAddressPart, setIsGSTAddressPart] = useState<string[]>([])
  const [defaultcityStateValue, setDefaultcityStateValue] = useState<string>('')
  const [defaultgstaddress, setDefaultgstaddress] = useState<string>('')

  /* Services  */
  const getPurchaseOrderList: OnPurchaseOrderService = new OnPurchaseOrderService()

  /* Services */
  const inventoryService: InventoryService = new InventoryService()
  const onSubmit = async (data) => {
    /* location link availability check */
    if (!latlong) {
      showNotification(STATUS.FAILURE, 'Please Mark A Location on Map')
      return
    }
    /*Update Wareohouse API */
    if (editWarehouseData) {
      await gstInVerify(editWarehouseData.gst_number)
      if (!isValidGST) {
        showNotification(STATUS.FAILURE, 'Please provide Valid GST')
        return false
      }
      if (!!workingdays.selectedDays && workingdays.selectedDays.length == 0) {
        showNotification(STATUS.FAILURE, 'Please sellect working days.')
        return false
      }
      const pincode = registerdAddress ? registerdAddress.pincode : editWarehouseData.pincode
      if (!!pincode) {
        let firstNumber_of_pin = pincode.charAt(0)
        if (parseInt(firstNumber_of_pin) === 0) {
          showNotification(STATUS.FAILURE, 'Pincode should not start with zero.')
          return
        }
      }
      data.gst_number = editWarehouseData.gst_number
      data.code = editWarehouseData.code
      data.name = editWarehouseData.name
      data.address1 = registerdAddress ? registerdAddress.address1 : editWarehouseData.address1
      data.address2 = registerdAddress ? registerdAddress.address2 : editWarehouseData.address2
      data.address3 = registerdAddress ? registerdAddress.address3 : editWarehouseData.address3

      data.city = registerdAddress ? registerdAddress.city : editWarehouseData.city
      data.state = registerdAddress ? registerdAddress.state : editWarehouseData.state
      data.pincode = registerdAddress ? registerdAddress.pincode : editWarehouseData.pincode
      data.seller_code = SupplierCode
      data.seller_name = localStorage.getItem('seller_name')
      data.active_days = workingdays ? JSON.stringify(workingdays.selectedDays) : editWarehouseData.active_days
      // data.active_hours_and_days = workingdays.working_hours
      //   ? JSON.stringify(workingdays.working_hours)
      //   : editWarehouseData.active_hours_and_days
      const working_hours_array = workingdays.working_hours
      ? workingdays.working_hours
      : editWarehouseData.active_hours_and_days;
      let working_hours_obj= {};
      if (Array.isArray(working_hours_array)) {
        working_hours_array.forEach(dayObj => {
          const dayName = Object.keys(dayObj)[0]; // Get the day name (e.g., 'Monday')
          const dayData = dayObj[dayName]; // Get the time data for that day
        
          // Assign the time data to the result object
          working_hours_obj[dayName] = {
            start: dayData.start,
            end: dayData.end
          };
        });
        data.active_hours_and_days = JSON.stringify(working_hours_obj);
      } else{
        data.active_hours_and_days = JSON.stringify(working_hours_array);
      }
      data.plus_code = plusCode
      data.location_link = latlong ? locationLink + latlong : locationLink + editWarehouseData.latlng
      data.latlng = 'POINT(' + latlong.replace(',', ' ') + ')'
      data.description = editWarehouseData.description
      data.regional_zone = editWarehouseData.regional_zone
      data.is_ret_order_handled = !!editWarehouseData.is_ret_order_handled
      data.inv_profile = editWarehouseData.inv_profile
      data.geofencing_coordinates = 'POINT(' + latlong.replace(',', ' ') + ')'
      data.courier_fullfillment_method = editWarehouseData.courier_fullfillment_method
      data.attributes = editWarehouseData.attributes
      data.fulfillment_cut_off = editWarehouseData.fulfillment_cut_off
      data.channels = editWarehouseData.channels
      data.region = editWarehouseData.region
      data.rsm = editWarehouseData.rsm
      data.drsm = editWarehouseData.drsm
      data.arsm = editWarehouseData.arsm
      data.asm = editWarehouseData.asm
      data.manager = editWarehouseData.manager
      data.car_parking = editWarehouseData.car_parking
      data.bike_parking = editWarehouseData.bike_parking
      data.branch_img = editWarehouseData.branch_img
      data.row_added_by = editWarehouseData.row_added_by
      data.row_updated_by = editWarehouseData.row_updated_by
      data.pan_number = editWarehouseData.pan_number
      data.ext_warehouse_code = editWarehouseData.ext_warehouse_code
      data.country = 'IN'
      data.seller_status = editWarehouseData.seller_status
      data.live_demo_brands = JSON.stringify(editWarehouseData.live_demo_brands)
      data.live_scalabletax = JSON.stringify(editWarehouseData.live_scalabletax)
      data.zone = JSON.stringify(editWarehouseData.zone)
      data.config= JSON.stringify(editWarehouseData.config)
      data.docking_area=JSON.stringify(editWarehouseData.docking_area)
      const tmp_fill = [editWarehouseData.fulfilment_type];
      data.fulfilment_type = JSON.stringify(tmp_fill);
      try {
        setLoading(true)
        const updateWarehouse: ISuccessResponse | IErrorResponse = await inventoryService.updatewarehouse(data)
        setLoading(false)

        if (updateWarehouse.status === 'failure') {
          showNotification(STATUS.FAILURE, updateWarehouse.data)
        } else {
          if (!!data.ext_warehouse_code) {
          create_update_wh_in_oms(data)
          }
          showNotification(STATUS.SUCCESS, updateWarehouse.message)
          history.push('/sellerLayout/inventory/ActiveLocations')
        }
      } catch (error) {
        showNotification(STATUS.FAILURE, (error as Error)?.message)
      }
    } else {
      if (!isValidGST) {
        showNotification(STATUS.FAILURE, 'Please provide Valid GST')
        return false
      }
      if (!!workingdays.selectedDays && workingdays.selectedDays.length == 0) {
        showNotification(STATUS.FAILURE, 'Please sellect working days.')
        return false
      }
      const pincode = registerdAddress ? registerdAddress.pincode : ''
      if (!!pincode) {
        let firstNumber_of_pin = pincode.charAt(0)
        if (parseInt(firstNumber_of_pin) === 0) {
          showNotification(STATUS.FAILURE, 'Pincode should not start with zero.')
          return
        }
      }
      /*Add Wareohouse API */
      if (registerdAddress && isValidGST) {
        // console.log('registerdAddress', data.gst_number)
        data.code =
          'WH-' +
          data.name.replaceAll(' ', '_') +
          '-' +
          localStorage.getItem('seller_name')?.replaceAll(' ', '_') +
          '-' +
          registerdAddress.pincode
        data.seller_code = SupplierCode
        data.seller_name = localStorage.getItem('seller_name')
        data.address1 = registerdAddress.address1
        data.address2 = registerdAddress.address2
        data.address3 = registerdAddress.address3
        data.city = registerdAddress.city
        data.state = registerdAddress.state
        data.pincode = registerdAddress.pincode
        data.country = 'IN'
        // data.active_hours_and_days = workingdays.working_hours ? JSON.stringify(workingdays.working_hours) : ''
        const working_hours_array = workingdays.working_hours ? workingdays.working_hours : [];
        let working_hours_obj= {};
        working_hours_array.forEach(dayObj => {
          const dayName = Object.keys(dayObj)[0]; // Get the day name (e.g., 'Monday')
          const dayData = dayObj[dayName]; // Get the time data for that day
        
          // Assign the time data to the result object
          working_hours_obj[dayName] = {
            start: dayData.start,
            end: dayData.end
          };
        });
        data.active_hours_and_days = JSON.stringify(working_hours_obj);
        data.active_days = workingdays.selectedDays ? JSON.stringify(workingdays.selectedDays) : ''
        data.plus_code = plusCode
        data.latlng = 'POINT(' + latlong.replace(',', ' ') + ')'
        data.location_link = locationLink + latlong
        data.description = 'NA'
        data.regional_zone = 'NA'
        data.ext_warehouse_code = ''
        data.is_ret_order_handled = false
        data.inv_profile = 'External Seller'
        data.geofencing_coordinates = 'POINT(' + latlong.replace(',', ' ') + ')'
        data.courier_fullfillment_method = '["Regular"]'
        data.attributes = '{"Regular":{"start":"00:00:00","end":"23:59:00"}}'
        data.fulfillment_cut_off = ''
        data.channels = '{}'
        data.region = 'NA'
        data.rsm = 'NA'
        data.drsm = 'NA'
        data.arsm = 'NA'
        data.asm = 'NA'
        data.manager = 'NA'
        data.car_parking = 0
        data.bike_parking = 0
        data.branch_img = ''
        data.row_added_by = 'Seller Portal'
        data.row_updated_by = 'Seller Portal'
        data.pan_number = 'NA'
        data.seller_status = 'Pending'
        data.zone = "[]"
        data.config= "[]"
        data.docking_area="[]"
        const tmp_fill = [data.fulfilment_type];
        data.fulfilment_type = JSON.stringify(tmp_fill);

        // console.log(data);

        try {
          setLoading(true)
          const addWarehouse: ISuccessResponse | IErrorResponse = await inventoryService.addwarehouse(data)
          setLoading(false)

          if (
            addWarehouse.status === 'failure' ||
            addWarehouse.status === 'error' ||
            addWarehouse.status === 'failed'
          ) {
            showNotification(STATUS.FAILURE, addWarehouse.message)
          } else {
            //create_update_wh_in_oms(data)
            showNotification(STATUS.SUCCESS, addWarehouse.message)
            history.push('/sellerLayout/inventory/PendingLocations')
          }
        } catch (error) {
          showNotification(STATUS.FAILURE, (error as Error)?.message)
        }
      } else {
        showNotification(STATUS.FAILURE, 'Please Enter the Registered Address')
      }
    }
  }


  const create_update_wh_in_oms = async (data) => {
    try {
      const warehouse_data = {
        apob_code: data.ext_warehouse_code,
        apob_name: data.name,
        virtual_branch_code: data.code,
        virtual_branch_name: data.name,
        address_line1: data.address1,
        address_line2: data.address2,
        city: data.city,
        state: data.state,
        contact_no: data.primary_contact_mobile,
        email: data.primary_contact_email,
        pincode: data.pincode
      }
      const addWarehouse: ISuccessResponse | IErrorResponse = await inventoryService.addupdatewarehouseInOMS(
        warehouse_data
      )
      if (addWarehouse.status === STATUS.SUCCESS) {
        showNotification(STATUS.SUCCESS, addWarehouse.message)
      } else {
        showNotification(STATUS.FAILURE, addWarehouse.message)
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to update warehouse details')
    }
  }

  // const create_update_wh_in_oms = async (data) => {
  //   try {
  //     const warehouse_data = {
  //       apob_code: data.ext_warehouse_code,
  //       apob_name: data.name,
  //       virtual_branch_code: data.code,
  //       virtual_branch_name: data.name,
  //       address_line1: data.address1,
  //       address_line2: data.address2,
  //       city: data.city,
  //       state: data.state,
  //       contact_no: data.primary_contact_mobile,
  //       email: data.primary_contact_email,
  //       pincode: data.pincode
  //     }
  //     const addWarehouse: ISuccessResponse | IErrorResponse = await inventoryService.addupdatewarehouseInOMS(
  //       warehouse_data
  //     )
  //     if (addWarehouse.status === STATUS.SUCCESS) {
  //       showNotification(STATUS.SUCCESS, addWarehouse.message)
  //     } else {
  //       showNotification(STATUS.FAILURE, addWarehouse.message)
  //     }
  //   } catch (error) {
  //     showNotification(STATUS.FAILURE, 'Unable to update warehouse details')
  //   }
  // }

  const getSupplierCode = async () => {
    try {
      const seller_code = localStorage.getItem('seller_code')
      const getPurchaseOrderSupplierCode:
        | ISuccessResponses
        | IErrorResponses = await getPurchaseOrderList.getPurchaseOrderSuppliercode(seller_code)

      if (getPurchaseOrderSupplierCode.StatusMessage === STATUS.SUCCESS) {
        const SupplierCode = getPurchaseOrderSupplierCode as ISuccessResponses
        setSupplierCode(SupplierCode.Data.accountsdetails[0].external.supplier_id)
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Seller Code')
    }
  }

  useEffect(() => {
    const supplier_code = localStorage.getItem('supplier_apx_code')
    if (!!supplier_code) {
      setSupplierCode(supplier_code)
    } else {
      getSupplierCode()
    }
    const registered_Address_data = {
      address1: '',
      address2: '',
      address3: '',
      city: '',
      state: '',
      pincode: ''
    }
    setRegesiteredAddress(registered_Address_data)
    onStatus("")
  }, [])

  // useEffect(() => {
  //   defaultcityStateValue
  // })

  // const handleReset = (value: string) => {
  //   if (value === 'No') {
  //     setIsSameGST('No')
  //     setDatacheck(false)
  //     reset()
  //   } else if (value === 'Yes') {
  //     setIsSameGST('Yes')
  //     setDatacheck(true)
  //     getTaxationInfo()
  //   }
  // }

  const gstInVerify = async (gstNumber: string) => {
    const filteredGSTNumber = gstNumber?.replace(/[^a-z0-9]/gi, '').substring(0, 15)
    setDefaultcityStateValue('');
    setIsGSTAddressPart([])
    dropdown_gstAddress('')
    if (gstNumber.length === 15) {
      const regexPattern = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/

      if (!regexPattern.test(filteredGSTNumber)) {
        setError('gst_number', {
          type: 'manual',
          message: 'GST Number format is invalid'
        })

        showNotification('failure', 'GST Number Format is invalid')
        setIsValidGST(false);
        return
      }

      try {
        setLoading(true)
        const { data }: ISuccessResponse | IErrorResponse = await onBoardingService.getGstIndetails(gstNumber)
        setLoading(false)
        if (!data.error) {
          const accountInformations = data.data
          const gstAddressInArray = accountInformations.adadr
          let addressData = gstAddressInArray.length == 0 ? accountInformations.pradr : gstAddressInArray
          let gstAddress: any[] = []
          if (addressData.length > 0) {
            gstAddress = addressData;
          } else {
            gstAddress.push(addressData);
          }
          const cityState = [...new Set(gstAddress.map((citylist) => `${citylist.addr.dst} - ${citylist.addr.stcd}`))]
          setIsGstCityState(cityState)
          setIsGstFullAddress(gstAddress)
          setIsValidGST(true)
        } else {
          setIsValidGST(false)
        }
      } catch (error) {
        setIsValidGST(false)
        showNotification(STATUS.FAILURE, 'Unable to get GST Informations')
      }
    } else {
      setValue('address1', '')
      setValue('address2', '')
      setValue('pincode', '')
      setValue('city', '')
      setValue('state', '')
      setIsValidGST(false)
    }
  }

  const workingDaysHandler = async (data) => {
    setWorkingdays(data)
  }

  useEffect(() => {
    if (!!isEdit) {
      setLoading(true)
      setDatacheck(true)
      setDatahidden(true)
      getTaxationInfoOfSeller()
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (datacheck) {
      setValue('gst_number', editWarehouseData?.gst_number)
      if (!!editWarehouseData?.gst_number) gstInVerify(editWarehouseData?.gst_number)
      setValue('vat_number', editWarehouseData?.vat_number)
      setValue('cst_number', editWarehouseData?.cst_number)
      setValue('tan_number', editWarehouseData?.tan_number)
      try{
        const temp_full = JSON.parse(editWarehouseData?.fulfilment_type);
        if(temp_full.length){
          setValue('fulfilment_type',temp_full[0]);
        }
      }catch(error){
        setValue('fulfilment_type', editWarehouseData?.fulfilment_type)
      }
      setValue('location_type', editWarehouseData?.location_type)
      setValue('name', editWarehouseData?.name)
      setValue('ext_warehouse_code', editWarehouseData?.ext_warehouse_code)
      setValue('primary_contact_name', editWarehouseData?.primary_contact_name)
      setValue('primary_contact_email', editWarehouseData?.primary_contact_email)
      setValue('primary_contact_mobile', editWarehouseData?.primary_contact_mobile)
      setValue('escalations_contact_name', editWarehouseData?.escalations_contact_name)
      setValue('escalations_contact_email', editWarehouseData?.escalations_contact_email)
      setValue('escalations_contact_mobile', editWarehouseData?.escalations_contact_mobile)
      setValue('address1', editWarehouseData?.address1)
      setValue('address2', editWarehouseData?.address2)
      setValue('address3', editWarehouseData?.address3)
      setValue('pincode', editWarehouseData?.pincode)
      setValue('city', editWarehouseData?.city)
      setValue('state', editWarehouseData?.state)
      const addr = {
        address1: editWarehouseData?.address1,
        address2: editWarehouseData?.address2,
        address3: editWarehouseData?.address3,
        city: editWarehouseData?.city,
        state: editWarehouseData?.state,
        pincode: editWarehouseData?.pincode
      }
      setRegesiteredAddress(addr)
      seteditWarehouseData(location.state)
      setWarehouseGST(editWarehouseData?.gst_number)
      // if (!!editWarehouseData?.active_days && !!editWarehouseData?.active_hours_and_days) {
      //   let workingLocal = {}
      //   const list_of_days_of_week = ALL_DAYS
      //   workingLocal['selectedDays'] = JSON.parse(editWarehouseData?.active_days)
      //   workingLocal['working_hours'] = JSON.parse(editWarehouseData?.active_hours_and_days)

      //   if(workingLocal['selectedDays'].length > 0 && !_.isEmpty(workingLocal['working_hours'])){
      //     for (const loopdays of list_of_days_of_week) {
      //       let findIdx = workingLocal['working_hours'].findIndex((weekdata, idx) => weekdata[loopdays] !== undefined)
      //       if (workingLocal['selectedDays'].includes(loopdays) && findIdx !== -1) {
      //         workingLocal[loopdays + 'start'] =
      //           'Mon Aug 18 2014 ' +
      //           workingLocal['working_hours'][findIdx][loopdays]['start'] +
      //           ' GMT+0530 (India Standard Time)'
      //         workingLocal[loopdays + 'end'] =
      //           'Mon Aug 18 2014 ' +
      //           workingLocal['working_hours'][findIdx][loopdays]['end'] +
      //           ' GMT+0530 (India Standard Time)'
      //       } else {
      //         workingLocal[loopdays + 'start'] = 'Mon Aug 18 2014 08:00:54 GMT+0530 (India Standard Time)'
      //         workingLocal[loopdays + 'end'] = 'Mon Aug 18 2014 20:00:00 GMT+0530 (India Standard Time)'
      //       }
      //     }
      //     setWorkingdays(workingLocal)
      //   }
      // }
      if (!!editWarehouseData?.active_days && !!editWarehouseData?.active_hours_and_days) {
        let workingLocal = {};
        const list_of_days_of_week = ALL_DAYS;
      
        // Parse the selectedDays and working_hours from the editWarehouseData
        workingLocal['selectedDays'] = JSON.parse(editWarehouseData?.active_days);
        workingLocal['working_hours'] = JSON.parse(editWarehouseData?.active_hours_and_days);
      
        if (workingLocal['selectedDays'].length > 0 && !_.isEmpty(workingLocal['working_hours'])) {
          for (const loopdays of list_of_days_of_week) {
            if (workingLocal['selectedDays'].includes(loopdays) && workingLocal['working_hours'][loopdays]) {
              const currentDate = new Date().toDateString(); // Get the current date as a string
      
              workingLocal[loopdays + 'start'] = `${currentDate} ${workingLocal['working_hours'][loopdays]['start']} GMT+0530 (India Standard Time)`;
              workingLocal[loopdays + 'end'] = `${currentDate} ${workingLocal['working_hours'][loopdays]['end']} GMT+0530 (India Standard Time)`;
            } else {
              const currentDate = new Date().toDateString(); // Get the current date as a string
      
              workingLocal[loopdays + 'start'] = `${currentDate} 08:00:54 GMT+0530 (India Standard Time)`;
              workingLocal[loopdays + 'end'] = `${currentDate} 20:00:00 GMT+0530 (India Standard Time)`;
            }
          }
          setWorkingdays(workingLocal);
        }
      }
    }
  }, [location, editWarehouseData, datacheck])
  useEffect(() => {
    if (!!sellerGST && !!warehouseGST) {
      if (sellerGST === warehouseGST) {
        setIsSameGST("Yes")
      } else {
        setIsSameGST('No')
      }
    }
  }, [sellerGST, warehouseGST])

  useEffect(() => {
    // const str: string = editWarehouseData?.latlng?.substring(1, editWarehouseData?.latlng?.length - 1)

    // if (str !== undefined) {
    // const lat_lng = str.split(',')
    setLat(Number(editWarehouseData?.latlng['x']))
    setLng(Number(editWarehouseData?.latlng['y']))
    // }
  }, [editWarehouseData])

  // useEffect(() => {
  //   setValue()
  // },[isGstCityState])

  const locationSelectionHandler = async (loactionAddress: IWarehouseDetails) => {
    if (loactionAddress.latlng !== '20.5937,78.9629') {
      const pluscode = loactionAddress.plusCode
      setPlusCode(pluscode.compound_code)
      setlatlong(loactionAddress.latlng)
    }
  }

  const setAddressLocation = (val) => {
    console.log(val)

    setLocality(val.label)
    setPlusCode(val.label)

    geocodeByAddress(val?.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        const latlng = `${lat}, ${lng}`
        setlatlong(latlng)
        setLat(lat)
        setLng(lng)
      })
  }
  const getTaxationInfoOfSeller = async () => {
    try {
      const email = localStorage.getItem('sellerMail') as string
      setLoading(true)
      const getTaxationResponse: ISuccessResponse | IErrorResponse = await onboardingService.getOnboardingData(4, email)
      setLoading(true)
      if (getTaxationResponse.status === STATUS.SUCCESS) {
        const successData = getTaxationResponse as ISuccessResponse
        setSellerGST(successData.data.gstin)
        setLoading(false)
      } else {
        showNotification(STATUS.FAILURE, 'Unable to get Taxation informations')
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Taxation informations')
    }
  }

  const getTaxationInfo = async () => {
    try {
      const email = localStorage.getItem('sellerMail') as string
      setLoading(true)
      const getTaxationResponse: ISuccessResponse | IErrorResponse = await onboardingService.getOnboardingData(4, email)
      setLoading(true)

      if (getTaxationResponse.status === STATUS.SUCCESS) {
        const successData = getTaxationResponse as ISuccessResponse
        setValue('gst_number', successData.data.gstin)
        gstInVerify(successData.data.gstin)
      } else {
        showNotification(STATUS.FAILURE, 'Unable to get Taxation informations')
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Taxation informations')
    }
  }

  const updateAddressDetail = (val, type_fields) => {
    setValue(type_fields, val)
    registerdAddress[type_fields] = val
    setRegesiteredAddress(registerdAddress)
  }

  const updateAddressDetailPicode = (val, type_fields) => {
    val = val.replace(/[^0-9]/g, '')
    setValue(type_fields, val)
    registerdAddress[type_fields] = val
    setRegesiteredAddress(registerdAddress)
  }
  //GST Address Data Auto Population
  const citystate_dropdown = (event,gst_address) => {
    if(!!gst_address){
      if(defaultcityStateValue == gst_address){
          const splitGstValue = gst_address.split('-')
          const addrResult = isGstFullAddress.filter((obj: any) => {
            return obj.addr.dst == splitGstValue[0].trim()
          });
          const mergeAddress = addrResult.map((list: any) => {
            return `${list.addr.bno}${list.addr.flno},${list.addr.st},${list.addr.dst},${list.addr.stcd},${list.addr.pncd}`
          });
          setIsGSTAddressPart(mergeAddress)
      } else {
        setDefaultcityStateValue(gst_address)
        const splitGstValue = gst_address.split('-')
          const addrResult = isGstFullAddress.filter((obj: any) => {
            return obj.addr.dst == splitGstValue[0].trim()
          });
          const mergeAddress = addrResult.map((list: any) => {
            return `${list.addr.bno}${list.addr.flno},${list.addr.st},${list.addr.dst},${list.addr.stcd},${list.addr.pncd}`
          });
          setIsGSTAddressPart(mergeAddress)
          dropdown_gstAddress('')
        }
    } else{
      dropdown_gstAddress('');
    }
  }
  const dropdown_gstAddress = (val) => {
    if(!!val){
      let addrSplitValue = val.split(',');
      const city = addrSplitValue[addrSplitValue.length - 3]
      const state = addrSplitValue[addrSplitValue.length - 2]
      const pincode = addrSplitValue[addrSplitValue.length - 1]
      setValue('address1', `${addrSplitValue[0]},${addrSplitValue[1]}`)
      setValue('address2', addrSplitValue[2])
      setValue('address3', '')
      setValue('city', city)
      setValue('state', state)
      setValue('pincode', pincode)
      const registered_Address_data = {
        address1: addrSplitValue[0] + ', ' + addrSplitValue[1],
        address2: addrSplitValue[2],
        address3: '',
        city: city,
        state: state,
        pincode: pincode
      }
      setDefaultgstaddress(val)
      setRegesiteredAddress(registered_Address_data);
    } else{
      setDefaultgstaddress('')
      setValue('address1', '')
      setValue('address2', '')
      setValue('address3', '')
      setValue('city', '')
      setValue('state', '')
      setValue('pincode', '')
      const registered_Address_data = {
        address1: '',
        address2: '',
        address3: '',
        city: '',
        state: '',
        pincode: ''
      }
      setRegesiteredAddress(registered_Address_data);
    }
  }

  return (
    <div className="Addlocations-page">
      <form className="location-layout-wrapper" onSubmit={handleSubmit(onSubmit)}>
        <Spinner loading={loading}/>
        <div className="location-core-details">
          <LayoutHeader title="Location Core Details" key="core_location" />

          <div className="legal-details-content">
            {/* <div className="locationDetails">
              <p className="location-type">Is this location GST Number Same as legal GST Number ? * </p>
              <FormControl className="radio-btn-wrapper">
                <RadioGroup className="radio-group" defaultValue={isSameGST} value={isSameGST} name="legal-gst-confirmation" onChange={(event) => handleReset(event.target.value)}>
                  <FormControlLabel value="Yes" control={<Radio/>} label="Yes" />
                  <FormControlLabel value="No" control={<Radio/>} label="No" />
                </RadioGroup>
              </FormControl>
              </div> */}
            <InputLabelinputWarehouse
              label="GST Number * "
              name="gst_number"
              maxLength={15}
              onChange={(event) => gstInVerify(event.target.value)}
              register={register(AddWarehouseValidation.gst_number)}
              error={errors.gst_number?.type !== undefined}
              errorText={errors.gst_number ? errors.gst_number.message : ''}
              disabled={datacheck}
            />
            {!isValidGST ? (
              ''
            ) :
              datahidden ? '' : (
              <div className="gst-dropdown divCityState">
                <FormControl>
                <FormLabel>City & State</FormLabel>
                <Autocomplete
                  placeholder="City & State"
                  id="combo-box-demo"
                  options={isGstCityState || []}
                  sx={{
                    width: 305,
                    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid green'
                    }
                  }}
                   inputValue={defaultcityStateValue}
                   value={defaultcityStateValue}
                  onInputChange={(event, val) => citystate_dropdown(event,val)}
                  renderInput={(params) => <TextField {...params} size="small"/>}
                />
              </FormControl>
              </div>

            )}

            {!defaultcityStateValue ? (
              ''
            ) : datahidden ? '' : (
              <div className="filter-item  gst-dropdown divGstAddress">
                <FormControl>
                <FormLabel>GST Address</FormLabel>
                <Autocomplete
                  id="combo-box-demo"
                  options={isGSTAddressPart || []}
                  sx={{
                    width: 305,
                    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid green',
                    }

                  }}
                  inputValue={defaultgstaddress}
                  value={defaultgstaddress}
                  onInputChange={(e, val) => dropdown_gstAddress(val)}
                  renderInput={(params) => <TextField value={defaultcityStateValue} {...params} size="small"  />}
                />
               </FormControl>
              </div>
            )}


            <InputLabelinputWarehouse
              label="Warehouse Name *"
              name="name"
              register={register(AddWarehouseValidation.name)}
              error={errors.name?.type !== undefined}
              errorText={errors.name ? errors.name.message : ''}
              tooltip="Warehouse_name"
              disabled={!!isEdit}
            />

            {
              !!isAdmin && !!isEdit?
              <InputLabelinputWarehouse
              label="ERP Warehouse *"
              name="ext_warehouse_code"
              register={register}
              disabled={!!isEdit}
            /> :<> </>

            }


            <InputLabelDownWithDropDown
              text="Location Type"
              dropdown={['Warehouse', 'Store']}
              name="location_type"
              register={register}
              notrequired
            />
            <InputLabelDownWithDropDown
              text="Fulfilment Type "
              dropdown={['Fulfilled by Poorvika', 'Fulfilled by Seller']}
              name="fulfilment_type"
              register={register(AddWarehouseValidation.fulfilment_type)}
              error={errors.fulfilment_type?.type !== undefined}
              errorText={errors.fulfilment_type ? errors.fulfilment_type.message : ''}
            />
            <InputLabelinputWarehouse label="TAN Number" name="tan_number" register={register} />
            <InputLabelinputWarehouse label="CST Number" name="cst_number" register={register} />
            <InputLabelinputWarehouse label="VAT Number" name="vat_number" register={register} />
          </div>
        </div>
        <div className="address-and-operating-hours">
          <LayoutHeader title="Address & Operating Hours" key="address_location" />
          <div className="address-content-container">
            {/* <div className="locationDetails">
              <p className="location-type">Is the address same as Legal Address ? * </p>
              <FormControl className="radio-btn-wrapper">
                <RadioGroup className="radio-group" defaultValue="No" name="legal-gst-confirmation">
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div> */}

            <InputLabelinputWarehouse
              label="Address 1*"
              name="address1"
              maxLength={60}
              onChange={(event) => updateAddressDetail(event.target.value, 'address1')}
              register={register(AddWarehouseValidation.address1)}
              error={errors.address1?.type !== undefined}
              errorText={errors.address1 ? errors.address1.message : ''}
              disabled={datacheck}
            />
            <InputLabelinputWarehouse
              label="Address 2"
              name="address2"
              onChange={(event) => updateAddressDetail(event.target.value, 'address2')}
              maxLength={60}
              register={register}
              disabled={datacheck}
            />
            <InputLabelinputWarehouse
              label="Address 3"
              name="address3"
              onChange={(event) => updateAddressDetail(event.target.value, 'address3')}
              register={register}
              maxLength={60}
              disabled={datacheck}
            />
            <div className="pincode-wrapper">
              <InputLabelinputWarehouse
                label="Pincode*"
                name="pincode"
                register={register(AddWarehouseValidation.pincode)}
                error={errors.pincode?.type !== undefined}
                errorText={errors.pincode ? errors.pincode.message : ''}
                onChange={(event) => updateAddressDetailPicode(event.target.value, 'pincode')}
                // onChange={(event) => {  event.target.value = event.target.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');  setValue("pincode", event.target.value ) }}
                maxLength={6}
                disabled={datacheck}
              />
            </div>
            <div className="city-state-wrapper">
              <InputLabelinputWarehouse
                label="City *"
                name="city"
                register={register(AddWarehouseValidation.city)}
                onChange={(event) => updateAddressDetail(event.target.value, 'city')}
                error={errors.city?.type !== undefined}
                errorText={errors.city ? errors.city.message : ''}
                disabled={datacheck}
              />
            </div>
            <div className="state-wrapper">
              <InputLabelDownWithDropDown
                text="State"
                dropdown={Istate}
                name="state"
                onChange={(value) => updateAddressDetail(value, 'state')}
                register={register(AddWarehouseValidation.state)}
                error={errors.state?.type !== undefined}
                errorText={errors.state ? errors.state.message : ''}
                disabled={datacheck}
              />
            </div>
            <div className="working-days-content">
              <p className="text-label">Working Hours & Days*</p>
              {workingdays ? (
                <div className="input-with-verified-icon">
                  {workingdays ? (
                    <div className="days-wrapper">
                      {workingdays.selectedDays.map((data: string, index) => (
                        <p key={'P_' + index}>
                          {data} {index !== workingdays.selectedDays.length - 1 ? <span>,&nbsp;</span> : <>.</>}
                        </p>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}
                  <p className="text-label-content">
                    {workingdays.opentime ? workingdays.opentime + ' to ' + workingdays.closetime : ''}
                  </p>
                  <EditOutlinedIcon className="verified-icon" onClick={() => setWorkingdaysDialog(true)} />
                </div>
              ) : (
                <div className="input-with-verified-icon">
                  <p>{editWarehouseData ? editWarehouseData.active_days : ''}</p>
                  <EditOutlinedIcon className="verified-icon" onClick={() => setWorkingdaysDialog(true)} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="warehouse-contacts-wrapper">
          <div>
            <LayoutHeader title="Contacts" key="Contacts_location" />
            <div className="legal-details-content">
              <p className="contacts-label">Primary Contact :</p>
              <InputLabelinputWarehouse
                label="Name *"
                name="primary_contact_name"
                register={register(AddWarehouseValidation.primary_contact_name)}
                error={errors.primary_contact_name?.type !== undefined}
                errorText={errors.primary_contact_name ? errors.primary_contact_name.message : ''}
              />
              <InputLabelinputWarehouse
                label="Email *"
                name="primary_contact_email"
                register={register(AddWarehouseValidation.primary_contact_email)}
                error={errors.primary_contact_email?.type !== undefined}
                errorText={errors.primary_contact_email ? errors.primary_contact_email.message : ''}
              />
              <InputLabelinputWarehouse
                label="Mobile * ( ex: 98******** )"
                name="primary_contact_mobile"
                maxLength={10}
                onChange={(event) =>
                  (event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'))
                }
                register={register(AddWarehouseValidation.primary_contact_mobile)}
                error={errors.primary_contact_mobile?.type !== undefined}
                errorText={errors.primary_contact_mobile ? errors.primary_contact_mobile.message : ''}
              />
              <p className="contacts-label">Escalations :</p>

              <InputLabelinputWarehouse
                label="Name *"
                name="escalations_contact_name"
                register={register(AddWarehouseValidation.escalations_contact_name)}
                error={errors.escalations_contact_name?.type !== undefined}
                errorText={errors.escalations_contact_name ? errors.escalations_contact_name.message : ''}
              />
              <InputLabelinputWarehouse
                label="Email *"
                name="escalations_contact_email"
                register={register(AddWarehouseValidation.escalations_contact_email)}
                error={errors.escalations_contact_email?.type !== undefined}
                errorText={errors.escalations_contact_email ? errors.escalations_contact_email.message : ''}
              />
              <InputLabelinputWarehouse
                label="Mobile * ( ex: 98******** )"
                name="escalations_contact_mobile"
                maxLength={10}
                onChange={(event) =>
                  (event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'))
                }
                register={register(AddWarehouseValidation.escalations_contact_mobile)}
                error={errors.escalations_contact_mobile?.type !== undefined}
                errorText={errors.escalations_contact_mobile ? errors.escalations_contact_mobile.message : ''}
              />
            </div>
          </div>
        </div>
        <div className="warehouse-contacts-wrapper">
          <div>
            <LayoutHeader title="Geo Location" key="geo_location" />
            <div className="legal-details-content">
              <div style={{ paddingTop: '10px' }}>
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyD-6hZF4mxTyZXc-AqgJzkaxyGyfwCFBM0"
                  apiOptions={{ region: 'in' }}
                  autocompletionRequest={{
                    bounds: [
                      { lat: 50, lng: 50 },
                      { lat: 100, lng: 100 }
                    ],
                    componentRestrictions: {
                      country: ['in']
                    }
                  }}
                  selectProps={{
                    placeholder: 'Search Pincode or Places',
                    locality,
                    onChange: setAddressLocation
                  }}
                />
              </div>

              <InputLabelwithContent
                label="Plus Code"
                content={editWarehouseData ? editWarehouseData?.plus_code : plusCode}
              />
              <InputLabelwithContent
                label="Location Link"
                content={editWarehouseData ? editWarehouseData.location_link : latlong ? locationLink + latlong : ''}
              />
            </div>
          </div>
          <div>
            <MapsContainer
              lat={lat}
              lng={lng}
              onLocationSelected={(data: IWarehouseDetails) => locationSelectionHandler(data)}
            />
          </div>
          <div className="addwarehouse-submit-btn">
            <ActionButtonSm label="Cancel" type="button" onClick={() =>  history.push('/sellerLayout/inventory/ActiveLocations')}/>
            <ActionButton label="Submit" type="submit" />
          </div>
        </div>
      </form>

      <WorkingDaysForm
        open={workingdaysDialog}
        title="Add Warehouse"
        cancelClose={() => setWorkingdaysDialog(false)}
        onDaysSelected={(e) => workingDaysHandler(e)}
        Defaultdays={workingdays}
      />
    </div>
  )
}
export default AddLocations
