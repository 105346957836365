import React, { useEffect, useState } from 'react'
import Menu from '@mui/material/Menu'
import { useSelector } from 'react-redux'
import { Route, useHistory } from 'react-router-dom'
import DispatchOrder from 'pages/DispatchOrder'


import PoorvikaSmLogo from '../../assets/images/poorvika-sm-logo.png'
import PoorvikaLogo from '../../assets/images/poorvika-Logo.svg'
import profileIcon from '../../assets/icons/sidemenu/profile.svg'
import productList from '../../assets/icons/sidemenu/product-list.svg'
import Store from '../../assets/icons/sidemenu/store.svg'
import Orders from '../../assets/icons/sidemenu/order.svg'
import Users from '../../assets/icons/sidemenu/users.svg'
import Accounts from '../../assets/icons/sidemenu/accounts.svg'
import AccountsColor from '../../assets/icons/sidemenu/accounts-color.svg'
import PurchaseOrder from '../../assets/icons/sidemenu/po.svg'
import profileColorIcon from '../../assets/icons/sidemenu/profile-color.svg'
import productListColorIcon from '../../assets/icons/sidemenu/product-list-color.svg'
import StoreColorIcon from '../../assets/icons/sidemenu/store-color.svg'
import OrdersColorIcon from '../../assets/icons/sidemenu/order-color.svg'
import UsersColorIcon from '../../assets/icons/sidemenu/users-color.svg'
import PoColorIcon from '../../assets/icons/sidemenu/po-color.svg'
import BellIcon from '../../assets/icons/sidemenu/bell-icon.svg'
import SideMenuItems from '../../components/sidemenuItems'
import {
  dashboardPath,
  isVisible,
  LIST_OF_ROLES,
  productListSubPaths,
  profileDetailsSubPaths,
  settingsSubpaths,
  STATUS,
  WarehouseAndInventorySubPaths,
  OMS_APP_URL,
  showNotification
} from '../../common/constant'
import InventoryManagement from '../../pages/LocationAndInventory/InventoryManagement'
import ListedProduct from '../../pages/ProductListing/ListedProduct'
import AddProduct from '../../pages/ProductListing/AddProduct'
import UserManagement from '../../pages/UsersAndRoles/userManagement'
import UserDetails from '../../pages/userManagement'
import PmplUserManagement from '../../pages/RootAdmin/UsersAndRoles/userManagement'
import AddUserForm from '../../containers/form/AddUser/Index'
import PmplAddUserForm from '../../containers/form/PmplAddUser/Index'
import PurchaseOrders from '../../pages/PurchaseOrder'
import AccountsListPage from '../../pages/RootAdmin/AccountsListPage'
import AccountVerificationWizard from '../../pages/RootAdmin/AccountVerificationWizard'
import RolePermission from '../../pages/RootAdmin/RolesPermissionPage'
import AuthRoute from '../../components/AuthRoute'
import SellerListAdmin from '../../pages/RootAdmin/SellerListPage'
import Logout from '../../components/Logout/Logout'
import { RootStore } from '../../redux/store'
import NotificationItem from '../../components/NotificationItem'
import NotificationPage from '../../pages/NotificationPage'
import { NotificationService } from '../../services/notification.service'
import { IErrorResponse, ISuccessResponse } from '../../services/interfaces/common.interface'
import { INotification } from '../../services/interfaces/notification.interface'
import DashboardPage from '../../pages/DashboardPage'
import SettingsPage from '../../pages/Settings'

import ProfileDetails from './ProfileDetails'
import WarehouseAndInventory from './WarehouseAndInventory'
import ProductListing from './ProductListing'
import { InventoryService } from 'services/inventory.service';
import { OnboardingService } from 'services/onBoarding.service'


import './styles.scss'
import AddLocations from 'pages/LocationAndInventory/AddLocation'
import PaymentDetailsPage from 'pages/PaymentDetailsPage'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

const SellerLayout: React.FC = () => {
  const role = localStorage.getItem('role')
  const isAdmin : boolean =
    role === 'ROOT_ADMIN' ||
    role === 'PMPL_CATALOGUE_MANAGER' ||
    role === 'PMPL_OPERATIONS_MANAGER' ||
    role === 'PMPL_FINANCE_MANAGER'
  const isSeller: boolean = role === 'SELLER_ADMIN'
  const isCallSellerDetail: boolean = (role === 'SELLER_CATALOGUE_MANAGER' || role == 'SELLER_OPERATION_MANAGER' || role === 'SELLER_FINANCE_MANAGER')
  const activeSellerInventoryCount = useSelector((state:RootStore)=>state.inventoryCount.activeLocationCount)

  const [sellerSelect, setSellerSelect] = useState<boolean>(true)
  const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission)
  const [notificationMenuVisibility, setNotificationMenuVisibility] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const history = useHistory()
  const notificationService = new NotificationService()
  const [notificationList, setNotificationList] = useState([])
  const [activeWarehouseCount, setActiveWarehouseCount] =  React.useState<number>(0);

  const inventoryService = new InventoryService();
  const onboardingService = new OnboardingService();

  
  const showAllHistoryHandler = (): void => {
    setNotificationMenuVisibility(!notificationMenuVisibility)
    history.push('/sellerLayout/notifications')
  }

  /* Handling menu closing activity */
  const handleClose = (): void => {
    setAnchorEl(null)
    setNotificationMenuVisibility(!notificationMenuVisibility)
  }

  /* Manual Routing handler */
  const routeHandler = async (routeLink: string) : Promise<void> => {
    history.push(routeLink)
  }

  const notificationMenuVisibilityHandler = () => setNotificationMenuVisibility(!notificationMenuVisibility)

  const getNotificationList = async () => {
    const notificationInfo: ISuccessResponse | IErrorResponse = await notificationService.getNotifications()

    if (notificationInfo.status === STATUS.SUCCESS) {
      const { data } = notificationInfo as ISuccessResponse
      setNotificationList(data)
    }
  }

  useEffect( ()=> {
    setActiveWarehouseCount(activeSellerInventoryCount)
  },[activeSellerInventoryCount])


useEffect( () => {
  const getActiveWarehouseData =  async(supplier_id) =>{
    const getWareHouseResponse: ISuccessResponse | IErrorResponse = await inventoryService.getwarehouselist(supplier_id, 1)
    if (getWareHouseResponse.status === STATUS.SUCCESS) {
        setActiveWarehouseCount(getWareHouseResponse?.data.warehouselist.length)
        if(getWareHouseResponse?.data.warehouselist.length == 0){
            showNotification(STATUS.FAILURE, ' To add or view products need atleast one active location.')
        }
    } else{
        showNotification(STATUS.FAILURE, ' Unable to get active location list')
    }
  }
  
  const getSellerData = async () => {
  //Note : if seller email is empty means - this may be seller USER so we have to get the seller email form backend
  let usermail: string = localStorage.getItem('userMail') as string;
  if(isCallSellerDetail){
      const getSellerEmail: ISuccessResponse | IErrorResponse = await onboardingService.getLoginUserSellerDetails(usermail)  
      if (getSellerEmail.status === STATUS.SUCCESS) {
        //Note : UPDATE THE basic details in the 
        localStorage.setItem('seller_code', getSellerEmail.data.account_code)
        localStorage.setItem('seller_name', getSellerEmail.data.name)
        localStorage.setItem('sellerMail', getSellerEmail.data.email)
        localStorage.setItem('code', getSellerEmail.data.account_code)
        localStorage.setItem('supplier_apx_code', getSellerEmail.data.supplier_id);
        await getActiveWarehouseData(getSellerEmail.data.supplier_id);
      }
    } else{
      // Admin user will get the any error msg regarding active warehouse not available message
      if(!!localStorage.getItem("supplier_apx_code") && !isAdmin){
        await getActiveWarehouseData(localStorage.getItem("supplier_apx_code"));
      }
    }
  };

  getSellerData();
}, [isCallSellerDetail]   )

  useEffect(() => {
    getNotificationList()
  }, [])

  return (
    <div className="app-layout">
      <section className="app-sidebar">
        <div className="menu-header">
          <img className="menu-logo" alt="logo" draggable="false" src={PoorvikaSmLogo} />
          <img className="menu-color-logo" alt="menu-logo" draggable="false" src={PoorvikaLogo} />
        </div>
        <div className="menu-body">
          <div className="menu-list">
            {isAdmin ? (
              <>
                <SideMenuItems
                  coloricon={AccountsColor}
                  icon={Accounts}
                  label="Accounts"
                  to="/sellerLayout/admin/accounts"
                />

                <SideMenuItems
                  coloricon={UsersColorIcon}
                  icon={Users}
                  label="Sellers"
                  to="/sellerLayout/admin/seller"
                  onClick={() => setSellerSelect(true)}
                />

                <div className="admin-seller-listmenu">
                {isVisible(
                  [
                    LIST_OF_ROLES.MENU_DASHBOARD 
                  ],
                  { user: userPermission }
                ) ? (
                  <SideMenuItems
                    coloricon={profileColorIcon}
                    disabled={sellerSelect}
                    icon={profileIcon}
                    isActive={dashboardPath}
                    label="Dashboard"
                    to="/sellerLayout/dashboard"
                  />
                ): <></>}
                 {isVisible(
                  [
                    LIST_OF_ROLES.MENU_PROFILE_DETAILS 
                  ],
                  { user: userPermission }
                ) ? (
                  <SideMenuItems
                    coloricon={profileColorIcon}
                    disabled={sellerSelect}
                    icon={profileIcon}
                    isActive={profileDetailsSubPaths}
                    label="Profile Details"
                    to="/sellerLayout/profileDetials/Overview"
                  />
                  ): <></>}
                   {isVisible(
                    [
                      LIST_OF_ROLES.MENU_PRODUCT_LISTING 
                    ],
                    { user: userPermission }
                  ) ? (
                  <SideMenuItems
                    coloricon={productListColorIcon}
                    disabled={sellerSelect}
                    icon={productList}
                    isActive={productListSubPaths}
                    label="Product Listing"
                    to="/sellerLayout/productListing/searchProduct"
                  />
                  ): <></>}
                   {isVisible(
                    [
                      LIST_OF_ROLES.MENU_WAREHOUSE_INVENTORY 
                    ],
                    { user: userPermission }
                  ) ? (
                  <SideMenuItems
                    coloricon={StoreColorIcon}
                    disabled={sellerSelect}
                    icon={Store}
                    isActive={WarehouseAndInventorySubPaths}
                    label="Warehouse & Inventory"
                    to="/sellerLayout/inventory/ActiveLocations"
                  />
                  ): <></>}
                  {isVisible(
                    [
                      LIST_OF_ROLES.MENU_OMS 
                    ],
                    { user: userPermission }
                  ) ? (
                  <SideMenuItems
                    coloricon={OrdersColorIcon}
                    disabled={sellerSelect}
                    icon={Orders}
                    label="Order Management"
                    to="/sellerLayout/orderManagement"
                  />
                  ): <></>}
                    {isVisible(
                    [
                      LIST_OF_ROLES.MENU_USERS_ROLES 
                    ],
                    { user: userPermission }
                  ) ? (
                  <SideMenuItems
                    coloricon={UsersColorIcon}
                    disabled={sellerSelect}
                    icon={Users}
                    label="Users & Roles"
                    to="/sellerLayout/usersRoles"
                  />
                  ): <></>}
                   {isVisible(
                    [
                      LIST_OF_ROLES.MENU_PURCHASE_ORDER 
                    ],
                    { user: userPermission }
                  ) ? (
                  <SideMenuItems
                    coloricon={PoColorIcon}
                    disabled={sellerSelect}
                    icon={PurchaseOrder}
                    label="Purchase Orders"
                    to="/sellerLayout/purchaseOrder/Pending"
                  />
                  ): <></>}
                  {isVisible(
                    [
                      LIST_OF_ROLES.MENU_PAYMENTS 
                    ],
                    { user: userPermission }
                  ) ? (
                   <div className={sellerSelect  ? 'cursor-notAllow sidemenu-item' : 'sidemenu-item'} onClick={() => routeHandler("/sellerLayout/payments")}>
                    <CurrencyRupeeIcon className='rupee-icon'/>
                    <p className='sidemenu-title'>Payments</p>
                  </div>
                  ): <></>}
                </div>
                {localStorage.getItem('role') === 'ROOT_ADMIN' ? (
                  <>
                    <SideMenuItems
                      coloricon={UsersColorIcon}
                      icon={Users}
                      label="Roles"
                      to="/sellerLayout/admin/Roles"
                    />
                    <SideMenuItems
                      coloricon={UsersColorIcon}
                      icon={Users}
                      label="Users"
                      to="/sellerLayout/admin/Users"
                    />{' '}
                  </>
                ) : null}
                {/* } */}

                {isVisible(
                    [
                      LIST_OF_ROLES.MENU_SETTINGS 
                    ],
                    { user: userPermission }
                  ) ? (
                <SideMenuItems
                  coloricon={PoColorIcon}
                  icon={PurchaseOrder}
                  label="Settings"
                  to="/sellerLayout/admin/settings/registration"
                  isActive={settingsSubpaths}
                />
                ): <></>}
              </>
            ) : (
              <>
              {isVisible(
                  [
                    LIST_OF_ROLES.MENU_DASHBOARD 
                  ],
                  { user: userPermission }
                )  && activeWarehouseCount > 0  ? (
                <SideMenuItems
                  coloricon={profileColorIcon}
                  icon={profileIcon}
                  isActive={dashboardPath}
                  label="Dashboard"
                  to="/sellerLayout/dashboard"
                />
                ): <></>}
                {/* Profile Details */}
                {isVisible(
                  [
                    LIST_OF_ROLES.MENU_PROFILE_DETAILS,
                    LIST_OF_ROLES.LEAGAL_CREATE,
                    LIST_OF_ROLES.LEAGAL_UPDATE,
                    LIST_OF_ROLES.LEAGAL_VIEW,
                    LIST_OF_ROLES.BANK_CREATE,
                    LIST_OF_ROLES.BANK_UPDATE,
                    LIST_OF_ROLES.BANK_VIEW,
                    LIST_OF_ROLES.CONTACT_CREATE,
                    LIST_OF_ROLES.CONTACT_UPDATE,
                    LIST_OF_ROLES.CONTACT_VIEW,
                    LIST_OF_ROLES.TAX_FINANCE_CREATE,
                    LIST_OF_ROLES.TAX_FINANCE_UPDATE,
                    LIST_OF_ROLES.TAX_FINANCE_VIEW,
                    LIST_OF_ROLES.KYC_CREATE,
                    LIST_OF_ROLES.KYC_UPDATE,
                    LIST_OF_ROLES.KYC_VIEW,
                    LIST_OF_ROLES.SELLER_SUMMARY
                  ],
                  { user: userPermission }
                ) ? (
                  <SideMenuItems
                    coloricon={profileColorIcon}
                    icon={profileIcon}
                    isActive={profileDetailsSubPaths}
                    label="Profile Details"
                    to="/sellerLayout/profileDetials/Overview"
                  />
                ) : (
                  <></>
                )}
                {/*Product Listing*/}
                {isVisible(
                  [
                    LIST_OF_ROLES.MENU_PRODUCT_LISTING,
                    LIST_OF_ROLES.PRODUCT_ADD,
                    LIST_OF_ROLES.PRODUCT_VIEW,
                    LIST_OF_ROLES.PRODUCT_EDIT,
                    LIST_OF_ROLES.PRODUCT_DELIST,
                    LIST_OF_ROLES.PRODUCT_APPROVE_REJECT,
                    LIST_OF_ROLES.PRODUCT_BULK_UPLOAD
                  ],
                  { user: userPermission }
                ) && activeWarehouseCount > 0 ? (
                  <SideMenuItems
                    coloricon={productListColorIcon}
                    icon={productList}
                    isActive={productListSubPaths}
                    label="Product Listing"
                    to="/sellerLayout/productListing/searchProduct"
                  />
                ) : (
                  <></>
                )}

                {/* Inventory and Warehouse   */}
                {isVisible(
                  [
                    LIST_OF_ROLES.MENU_WAREHOUSE_INVENTORY,
                    LIST_OF_ROLES.WAREHOUSE_CREATE,
                    LIST_OF_ROLES.WAREHOUSE_EDIT,
                    LIST_OF_ROLES.WAREHOUSE_DELETE,
                    LIST_OF_ROLES.WAREHOUSE_LIST,
                    LIST_OF_ROLES.WAREHOUSE_VIEW_STOCK,
                    LIST_OF_ROLES.WAREHOUSE_UPDATE_STOCK,
                    LIST_OF_ROLES.WAREHOUSE_ADD_PRODUCT,
                    LIST_OF_ROLES.EXPORT_INVENTORY,
                    LIST_OF_ROLES.BULK_UPLOAD_STOCK
                  ],
                  { user: userPermission }
                ) ? (
                  <SideMenuItems
                    coloricon={StoreColorIcon}
                    icon={Store}
                    isActive={WarehouseAndInventorySubPaths}
                    label="Warehouse & Inventory"
                    to="/sellerLayout/inventory/ActiveLocations"
                  />
                ) : (
                  <></>
                )}
                 {isVisible(
                    [
                      LIST_OF_ROLES.MENU_OMS 
                    ],
                    { user: userPermission }
                  ) && activeWarehouseCount > 0 ? (
                <SideMenuItems
                  coloricon={OrdersColorIcon}
                  icon={Orders}
                  label="Order Management"
                  to="/sellerLayout/orderManagement"
                />
                ): <></>}
                {isSeller && isVisible(
                    [
                      LIST_OF_ROLES.MENU_OMS 
                    ],
                    { user: userPermission }
                  ) && activeWarehouseCount > 0 ? (
                  <SideMenuItems
                    coloricon={UsersColorIcon}
                    icon={Users}
                    label="Users & Roles"
                    to="/sellerLayout/usersRoles"
                  />
                ) : (
                  <></>
                )}
                {isVisible([LIST_OF_ROLES.MENU_PURCHASE_ORDER,LIST_OF_ROLES.PURCHASE_ORDER_VIEW], {
                  user: userPermission
                }) && activeWarehouseCount > 0 ? (
                  <SideMenuItems
                    coloricon={PoColorIcon}
                    icon={PurchaseOrder}
                    label="Purchase Orders"
                    to="/sellerLayout/purchaseOrder/Pending"
                  />
                ) : null}
                <div className='sidemenu-item' onClick={() => routeHandler("/sellerLayout/payments")}>
                  <CurrencyRupeeIcon className='rupee-icon'/>
                  <p className='sidemenu-title'>Payments</p>
                </div>
              </>
            )}
          </div>
          <div className="menu-footer">
            {isAdmin ? (
              <div className="admin-logout-wrapper">
                <img
                  alt="admin-footer"
                  className="menu-footer-ic"
                  draggable="false"
                  src={BellIcon}
                  onClick={notificationMenuVisibilityHandler}
                />
                <Logout />
              </div>
            ) : (
                activeWarehouseCount > 0 ? <>
                <img
                  alt="admin-footer"
                  className="menu-footer-ic"
                  draggable="false"
                  src={BellIcon}
                  onClick={notificationMenuVisibilityHandler}
                />
              </> : <></>
            )}
            <Menu
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
              anchorEl={anchorEl}
              id="basic-menu"
              open={notificationMenuVisibility}
              onClose={handleClose}
            >
              <div className="notification-menu">
                <div className="header-and-actions">
                  <div className="notification-header">
                    <h1>Recent Notifications</h1>
                  </div>
                  {/* <div className="action">
                    <p>Mark all as Read</p>
                  </div> */}
                </div>

                <div className="notification-items">
                  {notificationList ? (
                    notificationList?.map((NotificationData: INotification) => (
                      <NotificationItem
                        key={NotificationData.notification_id}
                        NotificationData={NotificationData}
                        refreshList={getNotificationList}
                      />
                    ))
                  ) : (
                    <p className="empty-status">No Notifications Found</p>
                  )}
                </div>
                <div className="view-all-notifications">
                  <p onClick={showAllHistoryHandler}>ALL NOTIFICATIONS</p>
                </div>
              </div>
            </Menu>
          </div>
        </div>
      </section>
      <section className="app-main">
        <AuthRoute component={ProfileDetails} path="/sellerLayout/profileDetials" />
        <AuthRoute component={WarehouseAndInventory} path="/sellerLayout/inventory" />
        <AuthRoute component={AddLocations} path="/sellerLayout/AddLocations" /> 
        <AuthRoute component={InventoryManagement} path="/sellerLayout/InventoryManagement" />
        <AuthRoute component={ProductListing} path="/sellerLayout/productListing" />
        <AuthRoute component={ListedProduct} path="/sellerLayout/ListedProducts" />
        <AuthRoute component={AddProduct} path="/sellerLayout/AddProducts" />
        <AuthRoute component={DashboardPage} path="/sellerLayout/dashboard" />
        {/* UserManagement */}
        <Route component={AddUserForm} path="/sellerLayout/AddUser" />
        <Route component={UserDetails} path="/sellerLayout/ViewUser" />
        <AuthRoute component={UserManagement} path="/sellerLayout/usersRoles" />
        <AuthRoute component={AddUserForm} path="/seller/AddUser" />
        <AuthRoute component={DispatchOrder} path="/sellerLayout/DispatchOrder/" />
        <AuthRoute component={PurchaseOrders} path="/sellerLayout/purchaseOrder" />
        <AuthRoute component={NotificationPage} path="/sellerLayout/notifications" />
        <AuthRoute component={PaymentDetailsPage} path="/sellerLayout/payments" />

        <AuthRoute
          path="/sellerLayout/orderManagement"
          component={() => {
            window.open(OMS_APP_URL+"/seller_login")

            return null
          }}
        />

        {/* component={() => { 
          axios.post('https://dev-oms.poorvika.com/employee/login',{emp_id: '1',
          emp_password: '12345'}).then((res)=>{
            console.log(res)
          })
          return null
        } */}

        {/* Admin paths */}
        <Route component={AccountsListPage} path="/sellerLayout/admin/accounts" />
        <Route component={AccountVerificationWizard} path="/sellerLayout/admin/verify" />
        <AuthRoute component={RolePermission} path="/sellerLayout/admin/Roles" />
        <AuthRoute component={SettingsPage} path="/sellerLayout/admin/settings" />
        <AuthRoute component={PmplUserManagement} path="/sellerLayout/admin/Users" />
        <Route component={PmplAddUserForm} path="/sellerLayout/admin/AddUser" />
        <AuthRoute
          component={() => <SellerListAdmin onClick={() => setSellerSelect(!sellerSelect)} />}
          path="/sellerLayout/admin/seller"
        />
      </section>
    </div>
  )
}
export default SellerLayout
