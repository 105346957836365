import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import CircularProgress from '@material-ui/core/CircularProgress';

import { showNotification, STATUS } from 'common/constant'
import { OnboardingService } from 'services/onBoarding.service'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'

import './styles.scss'


type IDragDropProps = {
  text: string
  fileSelected: (file: string) => void
  profile?: boolean
  fileUploaded?: (remoteUrl: string) => void
}

const FileDragandDropOnly: React.FC<IDragDropProps> = ({ text, fileSelected, profile, fileUploaded }) => {
  const [isFileUploading, setFileUploading] = useState(false)
  const onboardingService = new OnboardingService();

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 1) {
      showNotification(STATUS.FAILURE, 'Multiple Files Not Allowed')

      return
    }

    const data = new FormData();
    data.append('file', acceptedFiles[0])

    try {
      setFileUploading(true)
      const uploadResult: ISuccessResponse | IErrorResponse = await onboardingService.uploadDocument(data);

      if (uploadResult.status === STATUS.SUCCESS) {
        fileSelected(uploadResult.data.remotePath)
        
           if(fileUploaded) fileUploaded(uploadResult.data.remotePath);
    
      } else {
        showNotification(STATUS.FAILURE, 'Unable to upload the document')
      }
      setFileUploading(false)

    } catch (error) {
      setFileUploading(false)
      showNotification(STATUS.FAILURE, 'Unable to upload the document')
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'application/pdf' })

  return (
    <div className={profile ? "drag-and-drop-wrapper-profile" : "drag-and-drop-wrapper"}>
      <div {...getRootProps()}>
        <input {...getInputProps()} multiple={false} />
        {isFileUploading ? <CircularProgress /> : <p className="drag-n-drop-text">{text}</p>}
      </div>
    </div>
  )
}

export default FileDragandDropOnly
