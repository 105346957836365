import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {useHistory } from 'react-router-dom'

import SelectDropDownWithLabel from 'components/SelectDropDown/input'
import './style.scss'
// import defaultProfileImage from 'assets/default_profile_image.png'
import AppHeaderLayout from 'components/AppHeaderLayout'
// import InputField from 'components/InputField/InputField'
import LayoutHeader from 'components/LayoutHeader'
import InputLabelwithDowninput from 'components/InputLabelwithDownInputField'
import ActionButton from 'components/ActionButton'
// import { LoginService } from 'services/login.service'
import { showNotification, STATUS } from 'common/constant'
// import slugify from 'common/slugify'
// import InputWithLabels from 'components/InputWithLabels'
// import InputWithEmailVerification from 'components/InputWithEmailVerification'
// import CodeInput from 'components/CodeInput'
import { OnboardingService } from 'services/onBoarding.service'
// import { ISuccessResponse } from 'services/interfaces/common.interface'
// import MobileOTPVerification from 'components/MobileOTPVerification'
// import WarningTextLayer from 'components/WarningTextLayer'
import { encrypt } from 'common/encryptor'
// import bufferGif from 'assets/buffer.gif'


//import { cloneDeep } from 'lodash'

type TPersonalInformations = {  
  name: string
  lastName: string
  role: string
  // auth_provider: string
  password: string
  // name: string
  accountcode: string
  email: string | undefined
  mobile: string
  picture: string

}
const ac: any = localStorage.getItem('seller_code')
console.log("ac", ac)
const personalInformationValidation = {
  name: {
    required: { value: true, message: "Name can't be Empty" }
    // minLength: { value: 5, message: 'First name must be minimum 5 Letters' },
  },
  role: {
    required: { value: true, message: '' }
  },
  // auth_provider: {
  //   required: { value: true, message: '' }
  // },

  // name: {
  //   required: { value: true, message: "Name can't be Empty" }
  // },

  email: {
    required: { value: true, message: "Email field can't be Empty" },
    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: ' Email format is invalid' }
  },
  password: {
    required: { value: true, message: "* Password field can't be Empty" },
    minLength: { value: 6, message: '* Password must be at least 6 characters long' }
  },
  mobile: {
    required: { value: true, message: "Phone Number can't be Empty" },
    minLength: { value: 10, message: ' Phone Number must be at least 10 digits' },
    maxLength: { value: 10, message: "Phone Number can't be more than 10 digits" }
  }
}

type TPersonalInformationProps = {
  nextStep: () => void
  isAdmin?: boolean
}

const AddUserForm: React.FC<TPersonalInformationProps> = () => {
  const history = useHistory()
  const { register, handleSubmit, errors } = useForm<TPersonalInformations>({ mode: 'onChange' })
// setValue('accountcode', localStorage.getItem('code'))
// ac = localStorage.getItem('code')
// var ac: any = localStorage.getItem('code')
// console.log("ac1", ac)

  // const [bufferimg, setbufferimg] = useState(false)
  // const [profilepic, setProfilePic] = useState('')
  // const [code, setCode] = useState<string>('')
  // const loginService = new LoginService()
  // const [otpVisibility, setOTPVisibility] = useState(false)
  // const [buttonText, setButtonText] = useState('GET OTP')
  // const [otpNumber] = useState('')
  const onBoardingService = new OnboardingService(); 
  // const [mobileVerified, setMobileVerified] = useState(false);
  // const [mobileNumber, setMobileNumber] = useState("");
  // const [rejectionNotes, setRejectionNotes] = useState<string>("");

  const onSubmit = async (data: TPersonalInformations) => {

    /* verify the OTP */
    try {

      // if(!mobileVerified) {
        data.password = encrypt(data.password);
        // data.picture = profilepic;

      //   const otpVerificationResponse = await onBoardingService.verifyOTP(mobileNumber, otpNumber);
      //   if(otpVerificationResponse.status === STATUS.FAILURE) {
      //     showNotification(STATUS.FAILURE, "Unable to verify OTP");
      //     return
      //   } else {
      //      nextStepHandler(data);
      //   }
      // } else {
        nextStepHandler(data);
      // }
    
  } catch (error) {
    console.log("onsubmit")
    showNotification(STATUS.FAILURE, "Unable to save personal Informations");
  }
   
  }


  const nextStepHandler = async (data: any) => {
    const personalDetails: any = data
        // console.log("nextStepHandler",localStorage.getItem('code'))
        // personalDetails.code = localStorage.getItem('code') || undefined;;
    // personalDetails.email = localStorage.getItem('userMail') || undefined;
    // personalDetails.mobile = mobileNumber;
    // personalDetails.picture = profilepic;
    // personalDetails.step = 'PERSONAL_INFORMATION';


    try {
      const savePersonalData = await onBoardingService.savePmplUserData(personalDetails);

      // const savePersonalData = "success"
      if(savePersonalData.status === STATUS.SUCCESS) {
        history.push('/sellerLayout/admin/Users')
        // nextStep();
      } 
      else {
        showNotification(STATUS.FAILURE, savePersonalData.message)
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to save personal Informations')
    }
  }

  useEffect(() => {
//     const getPersonalData = async () => {
//       try {
//         const personalData = await onBoardingService.getOnboardingData(1)
// console.log("personaldata", personalData.status)
//         if (personalData.status === STATUS.SUCCESS) {
//           const successData = personalData as ISuccessResponse
//           setValue('email', successData.data.email)
//           setValue('name', successData.data.name)
//           // setValue('lastName', successData.data.lastname)
//           // setValue('name', successData.data.name)
//           // setCode(successData.data.code)
//           // setRejectionNotes(successData.data.rejection_notes);
//           console.log("successdata",successData.data)
//           // if(successData.data.mobile_verified) {
//           //   setMobileVerified(successData.data.mobile_verified);
//             // setMobileNumber(successData.data.mobile)
//           //   // setButtonText('Verified')
//           // }
          
//         } else {
//           showNotification(STATUS.FAILURE, 'Unable to get personal informations')
//         }
//       } catch (error) {
//         showNotification(STATUS.FAILURE, 'Unable to get personal informations')
//       }
//     }

//     getPersonalData()
  }, [])

  // const OTPVerificationHandler = async (mobileNumber: string) => {
  //   setMobileNumber(mobileNumber);
  //   try {
  //     const initiateOTP = await onBoardingService.getOTP(mobileNumber);  
  //     if(initiateOTP.status === STATUS.SUCCESS) {
  //       showNotification(STATUS.SUCCESS, 'OTP Successfully sent')
  //       // setOTPVisibility(true)
  //       // setButtonText('RESEND')
  //     } else {
  //       showNotification(STATUS.FAILURE, initiateOTP.message)  
  //     }
  //   } catch (error) {
  //     showNotification(STATUS.FAILURE, 'Unable to send OTP')
  //   }
  // }

  // const autoCodeValidationHandler = async (code: string) => {
  //   if (!code) {
  //     setCode('')
  //     return
  //   }

  //   try {
  //     const checkAccount = await loginService.checkCodeAuthMode(code)

  //     if (checkAccount.status === STATUS.SUCCESS) {
  //       setCode(slugify(code))
  //     } else {
  //       autoCodeValidationHandler(code + ' ' + Math.random().toString(36).substring(2, 7))
  //     }
  //   } catch (error) {
  //     showNotification(STATUS.FAILURE, error.message)
  //   }
  // }
  // const profilePictureHandler= async (event: any) => {
  //   setbufferimg(true)
  //   console.log("profile")
  //   if(event.target.files && event.target.files[0]) {
  //     const data = new FormData();
  //     data.append('file', event.target.files[0])
  //     const uploadResult = await onBoardingService.uploadDocument(data);
  //   console.log("profile", uploadResult)

  //     if(uploadResult.status ===STATUS.SUCCESS) {
  //       const result = uploadResult as ISuccessResponse;
  //       setProfilePic(result.data.remotePath)
        
  //     }
  //   }
  //   setbufferimg(false)
  // }

  return (
    
    <div className="personal-information-form-page">
        <AppHeaderLayout title="User Management" />
       <div className="bank-account-page">
      <form className="personal-information-form" onSubmit={handleSubmit(onSubmit)}>

            <div className="legal-layout-wrapper">
                <LayoutHeader title="User Account" />

                <div className="legal-details-content">
                    <InputLabelwithDowninput
                        label="Name"
                        type = "text"
                        // register={register}
                        // name="bankName"
                        error={errors.name?.type !== undefined}
                        errorText={errors.name ? errors.name.message : ''}
                        name="name"
                        register={register(personalInformationValidation.name)}
                        // text="First Name"
                        // type="text"
                    />
                    {/* <InputLabelwithDowninput
                        error={errors.lastName?.type !== undefined}
                        errorText={errors.lastName ? errors.lastName.message : ''}
                        name="lastName"
                        register={register(personalInformationValidation.lastName)}
                        label="Last Name"
                    /> */}
                    {/* <InputLabelwithDowninput
          error={errors.accountcode?.type !== undefined}
          errorText={errors.accountcode ? errors.accountcode.message : ''}
          // hint="(This name will be used in the entire system)"
          value={ac}
          name="accountcode"
          register={register(personalInformationValidation.accountcode)}
          label="Account Code" 
          readonly = {true}

                    /> */}
                    <InputLabelwithDowninput
                                  error={errors.email?.type !== undefined}
                                  errorText={errors.email ? errors.email.message : ''}
                                  // hint="(This name will be used in the entire system)"
                                  name="email"
                                  register={register(personalInformationValidation.email)}
                                  label="Email"
                                  type="email"

                    />
                    <InputLabelwithDowninput
                                error={!!errors.password}
                                errorText={errors.password ? errors.password.message : ''}
                                register={register(personalInformationValidation.password)}
                                label="Password"
                                name="password"
                                type="password"
                    />
                    <SelectDropDownWithLabel
          name="role"
          register={register(personalInformationValidation.role)}
          options={[ 'PMPL_CATALOGUE_MANAGER', 'PMPL_FINANCE_MANAGER', 'PMPL_OPERATIONS_MANAGER']}
          selectedItem="PMPL_CATALOGUE_MANAGER"
          title="Roles"
        />
                    <InputLabelwithDowninput
                                  error={errors.mobile?.type !== undefined}
                                  errorText={errors.mobile ? errors.mobile.message : ''}
                                  name="mobile"
                                  type="number"
                                  register={register(personalInformationValidation.mobile)}
                                  label="Mobile"
                    />
            <ActionButton label="Create User" type="submit" varient="outlined" />  <></>

                </div>
                
            </div>
            </form>
        </div>
        </div>
      // {/* <input className="file-uploader" id="profile-image-file" type="file"  onChange={(event) => profilePictureHandler(event) }/> */}
//       <WarningTextLayer info={rejectionNotes}/>
//       <form className="personal-information-form" onSubmit={handleSubmit(onSubmit)}>
//         <div className="image-and-name-wrapper">
//         <div>
          
//           {/* {bufferimg ? 
//           <img src={bufferGif} className="buffer-image" ></img> :
            
//           <img
//             alt="profile"
//             className="profile-image"
//             draggable={false}
//             src={profilepic? profilepic: defaultProfileImage}
//             onClick={() => document.getElementById('profile-image-file')?.click()}
            
//           />
//   }
//            */}
          
//           </div>
//           {/* <div className="name-wrapper">
//             <InputWithLabels
//               error={errors.firstName?.type !== undefined}
//               errorText={errors.firstName ? errors.firstName.message : ''}
//               name="firstName"
//               register={register(personalInformationValidation.firstName)}
//               text="First Name"
//               type="text"
//             />
//             <InputWithLabels
//               error={errors.lastName?.type !== undefined}
//               errorText={errors.lastName ? errors.lastName.message : ''}
//               name="lastName"
//               register={register(personalInformationValidation.lastName)}
//               text="Last Name"
//             />
//           </div> */}
//         </div>

//         <p className="form-subtitle">User Account</p>
//         <InputWithLabels
//               error={errors.firstName?.type !== undefined}
//               errorText={errors.firstName ? errors.firstName.message : ''}
//               name="firstName"
//               register={register(personalInformationValidation.firstName)}
//               text="First Name"
//               type="text"
//             />
//                     <InputWithLabels
//               error={errors.lastName?.type !== undefined}
//               errorText={errors.lastName ? errors.lastName.message : ''}
//               name="lastName"
//               register={register(personalInformationValidation.lastName)}
//               text="Last Name"
//             />
//         <InputWithLabels
//           error={errors.accountcode?.type !== undefined}
//           errorText={errors.accountcode ? errors.accountcode.message : ''}
//           // hint="(This name will be used in the entire system)"
//           name="accountcode"
//           register={register(personalInformationValidation.accountcode)}
//           text="Account Code"
//           // onChange={(event) => autoCodeValidationHandler(slugify(event.target.value))}
//         />
//         {/* <SelectDropDownWithLabel
//           name="auth_provider"
//           register={register(personalInformationValidation.auth_provider)}
//           options={['LOCAL', 'OPENIDCONNECT']}
//           selectedItem="LOCAL"
//           title="Auth Provider :"
//         /> */}
//          <InputWithLabels
//           error={errors.email?.type !== undefined}
//           errorText={errors.email ? errors.email.message : ''}
//           // hint="(This name will be used in the entire system)"
//           name="email"
//           register={register(personalInformationValidation.email)}
//           text="Email"
//           // onChange={(event) => autoCodeValidationHandler(slugify(event.target.value))}
//         />
//               <InputWithLabels
//         error={!!errors.password}
//         errorText={errors.password ? errors.password.message : ''}
//         register={register(personalInformationValidation.password)}
//         text="Password"
//         name="password"
//         type="password"
//       />

// <SelectDropDownWithLabel
//           name="role"
//           register={register(personalInformationValidation.role)}
//           options={['SELLER_ADMIN', 'MANAGER']}
//           selectedItem="SELLER_ADMIN"
//           title="Roles :"
//         />


//         <InputWithLabels
//           error={errors.mobile?.type !== undefined}
//           errorText={errors.mobile ? errors.mobile.message : ''}
//           // hint="(This name will be used in the entire system)"
//           name="mobile"
//           register={register(personalInformationValidation.mobile)}
//           text="Mobile"
//           // onChange={(event) => autoCodeValidationHandler(slugify(event.target.value))}
//         />
//         {/* <InputWithLabels register={register}  hint="(This is unique code for each sellers)" name="code" disabled text="Code" value={code} /> */}
//         {/* <CodeInput
//           codeSaved={(newCode) => autoCodeValidationHandler(newCode)}
//           isModeEdit={false}
//           name="code"
//           register={register}
//           text="Code"
//           value={code}
//         /> */}
//         {/* <InputWithEmailVerification name="email" register={register} text="Email" isEmailVerified /> */}
//         {/* {mobileVerified ? <MobileOTPVerification
//           buttonText={buttonText}
//           disabled={mobileVerified}
//           value={mobileNumber}
//           error={errors.mobile?.type !== undefined}
//           errorText={errors.mobile ? errors.mobile.message : ''}
//           getOTPClicked={(mobileNumber: string) => OTPVerificationHandler(mobileNumber)}
//           name="mobile"
//           register={register(personalInformationValidation.mobile)}
//           text="Mobile Number"
//         /> :  <MobileOTPVerification
//         buttonText={buttonText}
//         error={errors.mobile?.type !== undefined}
//         errorText={errors.mobile ? errors.mobile.message : ''}
//         getOTPClicked={(mobileNumber: string) => OTPVerificationHandler(mobileNumber)}
//         name="mobile"
//         register={register(personalInformationValidation.mobile)}
//         text="Mobile Number"
//       />}
//         {otpVisibility ? (
//           <InputWithLabels
//             hint="(OTP Sent to your Mobile Number)"
//             onChange={(event) => {
//               setOtpNumber(event.target.value)
//             }}
//             text="OTP"
//             type="password"
//           />
//         ) : (
//           ''
//         )} */}

        

// {/* <ActionButton label="Save & Exit" type="submit" varient="outlined" /> */}
//         <ActionButton label="Create User" type="submit" varient="outlined" />  <></>
//         {/* <ActionButton label="Save & Next" type="submit" /> */}
//       </form>
    // </div>
  )
}

export default AddUserForm
