import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Pagination } from '@mui/material'

import './styles.scss'
import { STATUS, showNotification } from 'common/constant'
import { AuditLogService } from 'services/auditLogs.service'
import { InventoryService } from 'services/inventory.service'
import { IAuditLogItem, IAuditLogRequest } from 'services/interfaces/auditLogs.interface'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'

const WarehouseAuditLogs: React.FC = () => {
  const auditLogsService = new AuditLogService()
  const inventoryService = new InventoryService()
  const [logs, setLogs] = useState<[]>([])
  const [pageNo, setPageNo] = useState<number>(1)
  const [pagelimit, setPagelimit] = useState<number>(10)
  const [pageCount, setPageCount] = useState<number>(2)


  const getLogs = async (): Promise<void> => {
    try {
      const auditRequest: IAuditLogRequest = {
        seller_code: localStorage.getItem('seller_code') as string,
        email: localStorage.getItem('userMail') as string,
        module_name: 'Warehouse'
      }

      const auditLogResponse: ISuccessResponse | IErrorResponse = await auditLogsService.getLogs(auditRequest)

      if (auditLogResponse.status === STATUS.SUCCESS) {
        const { data } = auditLogResponse as ISuccessResponse
        setLogs(data)
      } else {
        showNotification(STATUS.FAILURE, 'Unable to get auditlogs')
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get auditlogs')
    }
  }
  const getLogsInvnetory = async (page_no): Promise<void> => {
    try {
      const auditRequest = {
        seller_code: localStorage.getItem('supplier_apx_code'),
        module: "",
        "field": "createdAt",
        "orderBy": "DESC",
        "page": page_no,
        "pageLimit": pagelimit,
      }

      const auditLogResponse: ISuccessResponse | IErrorResponse = await inventoryService.historyProductInventory(auditRequest)

      if (auditLogResponse.status === STATUS.SUCCESS) {
        const { data } = auditLogResponse as ISuccessResponse
        setLogs(data.data)
        if(page_no === 1){
          const totalrecord = data.totalRecord?.length > 0 ? data.totalRecord[0]["count"] : 0;
          setPageCount(Math.ceil(totalrecord/pagelimit))
        }
      } else {
        showNotification(STATUS.FAILURE, 'Unable to get auditlogs')
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get auditlogs')
    }
  }

    /* Pagination Handler */
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
      setPageNo(value);
      getLogsInvnetory(value)
    }

  useEffect(() => {
    getLogsInvnetory(1)
  }, [])

  return (
    <div className="warehouse-history-page">
      <h1 className="history-heading">Warehouse History</h1>
      <p className="captions">Captions: NA - Not Applicable</p>
      <table className="history-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Activity Type</th>
            <th>Description</th>

            <th>Updated by</th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log: any, idx) => {
            return (
              <tr key={log?._Id +"_"+idx}>
                <td>{moment(log?.createdAt).format('DD-MM-YYYY hh:mm A')}</td>
                <td>{log?.activity === 'Insert' ? 'New' : 'Update'}</td>
                <td>{log?.operationType} - {log?.moduleType} 
                {!!log?.erpProductId? " - Product Id:"+ log?.erpProductId : ''}  
                {!!log?.wareHouseCode ? " - Warehouse: "+ log?.wareHouseCode : ''}</td>
                <td>{log?.createdBy}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="pagination-zone">
        {/* <Pagination count={1} variant="outlined" shape="rounded" /> */}
        <div className="all-products-pagination">
        <Pagination
          className="all-products-pagination"
          page={pageNo}
          count={pageCount}
          shape="rounded"
          variant="outlined"
          onChange={handleChange}
        />
      </div>
      </div>
    </div>
  )
}

export default WarehouseAuditLogs
