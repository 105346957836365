import React, { useEffect, useState } from 'react'
import { faSort, faSortUp, faSortDown, faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Autocomplete, TextField, FormControl, InputLabel, Select, MenuItem, Pagination } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import { Slider } from '@material-ui/core'


import { InventoryService } from '../../../../services/inventory.service'
import { showNotification, STATUS } from '../../../../common/constant'
import { IErrorResponse, ISuccessResponse } from '../../../../services/interfaces/common.interface'
import ActionButton from '../../../../components/ActionButton'
import { IProductInventory } from '../../../../services/interfaces/inventory.interface'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300
  },
  margin: {
    height: theme.spacing(3)
  },
  thumb: {
    background: '#ff632b',
    '&~&': {
      background: '#ff632b'
    }
  },
  mark: {
    background: 'black'
  },
  rail: {
    background: 'grey'
  },
  track: {
    background: '#ff632b !important'
  },
  valueLabel: {
    '&>*': {
      background: 'black'
    }
  }
}))

const InActiveInventoryTable: React.FC<{ isUpdated: boolean; inventory: IProductInventory[] }> = ({
  isUpdated,
  inventory
}) => {
  const classes = useStyles()

  const [productStatus, setProductStatus] = useState<[IProductInventory] | []>()
  const [pageCount, setPageCount] = useState<number>()
  const [sortingType, setSortingType] = useState<string>('ASC')
  const [sortColumn, setSortColumn] = useState<string>('erp_prod_id')
  const [filterSku, setFilterSku] = useState<any>({})
  const [filterBrand, setfilterBrand] = useState<any>({})
  const [filterCategory, setfilterCategory] = useState<any>({})
  const [filterPsn, setFilterPsn] = useState<any>({})
  const [filterErpCode, setFilterErpCode] = useState<any>({})
  const [filterWarName, setFilterWarName] = useState<any>({})
  const [filterSelCode, setFilterSelCode] = useState<any>({})
  const [filterStock, setFilterStock] = useState<number[]>([0, 35000])
  const [maxStock, setMaxStock] = useState<number>(0)
  const [menu_text, setMenuText] = useState<string>('1')
  const [pagelimit, setPagelimit] = useState<number>(10)
  const [pageNo, setPageNo] = useState<number>(1)
  // const userPermission = useSelector((state: RootStore) => state.userDetails.userDetails?.Permission)

  const inventoryService: InventoryService = new InventoryService()

  useEffect(() => {
    const maxStock = inventory?.length > 0 ? Math.max(...inventory.map((item: IProductInventory) => item.profile_stock_inhand)) : 0
    setMaxStock(maxStock)
    setFilterStock([0, maxStock])
  }, [inventory])

  const multipleProductFilter = async (sortColumnName?, sortType?: string,page?: number, change_in_total?: string) => {
    try {
      const SupplierCode = localStorage.getItem("supplier_apx_code");
      const data = {
        sellerCode: SupplierCode,
        sku: filterSku?.sku ?? '',
        poorivkaCode: filterErpCode?.erp_item_code ?? '',
        profile: "seller",
        brandName: filterBrand?.brand ?? '',
        categoryName: filterCategory?.category ?? '',
        psn: filterPsn?.psn ?? '',
        "warehouseStatus": 1,
        "inventoryStatus":0,
        extProdId: filterSelCode?.ext_prod_id ?? '',
        warehouseName: filterWarName?.name ?? '',
        "stockrange": {
          "min": filterStock[0],
          "max": filterStock[1]
        },
        order : sortType,
        field : sortColumnName,
        limit : pagelimit,
        page : !!page ? page : 1
      }
     
      if(!!change_in_total && change_in_total === "yes"){
        const PimproductsInventoryCount =  await inventoryService.getInventoryListTotal(data)
        setPageCount(Math.ceil(PimproductsInventoryCount.count/pagelimit))
      }
      const Pimproducts: ISuccessResponse | IErrorResponse = await inventoryService.multipleSearchProductInventory(data)
      const getpimproducts = Pimproducts as ISuccessResponse
      setProductStatus(getpimproducts.data)
      setPageNo(!!page ? page : 1)
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Product informations')
    }
  }

  const handleProductFilter = () => {
    const sortingType_t = sortingType === 'ASC' ? 'DESC' : 'ASC' 
    multipleProductFilter(sortColumn,sortingType_t,1,"yes")
  }


  const getInventorylist = async (pageNo) => {
    try {
      const SupplierCode = localStorage.getItem("supplier_apx_code");
      if(!!SupplierCode){
        const getInventorylistResponse = await inventoryService.multipleSearchProductInventory({"sellerCode":SupplierCode,  "limit": pagelimit, "page":pageNo,"inventoryStatus":0, "warehouseStatus": 1})
        if(getInventorylistResponse.status === STATUS.SUCCESS){
          setProductStatus(getInventorylistResponse.data)
        } else {
          showNotification(STATUS.FAILURE, 'Unable to get Product informations')
        }
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Product informations')
    }
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNo(value);
    const sortingType_t = sortingType === 'ASC' ? 'DESC' : 'ASC' 
    multipleProductFilter(sortColumn,sortingType_t,value,"no")
  }




  /* To sort the table data based on column */
  const sortingFunctionalityHandler = (columnName: string, sortType: string) => {
    multipleProductFilter(columnName, sortType,1,"no")
    setSortColumn(columnName)
    setSortingType(sortingType === 'ASC' ? 'DESC' : 'ASC')
  }

  const getTotalProductCount = async() => {
    try{
    const SupplierCode = localStorage.getItem("supplier_apx_code");
      const getInventoryCount = await inventoryService.getInventoryListTotal( {"sellerCode":SupplierCode,"inventoryStatus":0})
      if(getInventoryCount.status === STATUS.SUCCESS){
        setPageCount(Math.ceil(getInventoryCount.count/pagelimit));
        getInventorylist(1);
      }else{
        showNotification(STATUS.FAILURE, 'Unable to get Product Count')
      } 
    }catch(error){
      showNotification(STATUS.FAILURE, 'Unable to get Product Count')
    }
  }

  useEffect(() => {
    getTotalProductCount()
  }, [isUpdated])

  return (
    <div className="Inventory-table-body">
      <div className="filter-head">
        <div className="filter-item">
          <FontAwesomeIcon className="filter-icon" icon={faFilter as IconProp} />
        </div>

        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={inventory || []}
            value={filterSku}
            getOptionLabel={(option: IProductInventory) => option.sku || ''}
            onChange={(e, val) => setFilterSku(val)}
            style={{ width: 200 }}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="SKU" variant="outlined" />}
          />
        </div>
        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={inventory || []}
            value={filterErpCode}
            getOptionLabel={(option: IProductInventory) => option.erp_prod_id || ''}
            style={{ width: 160 }}
            onChange={(e, value) => setFilterErpCode(value)}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="Poorvika Code" variant="outlined" />}
          />
        </div>
        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={inventory || []}
            value={filterSelCode}
            getOptionLabel={(option: IProductInventory) => option.ext_prod_id || ''}
            style={{ width: 160 }}
            onChange={(e, value) => setFilterSelCode(value)}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="Seller Code" variant="outlined" />}
          />
        </div>
        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={inventory || []}
            value={filterPsn}
            getOptionLabel={(option: IProductInventory) => option.psn || ''}
            onChange={(e, val) => setFilterPsn(val)}
            style={{ width: 200 }}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="PSN" variant="outlined" />}
          />
        </div>

        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={
              inventory?.filter(
                (v: IProductInventory, i, a) => a.findIndex((t: IProductInventory) => t.name === v.name) === i
              ) || []
            }
            value={filterWarName}
            getOptionLabel={(option: IProductInventory) => option.name || ''}
            style={{ width: 160 }}
            onChange={(e, value) => setFilterWarName(value)}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="Warehouse" variant="outlined" />}
          />
        </div>

        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            value={filterBrand}
            options={
              inventory?.filter(
                (v: IProductInventory, i, a) => a.findIndex((t: IProductInventory) => t.brand === v.brand) === i
              ) || []
            }
            getOptionLabel={(option: IProductInventory) => option.brand || ''}
            style={{ width: 160 }}
            onChange={(e,value) => setfilterBrand(value)}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="Brand" variant="outlined" />}
          />
        </div>

        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={
              inventory?.filter(
                (v: IProductInventory, i, a) => a.findIndex((t: IProductInventory) => t.category === v.category) === i
              ) || []
            }
            value={filterCategory}
            getOptionLabel={(option: IProductInventory) => option.category || ''}
            style={{ width: 160 }}
            onChange={(e, value) => setfilterCategory(value)}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="Catagory" variant="outlined" />}
          />
        </div>

        <div className="filter-item" style={{ width: '250px' }}>
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">Stock</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={menu_text}
              style={{ color: 'rgba(0, 0, 0, 0.6)' }}
              label="Stock"
              onClose={() => setMenuText('1')}
            >
              <MenuItem value="" style={{ display: 'flex', flexDirection: 'column' }}>
                <Slider
                  getAriaLabel={() => 'Stock range'}
                  value={filterStock}
                  min={0}
                  max={maxStock}
                  valueLabelDisplay="off"
                  onChange={(event, value: any) => setFilterStock(value)}
                  track="inverted"
                  classes={{
                    thumb: classes.thumb,
                    rail: classes.rail,
                    track: classes.track,
                    valueLabel: classes.valueLabel
                  }}
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    size="small"
                    disabled
                    value={filterStock[0]}
                    type="number"
                    style={{ width: 'auto', maxWidth: '130px', paddingRight: '10px' }}
                  >
                    {' '}
                  </TextField>
                  to{' '}
                  <TextField
                    size="small"
                    disabled
                    value={filterStock[1]}
                    type="number"
                    style={{ width: 'auto', maxWidth: '130px', paddingLeft: '10px' }}
                  >
                    {' '}
                  </TextField>
                </div>
              </MenuItem>
              <MenuItem
                value="1"
                style={{ display: 'none' }}
              >{`Stock Range : ${filterStock[0]} - ${filterStock[1]}`}</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="filter-item">
          <ActionButton
            onClick={() => {
              handleProductFilter()
            }}
            label="Apply"
          />
          <ActionButton
            label="Clear"
            onClick={() => {
              getInventorylist(1)
              setFilterSku(null)
              setFilterPsn(null)
              setFilterErpCode(null)
              setfilterCategory(null)
              setfilterBrand(null)
              setFilterWarName(null)
              setFilterSelCode(null)
              setFilterStock([0, maxStock])
            }}
          />
        </div>
      </div>
      <table className="inventory-manaagement-table">
        <thead>
          <tr>
            <th>
              Product
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'product_name' ? faSort  as IconProp: sortingType === 'DESC' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('product_name', sortingType)}
              />
            </th>
            <th>
              Category
              <FontAwesomeIcon
                   className="sort-icon"
                   icon={sortColumn !== 'category' ? faSort  as IconProp: sortingType === 'DESC' ? faSortDown as IconProp : faSortUp as IconProp } 
                   onClick={() => sortingFunctionalityHandler('category', sortingType)}
                   />
            </th>
            <th>
              Brand
              <FontAwesomeIcon
                   className="sort-icon"
                   icon={sortColumn !== 'brand' ? faSort  as IconProp: sortingType === 'DESC' ? faSortDown as IconProp : faSortUp as IconProp } 
                   onClick={() => sortingFunctionalityHandler('brand', sortingType)}
                   />
            </th>
            <th>
              Global SKU
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'sku' ? faSort  as IconProp: sortingType === 'DESC' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('sku', sortingType)}
              />
            </th>
            <th>
              Poorvika Product Code
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'erp_prod_id' ? faSort  as IconProp: sortingType === 'DESC' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('erp_iterp_prod_idem_code', sortingType)}
              />
            </th>
            <th>
              Seller Product Code
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'ext_prod_id' ? faSort  as IconProp: sortingType === 'DESC' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('ext_prod_id', sortingType)}
              />
            </th>
            <th>
              PSN
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'psn' ? faSort  as IconProp: sortingType === 'DESC' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('psn', sortingType)}
              />
            </th>
            <th>
              Stock in Hand
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'profile_stock_inhand' ? faSort  as IconProp: sortingType === 'DESC' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('profile_stock_inhand', sortingType)}
              />
            </th>

            <th>
              Warehouse
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'branch_code' ? faSort  as IconProp: sortingType === 'DESC' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('branch_code', sortingType)}
              />
            </th>
          </tr>
        </thead>
        {productStatus ? (
          <tbody>
            {productStatus.map((item,idx) => {
              return (
                <tr key={item.erp_prod_id+"_"+idx}>
                  <td key={"Iname_"+idx}>
                    <div className="product-col">
                      <div className="pimg-wrap">
                        <img className="product-img" alt='product-img' src={item.img_url} />
                      </div>
                      <div className="pcontent-wrap">{item.product_name}</div>
                    </div>
                  </td>
                  <td key={"category_"+idx}>{item.category}</td>
                  <td key={"band_"+idx}>{item.brand}</td>
                  <td key={"sku_"+idx}>{item.sku}</td>
                  <td key={"erp_"+idx}>{item.erp_prod_id}</td>
                  <td key={"ext_"+idx}>{item.ext_prod_id}</td>
                  <td key={"psn_"+idx}>{item.psn}</td>

                  <td className="stock-count" key={"stock_"+idx}>{item.profile_stock_inhand}</td>

                  <td key={"name_"+idx}>{item.name}</td>
                </tr>
              )
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td className="empty-data" colSpan={12}>
                No data to display
              </td>
            </tr>
          </tbody>
        )}
      </table>
      <div className="all-products-pagination">
        <Pagination
          className="all-products-pagination"
          page={pageNo}
          count={pageCount}
          shape="rounded"
          variant="outlined"
          onChange={handleChange}
        />
      </div>
    </div>
  )
}
export default InActiveInventoryTable
