import React, { useEffect, useState, useRef } from 'react'
import { Checkbox, Slider } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { faSort, faSortUp, faSortDown, faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, DialogActions } from '@material-ui/core'
import { Autocomplete, TextField, FormControl, InputLabel, Select, MenuItem, Pagination } from '@mui/material'
import { useForm } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'

import { ProductService } from '../../../services/productService'
import { IErrorResponse, ISuccessResponse } from '../../../services/interfaces/common.interface'
import { showNotification, STATUS } from '../../../common/constant'
import ActionButton from '../../../components/ActionButton'
import { InventoryService } from '../../../services/inventory.service'
import InputWithLabels from '../../../components/InputWithLabels'
import { IListingProduct } from '../../../services/interfaces/product.interface'

import './styles.scss'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { CSVLink } from 'react-csv'


const useStyles = makeStyles((theme) => ({
  root: {
    width: 300
  },
  margin: {
    height: theme.spacing(3)
  },
  thumb: {
    background: '#ff632b',
    '&~&': {
      background: '#ff632b'
    }
  },
  mark: {
    background: 'black'
  },
  rail: {
    background: 'grey'
  },
  track: {
    background: '#ff632b !important'
  },
  valueLabel: {
    '&>*': {
      background: 'black'
    }
  }
}))

type ALLProductprops = {
  searchInput: string
}

type IWareHouseProduct = {
  checked: boolean
} & IListingProduct



type IWareHouseTime = {
  Monday:{
    start:string,
    end:string
  }
}

type IWareHouse = {


address1: string
address2: string
city: string
cst_number: string
escalations_contact_email: string
escalations_contact_mobile: string
escalations_contact_name: string
ext_warehouse_code: string
fulfilment_type: string
gst_number: string
is_active: string
latlng: string
location_type: string
pan_number:string
pincode: string
plus_code: string
primary_contact_email: string
primary_contact_mobile: string
primary_contact_name: string
row_added_by: string
row_added_dttm: string
row_updated_by: string
row_updated_dttm: string
seller_code: string
state: string
tan_number: string
vat_number: string
code: string
name: string
warehouse_status: string
working_days: [string]
working_hours_and_days: IWareHouseTime []

}

const AddInventoryProducts: React.FC<ALLProductprops> = ({ searchInput }) => {
  const classes = useStyles()

  const { register, handleSubmit } = useForm({ mode: 'all' })

  const [product, setProducts] = useState<IWareHouseProduct []>([])

  
  const [allproductsChecked, setAllProductsChecked] = useState<boolean>(false)
  const [pageCount, setPageCount] = useState<number>()
  const [warehouseCode, setWarehouseCode] = React.useState<IWareHouse>()

  const productService: ProductService = new ProductService()
  const inventoryService: InventoryService = new InventoryService()
  const [sortDialog, setsortDialog] = React.useState<boolean>(false)
  const [sortingType, setSortingType] = useState<string>('1')
  const [sortColumn, setSortColumn] = useState<string>('')
  const [filterSku, setFilterSku] = useState<any>({})
  const [filterErpCode, setFilterErpCode] = useState<any >({})
  const [filterName, setFilterName] = useState<any>({})
  const [filterCategory, setFilterCategory] = useState<any >({})
  const [filterSelCode, setFilterSelCode] = useState<any >({})
  const [filterMrp, setFilterMrp] = useState<number[]>([0, 35000])
  const [maxMrp, setMaxPrice] = useState<number>(0)
  const [menu_text, setMenuText] = useState<string>('1')
  const [checkedProducts, setCheckedProducts] = useState<number>(0)
  const [pageNo, setPageNo] = useState<number>(1);
  const location = useLocation<any>()
  const [csvData, setCsvData] = useState([])
  const inputEl = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null)


  const getSellerProducts = async (pageNO, optionval?: string) => {
    try {
      // const Pimproducts: ISuccessResponse | IErrorResponse = await productService.getSellerProducts('Listed', pageNO, location.state.warehouse_code)
      const Pimproducts: ISuccessResponse | IErrorResponse = await productService.getSellerProductsWithoutWarehose('Listed', pageNO)
      const getpimproducts = Pimproducts as ISuccessResponse

      if ((Pimproducts.status = STATUS.SUCCESS)) {
        getpimproducts?.data?.map((product) => (product['checked'] = false))
        setProducts(getpimproducts.data ? getpimproducts.data : [])
        setPageCount(getpimproducts.pagecount)
        if(optionval == "onload"){
          const maxMrp = getpimproducts.data?.length > 0 ? Math.max(...getpimproducts.data.map((item: IWareHouseProduct) => item.mrp)) : 0
          setMaxPrice(maxMrp)
          setFilterMrp([0, maxMrp])
        }
      }
    } catch (error) {
       showNotification(STATUS.FAILURE, 'Unable to get Product informations')
    }
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNo(value)
    getSellerProducts(value)
  }

  // useEffect(() => {
  //   const maxMrp = product?.length > 0 ? Math.max(...product.map((item: IWareHouseProduct) => item.mrp)) : 0

  //   setMaxPrice(maxMrp)
  //   setFilterMrp([0, maxMrp])
  // }, [product])

  const AddProductstoInventory = async (data) => {
    // const addInventory = [
    //   {
    //     warehouse_name: warehouseCode?.warehouse_name,
    //     inv_profile: '',
    //     erp_item_code: data.erp_item_code,
    //     pim_code: data.pim_code,
    //     psn: data.psn,
    //     ext_prod_id: data.ext_prod_id,
    //     sku: data.sku,
    //     brand: data.brand,
    //     category: data.category,
    //     listing_status: 'Listed',
    //     reserverd_qty: 0,
    //     qty_on_hand: 0,
    //     backorder_qty: 0,
    //     backorder_avail_date: ''
    //   }
    // ]
    const addInventory = [{
      "inv_loc_code": warehouseCode?.code,
      "pim_prod_id": data.pim_code,
      "erp_prod_id": data.erp_item_code,
      "ext_prod_id": data.ext_prod_id,
      "product_name": data.name,
      "prod_id": "",
      "sku":data.sku,
      "img_url": data.image,
      "psn": data.psn,
      "status": "NEW",
      "invoice_no": "",
      "sale_invoice_no": "",
      "profile": "seller",
      "allowed_channels": "[\"online\"]",
      "profile_stock_inhand": 0,
      "profile_stock_res": 0,
      "branch_code": warehouseCode?.ext_warehouse_code,
      "profile_fullfillment_method": "[\"REGULAR\"]",
      "channels": "{\"online\":{\"in_hand\":\"0\",\"order_min\":\"0\",\"order_max\":\"0\",\"status\":\"In-Stock\",\"fullfillment\":{\"allowed\":[\"REGULAR\"],\"price\":[{\"REGULAR\":\"0\"}]}}}",
      "channel_priority": "[null]",
      "row_added_by": "Seller Portal",
      "row_updated_by": "Seller Portal",
      "brand": data.brand,
      "category":data.category,
      "pimNewProductStatus": false
    }];

    try {
      const addproducts: ISuccessResponse | IErrorResponse = await inventoryService.addInventory(addInventory)
      if(addproducts.status == STATUS.ERROR || addproducts.status == STATUS.FAILURE){
        showNotification(STATUS.FAILURE, addproducts?.message? addproducts.message : 'Unable to Add Product ')
      } else{
        showNotification(STATUS.SUCCESS, addproducts.data[0].message)
      }
      getSellerProducts(1)
      setAllProductsChecked(false)
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to Add Product ')
    }
  }

  /*This donwload is available for the only  active products */
  const handleDownload = async (downloadResult) => {
    try {
      const SupplierCode = localStorage.getItem("supplier_apx_code");
      if (downloadResult?.data?.length !== 0) {
        const data = downloadResult.data
          .map((item) => {
            const product = {}

            product['Seller Code'] = SupplierCode
            product['Product'] = item["data"].product_name
            product['Category'] = item["data"].category
            product['Brand'] = item["data"].brand
            product['Global SKU'] = item["data"].sku
            product['Poorvika Product Code'] = item["data"].erp_prod_id
            product['Seller Product code'] = item["data"].ext_prod_id
            product['PSN'] = item["data"].psn
            product['Inventory Status'] = item["message"]
            return product
          })

          // .filter((p) => p['isActive'] === 1)
        setCsvData(data)
      } else showNotification(STATUS.FAILURE, 'Failed to download Add Inventory status')

      inputEl.current.link.click()
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to download the document')
    }
  }

  useEffect(() => {
    const newProduct = [...product]

    newProduct.map((productInfo) => (productInfo.checked = allproductsChecked))
   
    const total = product?.filter((item) => item.checked).length;
    setCheckedProducts(total)

    setProducts(newProduct)
  }, [allproductsChecked])

  const multipleSelectionHander = async (isSelected: boolean, index: number) => {
    const newProduct = [...product]
    newProduct[index]['checked'] = isSelected;

    const total = product?.filter((item) => item.checked).length;
    setCheckedProducts(total)

    setProducts(newProduct)
  }

  /* Adding multiple product to the inventory upon checkbox selection */
  const MultipleProductstoInventory = async () => {
    const selectedItems = product?.filter((item) => item.checked);
    setCheckedProducts(selectedItems.length)

    if (selectedItems.length) {
      let addInventoryProducts: any [] = []

      selectedItems.map((data: any) => {
        // const mulProduct = {
        //   warehouse_name: warehouseCode?.name,
        //   inv_profile: '',
        //   erp_item_code: data.erp_item_code,
        //   pim_code: data.pim_code,
        //   psn: data.psn,
        //   ext_prod_id: data.ext_prod_id,
        //   sku: data.sku,
        //   brand: data.brand,
        //   category: data.category,
        //   listing_status: 'Listed',
        //   reserverd_qty: 0,
        //   qty_on_hand: 0,
        //   backorder_qty: 0,
        //   backorder_avail_date: ''
        // }
        const mulProduct = {
          "inv_loc_code": warehouseCode?.code,
          "pim_prod_id": data.pim_code,
          "erp_prod_id": data.erp_item_code,
          "ext_prod_id": data.ext_prod_id,
          "product_name": data.name,
          "prod_id": "",
          "sku":data.sku,
          "img_url": data.image,
          "psn": data.psn,
          "status": "NEW",
          "invoice_no": "",
          "sale_invoice_no": "",
          "profile": "seller",
          "allowed_channels": "[\"online\"]",
          "profile_stock_inhand": 0,
          "profile_stock_res": 0,
          "branch_code": warehouseCode?.ext_warehouse_code,
          "profile_fullfillment_method": "[\"REGULAR\"]",
          "channels": "{\"online\":{\"in_hand\":\"0\",\"order_min\":\"0\",\"order_max\":\"0\",\"status\":\"In-Stock\",\"fullfillment\":{\"allowed\":[\"REGULAR\"],\"price\":[{\"REGULAR\":\"0\"}]}}}",
          "channel_priority": "[null]",
          "row_added_by": "Seller Portal",
          "row_updated_by": "Seller Portal",
          "brand": data.brand,
          "category":data.category,
          "pimNewProductStatus": false
        }
        addInventoryProducts = [...addInventoryProducts, mulProduct]
      })

      try {
        const addproducts : ISuccessResponse | IErrorResponse= await inventoryService.addInventory(addInventoryProducts)
        if(addproducts.status === STATUS.SUCCESS){
          showNotification(STATUS.SUCCESS, "Status update aavialble in the file")
          if(allproductsChecked){
            setAllProductsChecked(false)
          }
          getSellerProducts(pageNo)
          handleDownload(addproducts)
        } else{
          showNotification(STATUS.FAILURE, 'Error - Unable to Add Product ')
        }
      } catch (error) {
        showNotification(STATUS.FAILURE, 'Unable to Add Product')
      }
    } else {
      showNotification(STATUS.FAILURE, 'Please select atleast one product')
    }
  }

  const multipleProductFilter = async (data, columnName?: string, sortType?: string) => {

    try {
      const Pimproducts: ISuccessResponse | IErrorResponse = await productService.multipleSearchProduct('Listed', 1, data, columnName, sortType)
      const getpimproducts = Pimproducts as ISuccessResponse

      setProducts(getpimproducts.data)
      setPageCount(getpimproducts.pagecount)
      setsortDialog(false)
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to get Product informations')
      setsortDialog(false)
    }
  }

  const handleProductFilter = () => {
    const data = {
      erp_item_code: filterErpCode?.erp_item_code ?? '',
      sku: filterSku?.sku ?? '',
      ext_prod_id: filterSelCode?.ext_prod_id ?? '',
      name: filterName?.name ?? '',
      category: filterCategory?.category ?? '',
      minMrp: filterMrp[0],
      maxMrp: filterMrp[1]
    }
    multipleProductFilter(data)
  }

 

  /* To sort the table data based on column */
  const sortingFunctionalityHandler = (columnName: string, sortType: string) => {
    const data = {
      erp_item_code: filterErpCode?.erp_item_code ?? '',
      sku: filterSku?.sku ?? '',
      ext_prod_id: filterSelCode?.ext_prod_id ?? '',
      name: filterName?.name ?? '',
      category: filterCategory?.category ?? '',
      minPrice: filterMrp[0],
      maxMrp: filterMrp[1]
    }
    multipleProductFilter(data, columnName, sortType)
    setSortColumn(columnName)
    setSortingType(sortingType === '1' ? '-1' : '1')
  }


  useEffect(() => {
    setWarehouseCode(location.state)
    getSellerProducts(1, "onload")
  }, [searchInput])

  return (
    <div className="Inventory-table-body addinventory-products" style={{marginTop:'1rem'}}>
    <CSVLink
            disabled
            filename="inventory_add_status.csv"
            data={csvData}
            asyncOnClick
            onClick={(event, done) => {
              done()
            }}
            style={{ display: 'none' }}
            ref={inputEl}
          />

      <div className="filter-head">
        <div className="filter-item">
          <FontAwesomeIcon className="filter-icon" icon={faFilter as IconProp} />
        </div>
        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={product || []}
            value={filterName}
            getOptionLabel={(option: IWareHouseProduct) => option.name || ''}
            style={{ width: 260 }}
            onChange={(e, value) => setFilterName(value)}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="Name" variant="outlined" />}
          />
        </div>
        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={product.filter((v:IListingProduct,i,a)=>a.findIndex((t:IListingProduct)=>(t.category===v.category))===i)
              || []}
            value={filterCategory}
            getOptionLabel={(option: IWareHouseProduct) => option.category || ''}
            style={{ width: 230 }}
            onChange={(e, value) => setFilterCategory(value)}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="Category" variant="outlined" />}
          />
        </div>


        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={product || []}
            value={filterSku}
            getOptionLabel={(option: IWareHouseProduct) => option.sku || ''}
            onChange={(e, val) => setFilterSku(val)}
            style={{ width: 200 }}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="SKU" variant="outlined" />}
          />
        </div>
        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={product || []}
            value={filterErpCode}
            getOptionLabel={(option: IWareHouseProduct) => option.erp_item_code || ''}
            style={{ width: 160 }}
            onChange={(e, value) => setFilterErpCode(value)}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="Poorvika Code" variant="outlined" />}
          />
        </div>
        <div className="filter-item">
          <Autocomplete
            id="combo-box-demo"
            options={product || []}
            value={filterSelCode}
            getOptionLabel={(option: IWareHouseProduct) => option.ext_prod_id || ''}
            style={{ width: 160 }}
            onChange={(e, value) => setFilterSelCode(value)}
            classes={classes}
            renderInput={(params) => <TextField {...params} size="small" label="Seller Code" variant="outlined" />}
          />
        </div>

        <div className="filter-item" style={{ width: '250px' }}>
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">MRP</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={menu_text}
              style={{ color: 'rgba(0, 0, 0, 0.6)' }}
              label="Stock"
              onClose={() => setMenuText('1')}
            >
              <MenuItem value="" style={{ display: 'flex', flexDirection: 'column' }}>
                <Slider
                  getAriaLabel={() => 'Stock range'}
                  value={filterMrp}
                  min={0}
                  max={maxMrp}
                  valueLabelDisplay="off"
                  onChange={(event, value: any) => setFilterMrp(value)}
                  track="inverted"
                  classes={{
                    thumb: classes.thumb,
                    rail: classes.rail,
                    track: classes.track,
                    valueLabel: classes.valueLabel
                  }}
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    size="small"
                    disabled
                    value={filterMrp[0]}
                    type="number"
                    style={{ width: 'auto', maxWidth: '130px', paddingRight: '10px' }}
                  >
                    {' '}
                  </TextField>
                  to{' '}
                  <TextField
                    size="small"
                    disabled
                    value={filterMrp[1]}
                    type="number"
                    style={{ width: 'auto', maxWidth: '130px', paddingLeft: '10px' }}
                  >
                    {' '}
                  </TextField>
                </div>
              </MenuItem>
              <MenuItem
                value="1"
                style={{ display: 'none' }}
              >{`MRP Range : ${filterMrp[0]} - ${filterMrp[1]}`}</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="filter-item">
          <ActionButton
            onClick={() => {
              handleProductFilter()
            }}
            label="Apply"
          />
          <ActionButton
            label="Clear"
            onClick={() => {
              getSellerProducts(1)
              setFilterSku(null)
              setFilterErpCode(null)
              setFilterName(null)
              setFilterCategory({})
              setFilterSelCode(null)
              setFilterMrp([0, maxMrp])
            }}
          />
        </div>
      </div>
{
  checkedProducts > 0 ? (
    <div className="filter-head">
    <div className="filter-item" style={{ marginLeft: '2rem' }}>
      <span> {checkedProducts > 1 ? checkedProducts +' items are Selected':  checkedProducts +' item Selected'} </span>{' '}
      <span>
        <ActionButton
          label="Add to Warehouse"
          onClick={() => MultipleProductstoInventory()}
         
        />
      </span>
    </div>
  </div>
  ) : (null)
}
     

      <table className="inventory-products-table">
        <thead>
          <tr>
            <th>
              <Checkbox checked={allproductsChecked} value={allproductsChecked} onChange={(event) => setAllProductsChecked(event.target.checked)} />
            </th>
            <th>
              Product
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'name' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('name', sortingType)}
              />
            </th>
            <th>
              Category
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'category' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('category', sortingType)}
              />
            </th>
            <th>
              Brand
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'brand' ? faSort as IconProp : sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('brand', sortingType)}
              />
            </th>
            <th>
              Global SKU
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'sku' ? faSort as IconProp : sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('sku', sortingType)}
              />
            </th>
            <th>
              Poorvika Product Code
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'erp_item_code' ? faSort as IconProp : sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('erp_item_code', sortingType)}
              />
            </th>
            <th>
              Seller Product Code
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'ext_prod_id' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('ext_prod_id', sortingType)}
              />
            </th>
            <th>
              MRP
              <FontAwesomeIcon
                className="sort-icon"
                icon={sortColumn !== 'mrp' ? faSort  as IconProp: sortingType === '-1' ? faSortDown as IconProp : faSortUp as IconProp}
                onClick={() => sortingFunctionalityHandler('mrp', sortingType)}
              />
            </th>
            <th>Action</th>
          </tr>
        </thead>
        {product ? (
          <tbody>
            {product.map((item, index) => {
              return (
                <tr key={item.erp_item_code}>
                  <td>
                    <Checkbox
                      checked={item.checked}
                      onChange={(event) => multipleSelectionHander(event.target.checked, index)}
                    />
                  </td>
                  <td>
                    <div className="product-col">
                      <div className="pimg-wrap">
                        <img className="product-img" alt='product-img' src={item.image} />
                      </div>
                      <div className="pcontent-wrap">{item.name}</div>
                    </div>
                  </td>
                  <td>{item.brand}</td>
                  <td>{item.category}</td>
                  <td>{item.sku}</td>

                  <td>{item.erp_item_code}</td>

                  <td>{item.ext_prod_id}</td>
                  <td>{item.mrp}</td>
                  <td>
                    <ActionButton label="ADD" onClick={() => AddProductstoInventory(item)} />
                  </td>
                  <td />
                </tr>
              )
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td className="empty-data" colSpan={12}>
                No data to display
              </td>
            </tr>
          </tbody>
        )}
      </table>

      <div className="all-products-pagination">
        <Pagination
          className="all-products-pagination"
          count={pageCount}
          page={pageNo}
          shape="rounded"
          variant="outlined"
          onChange={handleChange}
        />
      </div>
      <Dialog className="search-filter-products-dialog" open={sortDialog}>
        <form onSubmit={handleSubmit(async (data) => multipleProductFilter(data))}>
          <div className="search-sort-dialog">
            <InputWithLabels name="mrp" register={register} text="MRP" notrequired />
            <InputWithLabels name="erp_item_code" register={register} text="Poorvika Product Code" notrequired />
            <InputWithLabels name="sku" register={register} text="Global SKU" notrequired />
            <InputWithLabels name="ext_prod_id" register={register} text="Seller Product Code" notrequired />
          </div>

          <DialogActions style={{ margin: '0 auto' }}>
            <ActionButton label="Cancel" type="button" varient="outlined" onClick={() => setsortDialog(false)} />

            <ActionButton label="Search" type="submit" />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
export default AddInventoryProducts
