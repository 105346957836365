import axios, { AxiosError } from 'axios'
import { store } from 'redux/store'
import { API_ENDPOINTS, parseAxiosError, PIM_PARAM } from 'common/constant'
import {
  PRODUCT,
  PRODUCTACTIVED,
  PRODUCT_ACTIVATED,
  PRODUCT_BLOCKED,
  PRODUCT_INACTIVE,
  PRODUCT_COUNT,
  PRODUCT_CATEGORIES,
  PRODUCT_UPDATE,
  PRODUCTS_LIST,
  PRODUCTS_COMM_LIST,
  PRODUCTSCOMM_UPDATE
} from 'redux/actions/ProductActionTypes'

import { BaseService } from './base.service'
import { IAPIResponse, IErrorResponse, ISuccessResponse } from './interfaces/common.interface'
import { IEditProductCatalog, IproductFields, IupdateProductFields } from './interfaces/product.interface'

export class ProductService extends BaseService {
  userEmail = localStorage.getItem('userMail') || ''
  role = localStorage.getItem('role') || ''

  public async addProduct(
    fields: IproductFields,
    url: string,
    category: string
  ): Promise<ISuccessResponse | IErrorResponse> {
    fields.url = url
    fields.productCategory = category

    const isAdmin: boolean =
      this.role === 'ROOT_ADMIN' ||
      this.role === 'PMPL_CATALOGUE_MANAGER' ||
      this.role === 'PMPL_OPERATIONS_MANAGER' ||
      this.role === 'PMPL_FINANCE_MANAGER'

    fields.userEmail = (isAdmin ? localStorage.getItem('sellerMail') : localStorage.getItem('userMail')) || ''

    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.ADD_PRODUCTS, fields)

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
  public async addProductComm(product: string, commission: number): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.post(
        API_ENDPOINTS.ADD_PRODUCT_COMM,
        { product, commission }
      )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async updateProduct(fields: IupdateProductFields, _id: string): Promise<ISuccessResponse | IErrorResponse> {
    fields._id = _id

    try {
      const { data } = await this.httpClient.put(`${API_ENDPOINTS.UPDATE_PRODUCTS}`, fields)
      store.dispatch({
        type: PRODUCT_UPDATE,
        payload: data.data
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async uploadDocument(uploadfile: FormData): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const seller_code = localStorage.getItem('seller_code')
      const { data } = await this.httpClient.post(
        API_ENDPOINTS.SELLER_WAREHOUSE + API_ENDPOINTS.BULK_UPLOAD_PRODUCT + seller_code,
        uploadfile
      )

      return data
    } catch (error) {

      return parseAxiosError(error as AxiosError)
    }
  }

  public async downloadDocument(category = '', brand = '', searchVal = ''){
    try {
      const seller_code = localStorage.getItem('seller_code')
      const postObject ={
        "variantName": searchVal,
        "categoryCode": category,
        "brandCode": brand,
        "seller_code":seller_code
      }
      // const { data } = await this.httpClient.post(API_ENDPOINTS.PIM_EXPORT, postObject,{
      //  headers: { "Content-Type": "blob" } 
      // } )
      // const data = await this.httpClient.post(API_ENDPOINTS.PIM_EXPORT ,postObject,{ responseType: 'blob'})


      const data = await axios.post(API_ENDPOINTS.PIM_EXPORT ,postObject,{ responseType: 'blob'})
      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getAllSellerProducts(): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const seller_code = localStorage.getItem('seller_code')
      const url = `${API_ENDPOINTS.SELLER_WAREHOUSE}${API_ENDPOINTS.DOWNLOAD_PRODUCT_LIST}${seller_code}/all?orderby=erp_item_code&sort=1&name&wholesale_price&mrp&erp_item_code&sku&ext_prod_id&psn`
      const { data } = await this.httpClient.get(url)

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getUploadHistory(): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const seller_code = localStorage.getItem('seller_code')
      const url = `${API_ENDPOINTS.SELLER_WAREHOUSE}${API_ENDPOINTS.UPLOAD_PRODUCT_HISTORY}?SellerCode=${seller_code}`
      const { data } = await this.httpClient.get(url)

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async updateProductComm(id: string, comm: number, status: string): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.put(
        `${API_ENDPOINTS.UPDATE_PRODUCT_COMM}`,
        { _id: id, commission: comm, status }
      )
      store.dispatch({
        type: PRODUCTSCOMM_UPDATE,
        payload: data.data
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getProductCommission(pageSize: number, pageNo: number): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_PRODUCT_COMM}/${pageSize}/${pageNo}`)
      store.dispatch({
        type: PRODUCTS_COMM_LIST,
        payload: data.data
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getProductCount(): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const role = localStorage.getItem('role') || ''
      const userEmail = localStorage.getItem('userMail') || ''
      const sellerEmail = localStorage.getItem('sellerMail') || ''

      if (role === 'SELLER_ADMIN') {
        const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_PRODUCTS_COUNT}/${userEmail}`)
        store.dispatch({
          type: PRODUCT_COUNT,
          payload: data.data
        })

        return data
      }

      if (
        role === 'ROOT_ADMIN' ||
        role === 'PMPL_CATALOGUE_MANAGER' ||
        role === 'PMPL_OPERATIONS_MANAGER' ||
        role === 'PMPL_FINANCE_MANAGER'
      ) {
        const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_PRODUCTS_COUNT}/${sellerEmail}`)
        store.dispatch({
          type: PRODUCT_COUNT,
          payload: data.data
        })

        return data
      }

      const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_ADMIN_PRODUCTS_COUNT}`)
      store.dispatch({
        type: PRODUCT_COUNT,
        payload: data.data
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getProductCategories(): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_PRODUCT_CATEGORIES}`)
      store.dispatch({
        type: PRODUCT_CATEGORIES,
        payload: data.data
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getProductReadyForActivation(
    type: string,
    pageSize: any,
    pageNo: any,
    search: string
  ): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const role = localStorage.getItem('role') || ''
      const userEmail = localStorage.getItem('userMail') || ''
      const sellerEmail = localStorage.getItem('sellerMail') || ''

      if (role === 'SELLER_ADMIN') {
        if (!search.length) {
          const { data } = await this.httpClient.get(
            `${API_ENDPOINTS.GET_PRODUCTS}/${type}/${pageSize}/${pageNo}/${userEmail}`
          )
          store.dispatch({
            type: PRODUCT,
            payload: data.data
          })

          return data
        }

        const { data } = await this.httpClient.get(
          `${API_ENDPOINTS.GET_PRODUCTS}/${type}/${pageSize}/${pageNo}/${userEmail}/${search}`
        )
        store.dispatch({
          type: PRODUCT,
          payload: data.data
        })

        return data
      }

      if (
        role === 'ROOT_ADMIN' ||
        role === 'PMPL_CATALOGUE_MANAGER' ||
        role === 'PMPL_OPERATIONS_MANAGER' ||
        role === 'PMPL_FINANCE_MANAGER'
      ) {
        if (!search.length) {
          const { data } = await this.httpClient.get(
            `${API_ENDPOINTS.GET_PRODUCTS}/${type}/${pageSize}/${pageNo}/${sellerEmail}`)
          store.dispatch({
            type: PRODUCT,
            payload: data.data
          })

          return data
        }

        const { data } = await this.httpClient.get(
          `${API_ENDPOINTS.GET_PRODUCTS}/${type}/${pageSize}/${pageNo}/${sellerEmail}/${search}`)
        store.dispatch({
          type: PRODUCT,
          payload: data.data
        })

        return data
      }

      const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_ADMIN_PRODUCTS}/${type}/${pageSize}/${pageNo}`)
      store.dispatch({
        type: PRODUCT,
        payload: data.data
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getProductsList(type: string): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const userEmail = localStorage.getItem('userMail') || ''

      const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_PRODUCTS_LIST}/${type}/${userEmail}`)
      store.dispatch({
        type: PRODUCTS_LIST,
        payload: data
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getProductBlocked(type: string, pageSize: any, pageNo: any): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const userEmail = localStorage.getItem('userMail') || ''
      const role = localStorage.getItem('role') || ''
      const sellerEmail = localStorage.getItem('sellerMail') || ''

      if (role === 'SELLER_ADMIN') {
        const { data } = await this.httpClient.get(
          `${API_ENDPOINTS.GET_PRODUCTS}/${type}/${pageSize}/${pageNo}/${userEmail}`)
        store.dispatch({
          type: PRODUCT_BLOCKED,
          payload: data.data
        })

        return data
      }

      if (
        role === 'ROOT_ADMIN' ||
        role === 'PMPL_CATALOGUE_MANAGER' ||
        role === 'PMPL_OPERATIONS_MANAGER' ||
        role === 'PMPL_FINANCE_MANAGER'
      ) {
        const { data } = await this.httpClient.get(
          `${API_ENDPOINTS.GET_PRODUCTS}/${type}/${pageSize}/${pageNo}/${sellerEmail}`
        )
        store.dispatch({
          type: PRODUCT_BLOCKED,
          payload: data.data
        })

        return data
      }

      const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_ADMIN_PRODUCTS}/${type}/${pageSize}/${pageNo}`)
      store.dispatch({
        type: PRODUCTACTIVED,
        payload: data.data
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getProducInActive(
    type: string,
    pageSize: any,
    pageNo: any,
    search: string
  ): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const role = localStorage.getItem('role') || ''
      const userEmail = localStorage.getItem('userMail') || ''
      const sellerEmail = localStorage.getItem('sellerMail') || ''

      if (role === 'SELLER_ADMIN') {
        if (!search.length) {
          const { data } = await this.httpClient.get(
            `${API_ENDPOINTS.GET_PRODUCTS}/${type}/${pageSize}/${pageNo}/${userEmail}`)
          store.dispatch({
            type: PRODUCT_INACTIVE,
            payload: data.data
          })

          return data
        }

        const { data } = await this.httpClient.get(
          `${API_ENDPOINTS.GET_PRODUCTS}/${type}/${pageSize}/${pageNo}/${userEmail}/${search}`)
        store.dispatch({
          type: PRODUCT_INACTIVE,
          payload: data.data
        })

        return data
      }

      if (
        role === 'ROOT_ADMIN' ||
        role === 'PMPL_CATALOGUE_MANAGER' ||
        role === 'PMPL_OPERATIONS_MANAGER' ||
        role === 'PMPL_FINANCE_MANAGER'
      ) {
        if (!search.length) {
          const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_PRODUCTS}/${type}/${pageSize}/${pageNo}/${sellerEmail}`)
          store.dispatch({
            type: PRODUCT_INACTIVE,
            payload: data.data
          })

          return data
        }

        const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_PRODUCTS}/${type}/${pageSize}/${pageNo}/${userEmail}/${search}`)
        store.dispatch({
          type: PRODUCT_INACTIVE,
          payload: data.data
        })

        return data
      }

      const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_ADMIN_PRODUCTS}/${type}/${pageSize}/${pageNo}`)
      store.dispatch({
        type: PRODUCT,
        payload: data.data
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getProductActive(
    type: string,
    pageSize: any,
    pageNo: any,
    search: string
  ): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const role = localStorage.getItem('role') || ''
      const userEmail = localStorage.getItem('userMail') || ''
      const sellerEmail = localStorage.getItem('sellerMail') || ''

      if (role === 'SELLER_ADMIN') {
        if (!search.length) {
          const { data } = await this.httpClient.get(
            `${API_ENDPOINTS.GET_PRODUCTS}/${type}/${pageSize}/${pageNo}/${userEmail}`
          )
          store.dispatch({
            type: PRODUCTACTIVED,
            payload: data.data
          })

          return data
        }

        const { data } = await this.httpClient.get(
          `${API_ENDPOINTS.GET_PRODUCTS}/${type}/${pageSize}/${pageNo}/${userEmail}/${search}`
        )
        store.dispatch({
          type: PRODUCTACTIVED,
          payload: data.data
        })

        return data
      }

      if (
        role === 'ROOT_ADMIN' ||
        role === 'PMPL_CATALOGUE_MANAGER' ||
        role === 'PMPL_OPERATIONS_MANAGER' ||
        role === 'PMPL_FINANCE_MANAGER'
      ) {
        if (!search.length) {
          const { data } = await this.httpClient.get(
            `${API_ENDPOINTS.GET_PRODUCTS}/${type}/${pageSize}/${pageNo}/${sellerEmail}`)
          store.dispatch({
            type: PRODUCTACTIVED,
            payload: data.data
          })

          return data
        }

        const { data } = await this.httpClient.get(
          `${API_ENDPOINTS.GET_PRODUCTS}/${type}/${pageSize}/${pageNo}/${sellerEmail}/${search}`)
        store.dispatch({
          type: PRODUCTACTIVED,
          payload: data.data
        })

        return data
      }

      const { data } = await this.httpClient.get(`${API_ENDPOINTS.GET_ADMIN_PRODUCTS}/${type}/${pageSize}/${pageNo}`)
      store.dispatch({
        type: PRODUCTACTIVED,
        payload: data.data
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async makeProductActivated(id: string): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.put(
        `${API_ENDPOINTS.GET_ADMIN_PRODUCT_ACTIVATE}`,
        { _id: id },
      )
      store.dispatch({
        type: PRODUCT_ACTIVATED,
        payload: data.data
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async makeProductDeActive(id: string, reason: string): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.put(
        `${API_ENDPOINTS.GET_ADMIN_PRODUCT_DEACTIVATE}`,
        { _id: id, deactivationReason: reason })
      store.dispatch({
        type: PRODUCTACTIVED,
        payload: data.data
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async makeProductBlocked(id: string, reason: string): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.put(
        `${API_ENDPOINTS.GET_ADMIN_PRODUCT_BLOCKED}`,
        { _id: id, blockedReason: reason })
      store.dispatch({
        type: PRODUCTACTIVED,
        payload: data.data
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  /* Pim products API ==========================================================>*/
  public async getPIMProductsCategoryAll(): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.get(API_ENDPOINTS.PIM_CATEGORY+"/all",
        { "headers" : {
          "channel" :PIM_PARAM.CHANNEL,
          "locale": PIM_PARAM.LOCALE
      }} )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getPIMBrandListAll(): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.get(API_ENDPOINTS.PIM_BRAND+"/all" ,
        { "headers" : {
          "channel" :PIM_PARAM.CHANNEL,
          "locale": PIM_PARAM.LOCALE
      }})

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
  public async getPIMProductsCategory(): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const Seller_code = localStorage.getItem('seller_code')
      const { data } = await this.httpClient.get(API_ENDPOINTS.PIM_CATEGORY+"/" +Seller_code,
        { "headers" : {
          "channel" :PIM_PARAM.CHANNEL,
          "locale": PIM_PARAM.LOCALE
      }} )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getPIMBrandList(): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const Seller_code = localStorage.getItem('seller_code')

      const { data } = await this.httpClient.get(API_ENDPOINTS.PIM_BRAND+"/" +Seller_code,
        { "headers" : {
          "channel" :PIM_PARAM.CHANNEL,
          "locale": PIM_PARAM.LOCALE
      }})

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getPimProducts(
    brandName: string,
    categoryName: string,
    productName?: string,
    pageNo?: string | number
  ): Promise<ISuccessResponse | IErrorResponse> {
    try {
      let postObject ={
        "variantName" : !!productName ? productName : "", 
        "categoryCode" : !!categoryName ? categoryName : "",
        "brandCode" : !!brandName? brandName : ""
      }
      const { data } = await this.httpClient.post(API_ENDPOINTS.PIM_PRODUCTS+"?page="+pageNo+"&limit=10",
      postObject);
      let return_data : ISuccessResponse;
      if(!!data?.data[0].result.length){
         return_data = {
          total_stock : data?.data[0].totalProduct[0].count,
          data:  data?.data[0].result,
          status: data?.status +"",
          message: "",
          pagecount :Math.ceil(data?.data[0].totalProduct[0].count/10)
        }
      } else{
        return_data = {
          total_stock :0,
          data: [],
          status: "",
          message: "No product found",
          pagecount: 0
        }
      }
      
      return return_data;
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async AddProductsSellerCatelogue(productdata): Promise<ISuccessResponse | IErrorResponse> {
    try {
      const { data } = await this.httpClient.post(API_ENDPOINTS.SELLER_WAREHOUSE + 'seller/catelogue', productdata)

      return data
    } catch (error) {

      return parseAxiosError(error as AxiosError)
    }
  }

  public async getSellerProducts(listing_status, pageNo, warehousecode?): Promise<ISuccessResponse | IErrorResponse> {
    const Seller_code = localStorage.getItem('seller_code')

    try {
      const { data } = await this.httpClient.get(
        `${API_ENDPOINTS.SELLER_WAREHOUSE + 'seller/catelogue/warehouse/'}${Seller_code}/${listing_status}/${pageNo}`,
        {
          headers: {
            warehousecode
          }
        }
      )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getSellerProductsWithoutWarehose(listing_status, pageNo): Promise<ISuccessResponse | IErrorResponse> {
    const Seller_code = localStorage.getItem('seller_code')

    try {
      const { data } = await this.httpClient.get(
        `${API_ENDPOINTS.SELLER_WAREHOUSE + 'seller/catelogue/'}${Seller_code}/${listing_status}/${pageNo}`
        
      )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async approveProduct(status: string, pimcode: string,erp_code: string, rejectReason?: string): Promise<ISuccessResponse | IErrorResponse> {
    const approveStatus = {
      listing_status: status,
      erp_item_code: erp_code,
      apx_seller_code: localStorage.getItem('supplier_apx_code'),
      seller_email:
        localStorage.getItem('role') === 'ROOT_ADMIN'
          ? localStorage.getItem('sellerMail')
          : localStorage.getItem('userMail')
    }
    const Seller_code = localStorage.getItem('seller_code')

    try {
      const { data } = await this.httpClient.put(
        `${API_ENDPOINTS.SELLER_WAREHOUSE}seller/catelogue/listing/${encodeURIComponent(pimcode)}/${Seller_code}?reason=${rejectReason}`,
        approveStatus)
      store.dispatch({
        type: PRODUCT_UPDATE,
        payload: data.data
      })

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async getSellerSearchProduct(
    status: string,
    pageNo: number,
    searchInput
  ): Promise<ISuccessResponse | IErrorResponse> {
    const Seller_code = localStorage.getItem('seller_code')

    try {
      const { data } = await this.httpClient.get(`${API_ENDPOINTS.SELLER_WAREHOUSE}seller/catelogue/${Seller_code}/${status}/${pageNo}? ${searchInput}`)

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async multipleSearchProduct(
    status: string,
    pageNo: number,
    searchInput,
    orderbyValues?: string,
    sortType?: string,
    warehousecode?:string
  ): Promise<ISuccessResponse | IErrorResponse> {
    const Seller_code = localStorage.getItem('seller_code')
    sortType = sortType ? sortType : ''
const url =  API_ENDPOINTS.SELLER_WAREHOUSE +
'seller/catelogue/' +
`${Seller_code}/${status}/${pageNo}?name=${searchInput.name ? searchInput.name : ''}&wholesale_price=${
  searchInput.wholesale_price ? searchInput.wholesale_price : ''
}&erp_item_code=${searchInput.erp_item_code ? searchInput.erp_item_code : ''}&sku=${
  searchInput.sku ? searchInput.sku : ''}&category=${searchInput.category ? searchInput.category : ''}&brand=${searchInput.brand ? searchInput.brand.replace(/^\s+|\s+$/gm,'') : ''}&ext_prod_id=${searchInput.ext_prod_id ? searchInput.ext_prod_id : ''}&min_price=${
  searchInput.minPrice ? searchInput.minPrice : ''
}&max_price=${searchInput.maxPrice ? searchInput.maxPrice : ''}&min_mrp=${
  searchInput.minMrp ? searchInput.minMrp : ''
}&max_mrp=${searchInput.maxMrp ? searchInput.maxMrp : ''}&min_stock=${
  searchInput.minStock ? searchInput.minStock : ''
}&max_stock=${searchInput.maxStock ? searchInput.maxStock : ''}&psn=${
  searchInput.psn ? searchInput.psn : ''
}&orderby=${orderbyValues ? orderbyValues : ''}&sort=${sortType}`
    try {
      const { data } = await this.httpClient.get(
        url,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          }
        }
      )

      return data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async multiplePimProductsSearch(
    brandName: string,
    categoryName: string,
    productName?: string,
    pageNo?: string,
    searchInput?: any,
    orderbyValues?: string,
    sortType?: string
  ): Promise<ISuccessResponse | IErrorResponse> {
    sortType = sortType ? sortType : ''

    try {
      let postObject ={
        "variantName" : !!productName ? productName : '', 
        "categoryCode" : !!categoryName ? categoryName : '',
        "brandCode" : !!brandName? brandName : '',
        "sku":  !!searchInput && searchInput.sku ? searchInput.sku : '',
        "erpItemCode": !!searchInput && searchInput.erp_item_code ? searchInput.erp_item_code : ''
      }
      const { data } = await this.httpClient.post(API_ENDPOINTS.PIM_PRODUCTS+"?page="+pageNo+"&limit=10&sortOrder="+sortType+"&sortBy="+orderbyValues,
      postObject);
      let return_data = {
        total_stock : data?.data[0].totalProduct[0].count,
        data:  data?.data[0].result,
        status: data?.status,
        message: "",
        pagecount :Math.ceil(data?.data[0].totalProduct[0].count/10)
      }

      return return_data
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }

  public async editProductCatalog(editedProductData: IEditProductCatalog): Promise<IAPIResponse> {
    try {
      const { data } = await this.httpClient.put(API_ENDPOINTS.EDIT_PRODUCT_CATALOG, editedProductData);

      return data      
    } catch (error) {
      return parseAxiosError(error as AxiosError)
    }
  }
}
