import {ACTIVE_LOCATION_COUNT } from "redux/actions/inventoryCount.action";


type Location= {
    activeLocationCount:any
}


const initialState = {
    activeLocationCount:undefined
}

const InventoryCountReducer = (state = initialState, action: any): Location => {
    switch (action.type) {
      case ACTIVE_LOCATION_COUNT:
        return {
          ...state,
          activeLocationCount: action.payload
        }
      default:
        return state
    }
  }

  export default InventoryCountReducer;