import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { LoginService } from 'services/login.service'
import { ILoginResponse, ILoginError, ILoginCredentials } from 'services/interfaces/login.interface'
import { ROLES, showNotification, STATUS } from 'common/constant'
import { encrypt } from 'common/encryptor'
import Spinner from 'components/Spinner';
import './LoginForm.scss'

type LoginCredentials = {
  email: string
  password: string
}

type LoginType = {
  isBusinessLogin: boolean
}

const formValidations = {
  email: {
    required: { value: true, message: '* Email field is empty' },
    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: '* Email format is invalid' }
  },
  password: {
    required: { value: true, message: '* Password field is empty' },
    minLength: { value: 6, message: '* Password must be at least 6 characters long' }
  }
}

const LoginForm: React.FC<LoginType> = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [emailLabel, setEmailLabel] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const loginService: LoginService = new LoginService()
  const history = useHistory()
  

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const { register, handleSubmit, errors } = useForm<LoginCredentials>({ mode: 'onChange' })

  const onSubmit = async (data: ILoginCredentials) => {
    try {
      setLoading(true)
      data.password = encrypt(data.password);
      const loginResponse: ILoginResponse | ILoginError = await loginService.login({ ...data });

      if (loginResponse.status === STATUS.SUCCESS) {
        const { data } = loginResponse as ILoginResponse
        localStorage.setItem('code', data.code)
        localStorage.setItem('accessToken', data.accessToken)

        if (data.role === ROLES.ROOT_ADMIN || data.role === ROLES.PMPL_CATALOGUE_MANAGER  || data.role === ROLES.PMPL_FINANCE_MANAGER || data.role === ROLES.PMPL_OPERATIONS_MANAGER) {
           /*UPDATED*/
          history.push('sellerLayout/admin/seller')
          // history.push('/sellerLayout/admin/accounts')
        }
        else if (data.role === ROLES.SELLER_ADMIN) {
          if (!data.sellerOnBoarded) {
            history.push('/onBoarding')
          }
        }
       
        else if (data.role === ROLES.SELLER_FINANCE_MANAGER) {
          if (!data.sellerOnBoarded) {
            history.push('/onBoarding')
          }
        }
        else if (data.role === ROLES.SELLER_OPERATION_MANAGER) {
          if (!data.sellerOnBoarded) {
            history.push('/onBoarding')
          }
        }
        else if (data.role === ROLES.SELLER_CATALOGUE_MANAGER) {
          if (!data.sellerOnBoarded) {
            history.push('/onBoarding')
          }
        }
        setLoading(false);
        showNotification(STATUS.SUCCESS, loginResponse.message)
      } else {
        setLoading(false);
        showNotification('failure', loginResponse.message)
      }
    } catch (error) {
      setLoading(false);
      showNotification('failure', (error as Error).message)
    }
  }

  return (
    <form className="loginForm-container" autoComplete="new-password" onSubmit={handleSubmit(onSubmit)}>
      <Spinner loading={loading}/>
      <div className="form-group email-ip">
        {/* <p className={emailLabel ? 'login-label-wrapper' : 'label-hide'}>Login ID or Email</p> */}
        <input
          id="email_id"
          ref={register(formValidations.email)}
          className='login-input-field-box'
          placeholder="Login ID or Email"
          // value="jayasinsoftsuave@gmail.com"
          name="email"
          onClick={() => setEmailLabel(!emailLabel)}
        />
        <label htmlFor="email_id" className="label-helper">Login ID or Email </label>

        {errors.email ? <div className="error-text">{errors.email.message}</div> : ''}
      </div>
      <div className="email-password-height" />
      <div className="form-group password-wrapper">

        <div className="password-ip">
          <input
            ref={register(formValidations.password)}
            autoComplete="new-password"
            className='login-password-field-box'
            placeholder="Enter your Password"
            name="password"
            // value="123456"
            type={passwordShown ? "text" : "password"}
            id="password"
          />
          <label htmlFor="password" className="label-password">Password</label>

          {passwordShown ?
            <VisibilityOffIcon className="password-toogle-ic" onClick={togglePasswordVisiblity} />

            : <VisibilityIcon className="password-toogle-ic" onClick={togglePasswordVisiblity} />
          }

        </div>
        {errors.password ? <div className="error-text">{errors.password.message}</div> : ''}




      </div>



      <div className="forgot-password-area">

        <Link className="forgot-password" to="/forgotPassword">
          Forgot Password?
        </Link>
      </div>

      <button className="login-btn" type="submit" >
        LOGIN
      </button>

    </form>
  )
}

export default LoginForm
