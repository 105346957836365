import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { ON_BORADING_STEPS, showNotification, STATUS } from 'common/constant'
import ActionButton from 'components/ActionButton'
import InputWithLabelsNotRequired from 'components/InputLabelsNotrequired'
import InputWithDropDown from 'components/InputwithDropdown'
import InputWithLabels from 'components/InputWithLabels'
import WarningTextLayer from 'components/WarningTextLayer'
import { RootStore } from 'redux/store'
import { IErrorResponse, ISuccessResponse } from 'services/interfaces/common.interface'
import { Istate } from 'services/interfaces/state.interface'
import { OnboardingService } from 'services/onBoarding.service'

import './styles.scss'
import InputWithEmailVerificationText from 'components/InputWithEmailVerificationText'
import CodeInput from 'components/CodeInput'
import { LoginService } from 'services/login.service'
import slugify from 'common/slugify'
import MobileOTPVerification from 'components/MobileOTPVerification'
import Spinner from 'components/Spinner'


type ILegalForm = {
  accountName: string,
  email: string,
  gstNumber: string,
  mobile: string,
  type: string
  name: string
  address1: string
  address2: string
  address3: string
  city: string
  state: string
  pin: string
}
type TLegalReviewFormProps = {

  onSelected: () => void;
}


const legalFormValidations = {
  accountName: {
    required: { value: true, message: "Account name can't be Empty" }
  },
  code: {
    required: { value: true, message: " Account code can't be Empty" }
  },
  mobile: {
    required: { value: true, message: "Phone Number can't be Empty" },
    minLength: { value: 10, message: ' Phone Number must be at least 10 digits' },
    maxLength: { value: 10, message: "Phone Number can't be more than 10 digits" }
  },
  email: {
    required: { value: true, message: "Email field can't be Empty" },
    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: ' Email format is invalid' }
  },
  gstNumber: {
    required: { value: true, message: 'GST Number is required' },
    minLength: { value: 15, message: 'Should be 15 characters' },
  },
  name: {
    required: { value: false, message: "Legal Name can't be Empty" }
  },
  type: {
    required: { value: true, message: '' }
  },
  address1: { required: { value: false, message: "Address Field can't be empty" } },
  address2: { required: { value: false, message: "Address Field can't be empty" } },
  city: { required: { value: false, message: "city Field can't be empty" } },
  state: { required: { value: false, message: "state Field can't be empty" } },
  pin: {
    required: { value: false, message: "pincode  Field can't be empty" },
    minLength: { value: 6, message: "pincode must be 6 characters long" }
  }
}


const LegalInfomationReviweForm: React.FC<TLegalReviewFormProps> = ({ onSelected }) => {
  const { register, handleSubmit, errors, setValue, setError } = useForm<ILegalForm>({mode: "all"})
  const [rejectionNotes, setRejectionNotes] = useState<string>("")
  const [code, setCode] = useState<string>('')
  const [mobileVerified, setMobileVerified] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [buttonText, setButtonText] = useState('GET OTP');
  const [emailButtonText, setEmailButtonText] = useState('Not Verified');
  const [mobileNumber, setMobileNumber] = useState("");
  const [otpVisibility, setOTPVisibility] = useState(false);
  const [otpNumber, setOtpNumber] = useState('')
  const [countDownStarted, setCountDownStarted] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [emailuser,setEmailuser] =  useState<string>('');
  let countDownTime = 30;

  const selectedUserEmail = useSelector((state: RootStore) => state.onBoardingData.userEmail?.email);


  /* Services  */
  const loginService = new LoginService();
  const onBoardingService = new OnboardingService();

  const onSubmit = async (formData: ILegalForm) => {
    if (rejectionNotes) {
      updateDatails(formData, rejectionNotes)
    } else {
      showNotification(STATUS.FAILURE, 'Add Rejection Notes')
    }
  }
  const onSubmitsave = async (formData: ILegalForm) => {
      updateDatails(formData, "Data updated.")
  }

  const updateDatails =async (formData:ILegalForm, msg : String) => {
    
    try {
      const saveRejectionNotesResponse: ISuccessResponse | IErrorResponse = await onBoardingService.updateOnBoardingData(
        {
          ...formData,
          code,
          step: ON_BORADING_STEPS[0],
          email: selectedUserEmail as string,
          rejection_notes: {
            step: ON_BORADING_STEPS[0],
            notes: msg
          }
        })

      if (saveRejectionNotesResponse.status === STATUS.SUCCESS) {
        showNotification(STATUS.SUCCESS, saveRejectionNotesResponse.message)
      } else {
        showNotification(STATUS.FAILURE,  saveRejectionNotesResponse.message)
      }
      onSelected();
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Failed to save data. Please check your input and try again.')
    }
  }

  const autoCodeValidationHandler = async (code: string) => {
    if (!code) {
      setCode('')

      return
    }

    try {
      const checkAccount: ISuccessResponse | IErrorResponse = await loginService.checkCodeAuthMode(code)

      if (checkAccount.status === STATUS.SUCCESS) {
        setCode(slugify(code))
      } else {
        autoCodeValidationHandler(code + ' ' + Math.random().toString(36).substring(2, 7))
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, (error as Error)?.message);
    }
  }

  const gstInVerify = async (gstNumber: string) => {
    const filteredGSTNumber = gstNumber?.replace(/[^a-z0-9]/gi,'').substring(0,15);
    setValue("gstNumber", filteredGSTNumber)

    if (gstNumber.length === 15) {
      const regexPattern  = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;

      if(!regexPattern.test(filteredGSTNumber)) {
        setError('gstNumber', {
          type: "manual", 
          message: "GST Number format is invalid"
        })
        showNotification('failure', "GST Number Format is invalid")

        return 
  
      }

      try {
        setLoading(true);
        const { data }: ISuccessResponse | IErrorResponse = await onBoardingService.getGstIndetails(gstNumber);

        if (!data.error) {
          if(data.data.sts !== "Cancelled" && data.data.sts !== ''){
          const accountInformations = data.data;
          const addressData = accountInformations.pradr.addr;
          setValue("name", accountInformations.lgnm);
          setValue("address1", addressData.bno + ", " + addressData.flno);
          setValue("address2", addressData.st);
          // setValue("address3", addressData.dst);
          // setValue("city", addressData.city);
          setValue("city", addressData.dst);
          setValue("state", addressData.stcd);
          setValue("pin", addressData.pncd);

        }
        else if(data.data.sts === "Cancelled"){
          setValue("name", "");
          setValue("address1", "");
          setValue("address2", "");
          setValue("city", "");
          setValue("state", "");
          setValue("pin", "");
          showNotification(STATUS.FAILURE, 'GST Status is Cancelled')
          setError('gstNumber', {
            type: "manual", 
            message: "GST Status is Cancelled"
          })
        }
        else{
          setValue("name", "");
          setValue("address1", "");
          setValue("address2", "");
          setValue("city", "");
          setValue("state", "");
          setValue("pin", "");
          showNotification(STATUS.FAILURE, 'Please Enter valid GST Number')
          setError('gstNumber', {
            type: "manual", 
            message: "Please Enter valid GST Number"
          })
        }
      }
      setLoading(false);
      } catch (error) {
        showNotification(STATUS.FAILURE, 'Unable to get GST Informations')
        setLoading(false);
      }
    } else {
      setValue("name", "");
      setValue("address1", "");
      setValue("address2", "");
      setValue("pin", "");
    }
  }

  useEffect(() => {
    const getPersonalData = async () => {
      try {
        const personalData: ISuccessResponse | IErrorResponse = await onBoardingService.getOnboardingData(1, selectedUserEmail as string)
        if (personalData.status === STATUS.SUCCESS) {
          const successData = personalData as ISuccessResponse;
          setCode(successData.data.account_code);
          setValue("accountName", successData.data.account_name);
          setValue("email", successData.data.email);
          setEmailuser(successData.data.email)
          setValue("gstNumber", successData.data.gstNumber);
          setValue("mobile", successData.data.mobile);
          setValue("name", successData.data.name);
          setValue("type", successData.data.type);
          setValue("address1", successData.data.address1);
          setValue("address2", successData.data.address2);
          setValue("address3", successData.data.address3);
          setValue("city", successData.data.city);
          setValue("pin", successData.data.pincode);
          setValue("state", successData.data.state);
          setRejectionNotes(successData.data.rejection_notes);
          setMobileNumber(successData.data.mobile)
          if(successData.data.mobile_verified) {
            setMobileVerified(successData.data.mobile_verified);
            setMobileNumber(successData.data.mobile)
            setButtonText('Verified')
          }
          if(successData.data.email_verified) {
            setEmailVerified(successData.data.email_verified)
            setEmailButtonText("Verified")
          }

        } else {
          showNotification(STATUS.FAILURE, 'Unable to get legal informations')
        }
      } catch (error) {
        showNotification(STATUS.FAILURE, 'Unable to get legal informations')
      }
    }

    getPersonalData()
  }, [])


  const OTPVerificationHandler = async () => {

    if(mobileNumber.length < 10) {
      showNotification('failure', "Mobile Number length is invalid ")
      return
    }

    try {
      if (countDownStarted) return;
      const initiateOTP: ISuccessResponse | IErrorResponse = await onBoardingService.getOTP(mobileNumber);

      if (initiateOTP.status === STATUS.SUCCESS) {
        showNotification(STATUS.SUCCESS, 'OTP Successfully sent')
        setOTPVisibility(true)
        setCountDownStarted(true);
        countDownTime = 30;
        countDownTimer();
      } else {
        showNotification(STATUS.FAILURE, initiateOTP.message)
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, 'Unable to send OTP')
    }
  }



  const countDownTimer = async () => {
    countDownTime--;

    if (countDownTime > 0) {
      setTimeout(() => {
        if (!mobileVerified) {
          setButtonText((countDownTime < 10) ? `0:0${countDownTime}` : `0:${countDownTime}`);
          countDownTimer();
        } else {
          setCountDownStarted(false)
          setButtonText('Verified')
        }
      }, 1000)
    } else {
      setCountDownStarted(false);

      if (mobileVerified) {
        setButtonText('Verified')
      } else {
        setButtonText(`RESEND`);
      }
    }

  }

  const verifyOTPHandler = async () => {
    const otpVerificationResponse: ISuccessResponse | IErrorResponse = await onBoardingService.verifyOTP(mobileNumber, otpNumber,emailuser);

    if (otpVerificationResponse.status === STATUS.SUCCESS) {
      showNotification(STATUS.SUCCESS, otpVerificationResponse.message);
      countDownTime = -1
  
      setMobileVerified(true)
     
      setOTPVisibility(false)

    } else {
      showNotification(STATUS.FAILURE, "Unable to verify OTP");

    }

  }

  const mobileNumberInputHandler = async(value: string): Promise<void> => {
    /* filter only number from a string and limit the length to 10 */
    const filteredMobileNumber = value.replace(/\D/g,'').substring(0,10);
    setValue("mobile",filteredMobileNumber );
    setMobileNumber(filteredMobileNumber);
    console.log("The event is calling");
    
  }

  return (
    <div className="legal-information-form-page">
      <WarningTextLayer info={rejectionNotes} closeIcon />
      <Spinner loading={loading}/>
      <form action="" className="legal-information-form">

        <p className="form-subtitle">Account Details</p>
        <InputWithLabels
          error={errors.accountName?.type !== undefined}
          errorText={errors.accountName ? errors.accountName.message : ''}
          name="accountName"
          register={register(legalFormValidations.accountName)}
          text="Company Name"
          onChange={(event) => autoCodeValidationHandler(slugify(event.target.value))}
        />

        <CodeInput
          codeSaved={(newCode) => autoCodeValidationHandler(newCode)}
          isModeEdit={false}
          name="code"
          register={register}
          text="Account Code *"
          value={code}
        />

        <InputWithEmailVerificationText
          name="email"
          register={register}
          text="Email"
          buttonText = {emailButtonText}
          isEmailVerified={emailVerified} />

        {mobileVerified ? <MobileOTPVerification
          buttonText='Verified'
          disabled={mobileVerified}
          value={mobileNumber}
          error={errors.mobile?.type !== undefined}
          errorText={errors.mobile ? errors.mobile.message : ''}
          getOTPClicked={()=> {OTPVerificationHandler()}}
          name="mobile"
          register={register(legalFormValidations.mobile)}
          text="Mobile"
        /> : <MobileOTPVerification
          buttonText={buttonText}
          error={errors.mobile?.type !== undefined}
          errorText={errors.mobile ? errors.mobile.message : ''}
          onChange={(event) => mobileNumberInputHandler(event.target.value)}
          getOTPClicked={()=> {OTPVerificationHandler()}}
          name="mobile"
          register={register(legalFormValidations.mobile)}
          text="Mobile "
        />}
        {otpVisibility ? (
          <div className="otp-verify-wrapper">
            <label className="txt-label">OTP :</label>
            <input

              type="password"
              onChange={(event) => setOtpNumber(event.target.value)}

              className="input-field-box"

            />
            <button className="btn-get-otp" type="button" onClick={verifyOTPHandler}>
              Verify
            </button>
          </div>
        ) : (
          ''
        )}


        <p className="form-subtitle">Legal Company Details</p>
        <InputWithLabels
          text="GST Number"
          type="text"
          name="gstNumber"
          onChange={(event) => gstInVerify(event.target.value)}
          error={errors.gstNumber?.type !== undefined}
          errorText={errors.gstNumber ? errors.gstNumber.message : ''}
          register={register(legalFormValidations.gstNumber)}
        />

        <InputWithLabels
          text="Legal Name"
          type="text"
          name="name"
          error={errors.name?.type !== undefined}
          errorText={errors.name ? errors.name.message : ''}
          register={register(legalFormValidations.name)}
        />
        <p className="form-subtitle">Address</p>
        <InputWithLabels
          text="Address 1"
          name="address1"
          error={errors.address1?.type !== undefined}
          errorText={errors.address1 ? errors.address1.message : ''}
          register={register(legalFormValidations.address1)}
        />
        <InputWithLabels
          text="Address 2"
          name="address2"
          error={errors.address2?.type !== undefined}
          errorText={errors.address2 ? errors.address2.message : ''}
          register={register(legalFormValidations.address2)}
        />
        <InputWithLabelsNotRequired
          text="Address 3"
          name="address3"
          register={register}
        />
        <InputWithLabels
          text="City"
          name="city"
          error={errors.city?.type !== undefined}
          errorText={errors.city ? errors.city.message : ''}
          register={register(legalFormValidations.city)}
        />


        <InputWithDropDown
          dropdown={Istate}
          text="State"
          name="state"
          error={errors.state?.type !== undefined}
          errorText={errors.state ? errors.state.message : ''}
          register={register(legalFormValidations.state)}
        />
        <InputWithLabels
          text="Pin Code"
          name="pin"
          maxLength={6}
          onChange={(event) => event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')}
          error={errors.pin?.type !== undefined}
          errorText={errors.pin ? errors.pin.message : ''}
          register={register(legalFormValidations.pin)}
        />
        <p className="form-subtitle">Rejection notes</p>
        <InputWithLabels
          text="Rejection Notes"
          type="text"
          value={rejectionNotes}
          onChange={(event) => setRejectionNotes(event.target.value)}
        />
        <div>
          <ActionButton label="Add Rejection Notes & Save" type="submit" onClick={handleSubmit(onSubmit)}/>
          <ActionButton label="Save" type="submit" onClick={handleSubmit(onSubmitsave)}/>

        </div>
      </form>
    </div>
  )
}

export default LegalInfomationReviweForm